import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Apis } from "../../../api";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function AutoEmailList() {
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        GetEmails();
    }, []);

    const GetEmails = async () => {
        const res = await Apis.myGet(`settingEmail/settingEmail?mailType=auto`);
        if (res && res.success) {
            setEmails(res.data.items);
        }
    };

    return (
        <div className="mb-3">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1500 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                メール種類
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                自動送信
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                件名
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emails.map((p) => (
                            <StyledTableRow key={p.id}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {p.title}
                                </StyledTableCell>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {p.is_auto_send ? (
                                        <span className="text-blue-500">
                                            送信する
                                        </span>
                                    ) : (
                                        <span className="text-gray-500">
                                            送信しない
                                        </span>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {p.subject}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Link to={"/admin/emails/new?id=" + p.id}>
                                        <div className="px-2 py-5 flex flex-col text-[16px]">
                                            <button className="mt-3 rounded-lg cursor-pointer bg-lime-400 hover:bg-lime-500 active:bg-lime-600 text-white max-[680px]:text-sm max-[680px]:p-1">
                                                編集
                                            </button>
                                        </div>
                                    </Link>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
