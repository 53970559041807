import React, { useState } from "react";
import AuthCode from "react-auth-code-input";
import { toast } from "react-toastify";
import { TERipple } from "tw-elements-react";
import { Apis } from "../api";

const NumberInputComponent = ({
    email,
    isForgotten,
    isSuccessful,
    handleLoginUser,
}) => {
    const [result, setResult] = useState();
    const handleOnChange = (res) => {
        setResult(res);
    };
    const handleSubmit = async () => {
        try {
            const response = await Apis.myPost(
                `account/${
                    isForgotten ? "validate_code" : "register/validate"
                }`,
                { email: email, vcode: result }
            );
            if (response.success) {
                toast.success("検証に成功しました!");
                if (!isForgotten) {
                    handleLoginUser(response.data);
                    return;
                }
                localStorage.setItem("code", result);
                isSuccessful(true);
            }
        } catch (error) {
            toast.error(error.message);
            console.error(error);
        }
    };
    return (
        <div>
            <div className="verifyInput w-full gap-x-3 p-3">
                <AuthCode
                    allowedCharacters="numeric"
                    onChange={handleOnChange}
                />
            </div>
            <div className="flex justify-center mt-10 mb-5">
                <TERipple>
                    <button
                        type="button"
                        className="px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white"
                        onClick={handleSubmit}
                    >
                        確 認
                    </button>
                </TERipple>
            </div>
        </div>
    );
};

export default NumberInputComponent;
