import React, { useContext, useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player";

import fileDownload from "js-file-download";
import { Link } from "react-router-dom";
import axios from "axios";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { generateEmbedUrl, getEmbedUrl } from "../utilities/helper";
import CustomTypography from "./CustomTypography";
import PreviewModal from "./PreviewImageModal";
import { isValidEmail, handleTextLink } from "../utilities/helper";
import { AppContext } from "../App";
import PdfLoader from "./PdfLoader";

export default function PublicViewSocial({ publishData, notShowBack }) {
    const images = publishData.idCard && publishData.idCard.idCard;
    // const [lastImage, setLastImages] = useState(
    //     publishData.idCard && publishData.idCard.idCard[0]
    // );
    const [newProfileData, setNewProfileData] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    // const [numPages, setNumPages] = useState(null);
    // const [tile1, setTile1] = useState(false);
    // const [tile2, setTile2] = useState(false);
    // const [tile3, setTile3] = useState(false);
    // const [tile4, setTile4] = useState(false);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [urlPreview, setUrlPreview] = useState("");
    const { previousPage } = useContext(AppContext);

    const [pdfWidth, setPdfWidth] = useState(0);
    const docContainerRef = useRef(null);

    const updatePdfWidth = () => {
        if (docContainerRef.current) {
            const { width } = docContainerRef.current.getBoundingClientRect();
            setPdfWidth(width);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updatePdfWidth);
        updatePdfWidth(); // Initial pdf width
        return () => window.removeEventListener("resize", updatePdfWidth); // Cleanup listener
    });

    // const handleCloseEnlarge = () => {
    //     setTile1(false);
    //     setTile2(false);
    //     setTile3(false);
    //     setTile4(false);
    // };

    const handlePlaying = () => {
        setIsPlaying((pre) => !pre);
    };

    // const onDocumentLoadSuccess = ({ numPages }) => {
    //     setNumPages(numPages);
    // };

    const handleDateExpire = (startDate, endDate) => {
        const currentTime = new Date().getTime();
        if (!startDate && !endDate) return true;
        if (
            startDate &&
            endDate &&
            startDate <= currentTime &&
            currentTime <= endDate
        )
            return true;
        if (!startDate && endDate && currentTime <= endDate) return true;
        if (startDate && !endDate && currentTime >= startDate) return true;
        return false;
    };

    const handleDownload = (url, filename) => {
        axios
            .get(url, {
                responseType: "blob",
            })
            .then((res) => {
                fileDownload(res.data, `${filename}.pdf`);
            });
    };

    useEffect(() => {
        const newData = {};
        if (publishData.snsTree_Data) {
            Object.keys(publishData.snsTree_Data).forEach((key) => {
                newData[key] = publishData.snsTree_Data[key]
                    .filter((obj) => {
                        if (key === "selfProfile") {
                            return obj && Object.keys(obj).length > 0;
                        }
                        return true;
                    })
                    .map((obj) => ({
                        ...obj,
                        type: key,
                    }));
            });
            const combinedArray = Object.values(newData)
                .flat()
                .filter(
                    (obj) =>
                        handleDateExpire(obj?.startTime, obj?.endTime) &&
                        !(
                            obj.type === "selfProfile" &&
                            obj.data &&
                            Object.keys(obj.data).length === 0
                        )
                )
                .sort((a, b) => a.order - b.order);
            setNewProfileData(combinedArray);
        }
    }, [publishData.snsTree_Data]);
    const imageStyle = (url) => {
        return {
            backgroundImage: `url(${url})`,
            backgroundSize: "contain",
            objectFit: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            // height: "100%",
        };
    };
    const classNameImage =
        "aspect-[1/1] rounded-lg overflow-hidden mx-auto cursor-pointer ";

    return (
        <div
            className="pb-4 w-full relative min-h-[calc(100vh-96px)]"
            style={{
                backgroundColor: publishData?.bgColor,
            }}
        >
            {publishData?.bgURL && (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        backgroundColor: "white",
                        display: "flex",
                        top: 0,
                        right: 0,
                        left: 0,
                        zIndex: 0,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                            backgroundColor: "#f0f0f0",
                        }}
                    >
                        <img
                            src={`${publishData?.bgURL}`}
                            style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "contain",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                opacity: 0.6,
                            }}
                            alt="..."
                        />
                    </div>
                </div>
            )}
            <div className="mx-auto opacity-background  max-w-[480px]">
                {!notShowBack &&
                    previousPage /* window.history.length > 2 */ && (
                        <Link to={-1}>
                            <img
                                src="/image/turn-left.png"
                                alt="edit"
                                className="relative w-[40px] h-[60px] cursor-pointer py-2"
                            ></img>
                        </Link>
                    )}
                <div className="relative min-w-[335px] max-h-[500px] aspect-[4/3]  border-1 overflow-hidden mx-auto">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={10}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        // className="relative min-w-[335px] h-full aspect-[4/3] border-1 overflow-hidden mx-auto cursor-pointer"
                    >
                        {images.map((image, index) => (
                            <SwiperSlide
                                key={index}
                                onClick={() => {
                                    setIsShowPreview(true);
                                    setPreviewImageUrl(image);
                                }}
                            >
                                <div
                                    className={`relative aspect-[4/3] overflow-hidden mx-auto flex items-center justify-center`}
                                >
                                    <label className="h-full w-full">
                                        <img
                                            src={image}
                                            className="max-w-full max-h-full object-contain"
                                            alt=""
                                            style={{
                                                objectFit: "contain",
                                                backgroundSize: "contain",
                                                aspectRatio: "4/3",
                                                width: "100%",
                                                height: "100%",
                                            }} // ここで object-fit: contain を適用
                                        />
                                    </label>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="flex flex-col justify-center mt-4 mb-4 items-center relative">
                    <div className="h-24 w-24 rounded-full bg-gray-200 overflow-hidden absolute cursor-pointer -top-16 z-[11]">
                        {publishData.faceImg ? (
                            <img
                                src={publishData.faceImg}
                                alt="Avatar"
                                onClick={() => {
                                    setIsShowPreview(true);
                                    setPreviewImageUrl(publishData.faceImg);
                                }}
                                className="w-full h-full object-contain"
                            />
                        ) : (
                            <svg
                                className="w-full h-full text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="0.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="12" y1="10" x2="12" y2="14" />
                                <line x1="10" y1="12" x2="14" y2="12" />
                            </svg>
                        )}
                    </div>
                    <div className="text-xl w-[80%] px-2 text-center mt-14 mb-2 py-1 bg-white/50">
                        <p>{publishData.accountName}</p>
                    </div>
                    <div className="p-2 text-lg w-[80%] h-fit bg-white/50">
                        {publishData.profile &&
                            publishData.profile
                                .split("\n")
                                .map((item, index) => (
                                    <p key={index} className="break-all">
                                        {item}
                                    </p>
                                ))}
                    </div>
                    {/* <textarea
                        readOnly
                        className=" p-3 text-lg w-[70%] h-fit bg-transparent resize-none"
                        value={publishData.profile}
                    >
                        {publishData.profile}
                    </textarea> */}
                </div>
                <div className="relative">
                    {newProfileData.map((item, index) => (
                        <div key={index} className="pb-2">
                            {(item.type === "dataLink" ||
                                item.type === "link") && (
                                <div
                                    style={{
                                        backgroundColor:
                                            publishData?.linkColor || "white",
                                    }}
                                    className="w-auto min-h-11 px-5 mx-4 rounded-xl flex item-center gap-6"
                                >
                                    <div className="shrink-0 h-[27px] w-[27px] my-auto">
                                        <img src={item.iconLink} alt="..." />
                                    </div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            isValidEmail(item.urlLink)
                                                ? `mailto:${item.urlLink}`
                                                : item.urlLink
                                                ? handleTextLink(item.urlLink)
                                                : ""
                                        }
                                        className="my-auto flex gap-4 w-full"
                                    >
                                        <p className=" whitespace-nowrap">
                                            {item?.title || item.urlLink || ""}
                                        </p>
                                        {item.text && (
                                            <p className="line-clamp-1 break-all">
                                                {item.text}
                                            </p>
                                        )}
                                    </a>
                                </div>
                            )}
                            {item.type === "imgLink1" && (
                                <div>
                                    {/* <div
                                        className={classNameImage}
                                        style={imageStyle(item.url)}
                                        onClick={() => {
                                            setIsShowPreview(true);
                                            setPreviewImageUrl(item.url);
                                        }}
                                    ></div> */}
                                    <Swiper
                                        modules={[Pagination]}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        pagination={{ clickable: true }}
                                        className="relative min-w-[335px] w-full max-h-[500px] aspect-[4/3] border-1 overflow-hidden mx-auto cursor-pointer"
                                    >
                                        {typeof item.url === "string" ? (
                                            <SwiperSlide
                                                key={index}
                                                onClick={() => {
                                                    setIsShowPreview(true);
                                                    setPreviewImageUrl(
                                                        item.url
                                                    );
                                                    setUrlPreview(
                                                        handleTextLink(
                                                            item.link
                                                        )
                                                    );
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${item.url})`,
                                                        backgroundSize:
                                                            "contain",
                                                        objectFit: "contain",
                                                        backgroundPosition:
                                                            "center",
                                                        backgroundRepeat:
                                                            "no-repeat",
                                                        scale: 1,
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                ></div>
                                            </SwiperSlide>
                                        ) : (
                                            Array.isArray(item.url) &&
                                            item.url.map((image, urlIndex) => (
                                                <SwiperSlide
                                                    key={urlIndex}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            image
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${image})`,
                                                            backgroundSize:
                                                                "contain",
                                                            objectFit:
                                                                "contain",
                                                            backgroundPosition:
                                                                "center",
                                                            backgroundRepeat:
                                                                "no-repeat",
                                                            scale: 1,
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                    ></div>
                                                </SwiperSlide>
                                            ))
                                        )}
                                    </Swiper>
                                    <div>
                                        <div className="w-full text-sm text-center px-3 break-all">
                                            {item.title}
                                        </div>
                                        <CustomTypography
                                            className="w-full text-sm text-center px-3 break-all text-gray-500"
                                            expandable
                                            maxLines={3}
                                            html={(item.text ?? "")
                                                .split("\n")
                                                .map(
                                                    (text, index) =>
                                                        `<p key=${index}>${text}</p>`
                                                )
                                                .join("")}
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "imgLink2" && (
                                <div className="flex">
                                    {item.url1 && (
                                        <div className="w-full">
                                            <div
                                                className={classNameImage}
                                                style={imageStyle(item.url1)}
                                                onClick={() => {
                                                    setIsShowPreview(true);
                                                    setPreviewImageUrl(
                                                        item.url1
                                                    );
                                                    setUrlPreview(
                                                        handleTextLink(
                                                            item.link1
                                                        )
                                                    );
                                                }}
                                            />
                                            <div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title1}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text1 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {item.url2 && (
                                        <div className="w-full">
                                            <div
                                                className={classNameImage}
                                                style={imageStyle(item.url2)}
                                                onClick={() => {
                                                    setIsShowPreview(true);
                                                    setPreviewImageUrl(
                                                        item.url2
                                                    );
                                                    setUrlPreview(
                                                        handleTextLink(
                                                            item.link2
                                                        )
                                                    );
                                                }}
                                            ></div>
                                            <div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title2}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text2 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {item.type === "imgLink3" && (
                                <>
                                    {/* パソコン画面の大きさの時 */}
                                    <div className="hidden lg:flex">
                                        {item.url1 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url1
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url1
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link1
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title1}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text1 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                        {item.url2 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url2
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url2
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link2
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title2}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text2 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                        {item.url3 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url3
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url3
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link3
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title3}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text3 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* それより小さい画面の時 */}
                                    <div className="lg:hidden">
                                        <Swiper
                                            spaceBetween={0}
                                            slidesPerView={1}
                                        >
                                            {[
                                                {
                                                    url: item.url1,
                                                    title: item.title1,
                                                    text: item.text1,
                                                    link: item.link1,
                                                },
                                                {
                                                    url: item.url2,
                                                    title: item.title2,
                                                    text: item.text2,
                                                    link: item.link2,
                                                },
                                                {
                                                    url: item.url3,
                                                    title: item.title3,
                                                    text: item.text3,
                                                    link: item.link3,
                                                },
                                            ]
                                                .filter((item) => item.url)
                                                .map((image, i) => (
                                                    <SwiperSlide key={i}>
                                                        <div className="w-full">
                                                            <div
                                                                className={
                                                                    classNameImage
                                                                }
                                                                style={imageStyle(
                                                                    image.url
                                                                )}
                                                                onClick={() => {
                                                                    setIsShowPreview(
                                                                        true
                                                                    );
                                                                    setPreviewImageUrl(
                                                                        image.url
                                                                    );
                                                                    setUrlPreview(
                                                                        handleTextLink(
                                                                            image.link
                                                                        )
                                                                    );
                                                                }}
                                                            ></div>
                                                            <div className="w-full text-sm font-bold text-center px-3">
                                                                {image.title}
                                                            </div>
                                                            <CustomTypography
                                                                className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                                expandable
                                                                maxLines={3}
                                                                html={(
                                                                    image.text ??
                                                                    ""
                                                                )
                                                                    .split("\n")
                                                                    .map(
                                                                        (
                                                                            text,
                                                                            index
                                                                        ) =>
                                                                            `<p key=${index}>${text}</p>`
                                                                    )
                                                                    .join("")}
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                    </div>
                                </>
                            )}
                            {item.type === "imgLink4" && (
                                <>
                                    {/* パソコン画面の大きさの時 */}
                                    <div className="hidden lg:flex">
                                        {item.url1 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url1
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url1
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link1
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title1}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text1 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                        {item.url2 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url2
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url2
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link2
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title2}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text2 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                        {item.url3 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url3
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url3
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link3
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title3}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text3 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                        {item.url4 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url4
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url4
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link4
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title4}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                    expandable
                                                    maxLines={3}
                                                    html={(item.text4 ?? "")
                                                        .split("\n")
                                                        .map(
                                                            (text, index) =>
                                                                `<p key=${index}>${text}</p>`
                                                        )
                                                        .join("")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* それより小さい画面の時 */}
                                    <div className="lg:hidden">
                                        <Swiper
                                            spaceBetween={0}
                                            slidesPerView={1}
                                        >
                                            {[
                                                {
                                                    url: item.url1,
                                                    title: item.title1,
                                                    text: item.text1,
                                                    link: item.link1,
                                                },
                                                {
                                                    url: item.url2,
                                                    title: item.title2,
                                                    text: item.text2,
                                                    link: item.link2,
                                                },
                                                {
                                                    url: item.url3,
                                                    title: item.title3,
                                                    text: item.text3,
                                                    link: item.link3,
                                                },
                                                {
                                                    url: item.url4,
                                                    title: item.title4,
                                                    text: item.text4,
                                                    link: item.link4,
                                                },
                                            ]
                                                .filter((item) => item.url)
                                                .map((image, i) => (
                                                    <SwiperSlide key={i}>
                                                        <div className="w-full">
                                                            <div
                                                                className={
                                                                    classNameImage
                                                                }
                                                                style={imageStyle(
                                                                    image.url
                                                                )}
                                                                onClick={() => {
                                                                    setIsShowPreview(
                                                                        true
                                                                    );
                                                                    setPreviewImageUrl(
                                                                        image.url
                                                                    );
                                                                    setUrlPreview(
                                                                        handleTextLink(
                                                                            image.link
                                                                        )
                                                                    );
                                                                }}
                                                            ></div>
                                                            <div className="w-full text-sm text-center px-3 break-all">
                                                                {image.title}
                                                            </div>
                                                            <CustomTypography
                                                                className="w-full text-sm text-center px-3 break-all text-gray-500"
                                                                expandable
                                                                maxLines={3}
                                                                html={(
                                                                    image.text ??
                                                                    ""
                                                                )
                                                                    .split("\n")
                                                                    .map(
                                                                        (
                                                                            text,
                                                                            index
                                                                        ) =>
                                                                            `<p key=${index}>${text}</p>`
                                                                    )
                                                                    .join("")}
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                    </div>
                                </>
                            )}
                            {item.type === "videoLink" && (
                                <div className="relative w-full h-auto aspect-video rounded-lg border border-white overflow-hidden mx-auto">
                                    {(item.data || item.url) && (
                                        <ReactPlayer
                                            url={
                                                item.url ||
                                                item.data ||
                                                item.data.url
                                            }
                                            playing={isPlaying}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            onClick={() => {
                                                handlePlaying();
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            {item.type === "text" && (
                                <div
                                    style={{
                                        backgroundColor:
                                            item.bgColor || "transparent",
                                    }}
                                    className="w-full h-auto text-wrap border "
                                >
                                    <div className="p-4 text-wrap break-words ql-editor custom-quilljs">
                                        <CustomTypography
                                            html={new QuillDeltaToHtmlConverter(
                                                item.text.ops,
                                                {}
                                            ).convert()}
                                            maxLines={5}
                                            expandable
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "textLink" && (
                                <div
                                    style={{
                                        backgroundColor:
                                            item.bgColor || "transparent",
                                    }}
                                    className="w-full h-auto text-wrap border "
                                >
                                    <div className="p-4 text-wrap break-words ql-editor custom-quilljs">
                                        <CustomTypography
                                            html={new QuillDeltaToHtmlConverter(
                                                item.text.ops,
                                                {}
                                            ).convert()}
                                            maxLines={5}
                                            expandable
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "mapLink" && (
                                <div style={{ width: "100%", height: "400px" }}>
                                    <iframe
                                        title="Google Map"
                                        width="100%"
                                        height="100%"
                                        style={{ border: "0" }}
                                        src={
                                            item.mapLink.startsWith(
                                                "https://www.google.com/maps/embed"
                                            )
                                                ? item.mapLink
                                                : item.mapLink.startsWith(
                                                      "https://www.google.com/maps/"
                                                  )
                                                ? generateEmbedUrl(item.mapLink)
                                                : getEmbedUrl(item.mapLink)
                                        }
                                        allowFullScreen
                                    />
                                </div>
                            )}
                            {item.type === "map" && (
                                <div
                                    style={{ width: "100%" }}
                                    className="aspect-video"
                                >
                                    <iframe
                                        title="Google Map"
                                        width="100%"
                                        style={{ border: "0" }}
                                        src={
                                            item.data.startsWith(
                                                "https://www.google.com/maps/embed"
                                            )
                                                ? item.data
                                                : item.data.startsWith(
                                                      "https://www.google.com/maps/"
                                                  )
                                                ? generateEmbedUrl(item.data)
                                                : getEmbedUrl(item.data)
                                        }
                                        className="aspect-video"
                                        allowFullScreen
                                    />
                                </div>
                            )}
                            {item.type === "spaceAdd" && (
                                <div
                                    className={`w-full p-2 bg-transparent opacity-50 rounded-lg`}
                                    style={{ height: item.space * 5 }}
                                ></div>
                            )}
                            {item.type === "selfProfile" && (
                                <div className={`w-full p-2 `}>
                                    <div
                                        style={{
                                            backgroundColor:
                                                item.color || "white",
                                        }}
                                        className="py-2 px-4 text-white rounded-lg cursor-pointer hover:opacity-90 active:opacity-80 overflow-hidden break-all"
                                    >
                                        {item.title}
                                    </div>
                                    <div className="w-full text-sm rounded-md p-2 px-3 my-1 text-wrap overflow-hidden break-all">
                                        <CustomTypography
                                            key={index}
                                            html={
                                                item.content &&
                                                item.content
                                                    .split("\n")
                                                    .map(
                                                        (line, index) =>
                                                            `<p key=${index}>${line}</p>`
                                                    )
                                                    .join("")
                                            }
                                            maxLines={3}
                                            expandable
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "slideLink" && (
                                <div className="flex flex-col items-center w-full">
                                    <div
                                        className="overflow-y-auto w-full aspect-[4/3] bg-slate-300"
                                        ref={docContainerRef}
                                    >
                                        <PdfLoader
                                            file={item.url}
                                            pdfWidth={pdfWidth}
                                        />
                                    </div>
                                    <div className="w-full text-sm font-bold text-center px-3 break-all">
                                        {item.title}
                                    </div>

                                    <CustomTypography
                                        className="w-full text-sm text-center px-3 break-all"
                                        expandable
                                        maxLines={3}
                                        html={(item.text ?? "")
                                            .split("\n")
                                            .map(
                                                (text, index) =>
                                                    `<p key=${index}>${text}</p>`
                                            )
                                            .join("")}
                                    />

                                    {item.enableDownload && (
                                        <button
                                            className="border-2 border-[#FF8C05] bg-white p-2 px-7 mt-3 rounded-full text-red-600 flex drop-shadow-lg font-bold"
                                            onClick={() =>
                                                handleDownload(
                                                    item.url,
                                                    item.title || "document"
                                                )
                                            }
                                        >
                                            ダウンロード
                                            <img
                                                className="shrink-0"
                                                alt="download"
                                                src="/image/ダウンロード.png"
                                                width="25"
                                            />
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <PreviewModal
                    open={isShowPreview}
                    onClose={() => {
                        setIsShowPreview(false);
                        setPreviewImageUrl("");
                        setUrlPreview("");
                    }}
                    imageUrl={previewImageUrl}
                    imageLink={urlPreview}
                />
                {/* <DraggableList onChangeData={handleDraggableList} />

        <DndProvider backend={HTML5Backend}>
          {
            profileData && profileData.map((item, index) =>(
              <Listcomponent key = {index} id = {index} index={index} item={item} moveItem={moveItem} isDelete = {deleteProfileData} />
            ))
          }
        </DndProvider>
        <div className="mt-[40px]">
          {
            profileButton.map((item, key)=>(
              item.type!=="link" && <div key={key} type={item.type} className="p-3 px-5 border rounded-md my-3 cursor-pointer border-black text-black/30 hover:text-black" onClick={handleModal} >{item.placeholder}</div>
            ))
          }
        </div> */}
            </div>
        </div>
    );
}
