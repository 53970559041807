import { Button, styled, Switch } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Apis } from "../api";
import utf8 from "utf8";
import { useAuthContext } from "../auth/context";
import ModalConfirm from "../components/Modal/ModalConfirm";
import { useNavigate } from "react-router";

const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor:
                    theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export default function Setting() {
    const [profile, setProfile] = useState({});
    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [urlBusiness, setUrlBusiness] = useState("");
    const [urlSns, setUrlSns] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailDelivery, setIsEmailDelivery] = useState(true);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [isShowNew, setIsShowNew] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordConfirmValid, setIsPasswordConfirmValid] = useState("");

    const [isUpdatePassword, setIsUpdatePassword] = useState(false);
    const [isUpdateFullName, setIsUpdateFullName] = useState(true);
    const [isUpdateUserName, setIsUpdateUserName] = useState(true);

    const [isUpdateEmail, setIsUpdateEmail] = useState(true);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [isSendMail, setIsSendMail] = useState(false);
    const { user, setShowLoginModal, setLoading, resetUserInfo } =
        useAuthContext();

    const mailRef = useRef(null);
    const askRef = useRef(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get("section");
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        const response = await Apis.myGet("account/profile");

        if (response.success) {
            setFullName(response.data.data.full_name ?? "");
            // setFirstName(response.data.data.first_name ?? "");
            // setLastName(response.data.data.last_name ?? "");
            setUsername(response.data.data.username ?? "");
            // setAvatar(response.data.data.avatar ?? "");
            setUrlBusiness(response.data.data.url_business);
            setUrlSns(response.data.data.url_sns);
            setEmail(response.data.data.email);
            setIsEmailDelivery(response.data.data.is_email_delivery);
            setProfile(response.data.data);

            setTimeout(function () {
                if (section === "mail") {
                    mailRef?.current?.scrollIntoView({
                        behavior: "auto",
                    });
                }
                if (section === "ask") {
                    askRef?.current?.scrollIntoView({
                        behavior: "auto",
                    });
                }
            }, 1000);
        }
    };

    const handleSendMail = async () => {
        const response = await Apis.myPost("account/ask", {
            title: title,
            content: content,
        });
        if (response?.data) {
            setTitle("");
            setContent("");
            toast.success("あなたの質問は送信されました。");
        }
    };

    const handleOpenDeleteModal = () => {
        setIsShowDeleteModal(true);
    };

    const handleDeleteAccount = async () => {
        try {
            setLoading(true);
            const response = await Apis.myDelete("account/profile");

            if (response.data) {
                navigate("/d-dashboard");
                setIsShowDeleteModal(false);
                toast.success("アカウントが削除されました。");
                resetUserInfo();
                return;
            }
            throw new Error("アカウント削除に失敗しました。");
        } catch (error) {
            console.error(error);
            setIsShowDeleteModal(false);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // const handleFirstname = async () => {
    //     if (
    //         !isUpdateFirstName &&
    //         firstName &&
    //         firstName !== profile.first_name
    //     ) {
    //         const response = await Apis.myPut("account/profile", {
    //             firstName: firstName,
    //         });
    //         if (response.success) {
    //             setProfile({
    //                 ...profile,
    //                 first_name: response.data.data.first_name,
    //             });
    //             toast.success("プロフィールを更新しました");
    //         }
    //     }
    //     setIsUpdateFirstName(!isUpdateFirstName);
    // };
    // const handleLastName = async () => {
    //     if (!isUpdateLastName && lastName && lastName !== profile.last_name) {
    //         const response = await Apis.myPut("account/profile", {
    //             lastName: lastName,
    //         });
    //         if (response.success) {
    //             setProfile({
    //                 ...profile,
    //                 last_name: response.data.data.last_name,
    //             });
    //             toast.success("プロフィールを更新しました");
    //         }
    //     }
    //     setIsUpdateLastName(!isUpdateLastName);
    // };
    const handleFullName = async () => {
        if (!isUpdateFullName && fullName !== profile.full_name) {
            const response = await Apis.myPut("account/profile", {
                fullName: fullName,
            });
            if (response.success) {
                setProfile({
                    ...profile,
                    full_name: response.data.data.full_name,
                });
                toast.success("プロフィールを更新しました");
            }
        }
        setIsUpdateFullName(!isUpdateFullName);
    };

    const handleUserName = async () => {
        if (!isUpdateUserName && username && username !== profile.username) {
            try {
                const response = await Apis.myPut("account/profile", {
                    username: username,
                });
                if (response.success) {
                    setProfile({
                        ...profile,
                        username: response.data.data.username,
                        urlBusiness: response.data.data.url_business,
                        urlSns: response.data.data.url_sns,
                    });
                    setUrlBusiness(response.data.data.url_business);
                    setUrlSns(response.data.data.url_sns);
                    toast.success("プロフィールを更新しました");
                }
            } catch (error) {
                if (error.message === "Username is exist") {
                    toast.error("すでに登録済みです。他に変更してください。");
                    return;
                }
                toast.error(error.message);
                return;
            }
        }
        setIsUpdateUserName(!isUpdateUserName);
    };

    // const handleAvatar = async (link, style) => {
    //     setAvatar(link);
    //     if (link) {
    //         const response = await Apis.myPut("account/profile", {
    //             avatar: link,
    //         });
    //         if (response.success) {
    //             setProfile({ ...profile, avatar: link });
    //             toast.success("プロフィールを更新しました");
    //         }
    //     }
    // };

    const handleEmail = () => {
        if (!isUpdateEmail) {
            setEmail(profile.email);
            setIsEmailValid(false);
        }
        setIsUpdateEmail(!isUpdateEmail);
    };

    // const FileUpload = async (file) => {
    //     const cloud_name = "dz6r3o4w0";
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     formData.append("upload_preset", "dfuqz9xv");
    //     formData.append("cloud_name", cloud_name);

    //     try {
    //         const res = await fetch(
    //             "https://api.cloudinary.com/v1_1/dz6r3o4w0/auto/upload",
    //             {
    //                 method: "POST",
    //                 body: formData,
    //             }
    //         );
    //         const data = await res.json();
    //         return data.secure_url;
    //     } catch (err) {
    //         toast.error(`${err.message}ファイルアップロードに失敗しました`);
    //     }
    // };

    const handleShowClick = () => {
        setIsShow((pre) => !pre);
    };
    const handleShowNewClick = () => {
        setIsShowNew((pre) => !pre);
    };
    const handleShowConfirmClick = () => {
        setIsShowConfirm((pre) => !pre);
    };

    const handleIsEmailDelivery = async (e) => {
        setIsEmailDelivery(e.target.checked);

        await Apis.myPut("account/profile", {
            isEmailDelivery: e.target.checked,
        });
    };

    /* -------------------------------------------------------------------------- */
    /*                                Update Email                                */
    /* -------------------------------------------------------------------------- */
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleUpdateEmail = async () => {
        const isValid = validateEmail(email);

        if (!isValid) {
            setIsEmailValid(true);
            return;
        }

        try {
            const response = await Apis.myPut("account/request-update-email", {
                newEmail: email,
            });
            if (response.success) {
                setEmail(profile.email);
                setIsUpdateEmail(true);
                toast.success("メール更新のリクエストが送信されました。");
            }
        } catch (error) {
            if (error.message === "Email already exist") {
                toast.error("すでに登録済みです。他に変更してください。");
                return;
            }
            toast.error(error.message);
            return;
        }
    };

    /* -------------------------------------------------------------------------- */
    /*                               Update Password                              */
    /* -------------------------------------------------------------------------- */

    const handleResetPassword = async () => {
        let isInValid = false;

        if (newPassword !== newPasswordConfirm) {
            isInValid = true;
            setIsPasswordConfirmValid("パスワードが一致しません。");
        } else {
            setIsPasswordConfirmValid("");
        }

        if (isInValid) return;
        try {
            const response = await Apis.myPost(`account/change_password`, {
                old_password: utf8.encode(oldPassword),
                new_password: utf8.encode(newPassword),
            });
            if (response.data) {
                setOldPassword("");
                setNewPassword("");
                setNewPasswordConfirm("");
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        const checkDisableUpdatePassword =
            user?.is_register_SNS && !user?.email_verified
                ? newPassword && newPasswordConfirm
                : oldPassword && newPassword && newPasswordConfirm;
        setIsUpdatePassword(checkDisableUpdatePassword);
    }, [oldPassword, newPassword, newPasswordConfirm]);

    useEffect(() => {
        setIsSendMail(title && content);
    }, [title, content]);

    useEffect(() => {
        if (!user) {
            setShowLoginModal(true);
            return;
        }
        fetchData();
    }, [user]);

    return (
        <div className="p-4 md:p-10">
            <Link to="/d-dashboard">
                <img
                    alt="logo"
                    src="/image/logo.png"
                    width="150"
                    className="pt-3 pl-5 mb-5"
                />
            </Link>
            <label className="text-md mt-3">プロフィールURL</label>
            <div className="w-full border rounded-md p-2 my-2">
                {!!urlBusiness ? (
                    <a
                        href={"https://degime.net/" + urlBusiness}
                        target="_blank"
                        className="text-blue-600 hover:text-blue-700 cursor-pointer"
                    >
                        {"https://degime.net/" + urlBusiness}
                    </a>
                ) : (
                    <div>&nbsp;</div>
                )}
            </div>
            <div className="w-full border rounded-md p-2">
                {!!urlSns ? (
                    <a
                        href={"https://degime.net/" + urlSns}
                        target="_blank"
                        className="text-blue-600 hover:text-blue-700 cursor-pointer"
                    >
                        {"https://degime.net/" + urlSns}
                    </a>
                ) : (
                    <div>&nbsp;</div>
                )}
            </div>
            {/* <div className="mt-10 py-1 px-4 inline-block text-white bg-sky-500 rounded-lg">
                NFCに書き込み
            </div> */}
            <div className="flex gap-5">
                {/* <div className="w-1/2">
                    <label className="text-md mt-3">名前（姓）</label>
                    <div className="relative">
                        <input
                            type="text"
                            value={firstName}
                            disabled={isUpdateFirstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="w-full p-2 text-lg border rounded-md"
                        />
                        <div
                            className="absolute top-1/2 right-2 py-1 px-4 text-white bg-green-500 hover:bg-green-700 active:bg-green-900 rounded-lg -translate-y-1/2 cursor-pointer"
                            onClick={handleFirstname}
                        >
                            編集
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <label className="text-md mt-3">名前（名）</label>
                    <div className="relative">
                        <input
                            type="text"
                            value={lastName}
                            disabled={isUpdateLastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="w-full p-2 text-lg border rounded-md"
                        />
                        <div
                            className="absolute top-1/2 right-2 py-1 px-4 text-white bg-green-500 hover:bg-green-700 active:bg-green-900 rounded-lg -translate-y-1/2 cursor-pointer"
                            onClick={handleLastName}
                        >
                            編集
                        </div>
                    </div>
                </div> */}
                <div className="w-full">
                    <label className="text-md mt-3">氏名</label>
                    <div className="relative">
                        <input
                            type="text"
                            value={fullName}
                            disabled={isUpdateFullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="w-full p-2 text-lg border rounded-md"
                        />
                        {isUpdateFullName ? (
                            <div
                                className="absolute top-1/2 right-2 py-1 px-4 text-white bg-green-500 hover:bg-green-700 active:bg-green-900 rounded-lg -translate-y-1/2 cursor-pointer"
                                onClick={handleFullName}
                            >
                                編集
                            </div>
                        ) : (
                            <div
                                className="absolute top-1/2 right-2 py-1 px-4 text-white bg-[#2ACEFF] hover:bg-[#45caf3] active:bg-[#3dc8e0] rounded-lg -translate-y-1/2 cursor-pointer"
                                onClick={handleFullName}
                            >
                                更新する
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <label className="text-md mt-5">ユーザー名</label>
            <div className="relative">
                <input
                    type="text"
                    value={username}
                    disabled={isUpdateUserName}
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                    className="w-full p-2 text-lg border rounded-md"
                />
                {isUpdateUserName ? (
                    <div
                        className="absolute top-1/2 right-2 py-1 px-4 text-white bg-green-500 hover:bg-green-700 active:bg-green-900 rounded-lg -translate-y-1/2 cursor-pointer"
                        onClick={handleUserName}
                    >
                        編集
                    </div>
                ) : (
                    <div
                        className="absolute top-1/2 right-2 py-1 px-4 text-white bg-[#2ACEFF] hover:bg-[#45caf3] active:bg-[#3dc8e0] rounded-lg -translate-y-1/2 cursor-pointer"
                        onClick={handleUserName}
                    >
                        更新する
                    </div>
                )}
            </div>

            {/* <label className="text-md mt-5">アバター</label>
            <div className="flex justify-center mt-[60px] mb-[40px] items-center w-full mx-auto">
                <div className="w-[150px]">
                    <AvatarUploader
                        key="avatarUploader"
                        onChangeData={handleAvatar}
                        initialData={avatar}
                        FileUpload={FileUpload}
                    />
                </div>
            </div> */}
            <label className="text-md mt-3">メールアドレス</label>
            <div className="relative">
                <input
                    type="text"
                    value={email}
                    disabled={isUpdateEmail}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    className="w-full p-2 text-lg border rounded-md"
                />
                {isUpdateEmail ? (
                    <div
                        className="absolute top-1/2 right-2 py-1 px-4 text-white bg-green-500 hover:bg-green-700 active:bg-green-900 rounded-lg -translate-y-1/2 cursor-pointer"
                        onClick={handleEmail}
                    >
                        編集
                    </div>
                ) : (
                    <div
                        className="absolute top-1/2 right-2 py-1 px-4 text-white bg-[#2ACEFF] hover:bg-[#45caf3] active:bg-[#3dc8e0] rounded-lg -translate-y-1/2 cursor-pointer"
                        onClick={handleEmail}
                    >
                        更新する
                    </div>
                )}
            </div>

            <div
                className={`${
                    !isEmailValid ? "opacity-0" : ""
                } text-xs text-red-500 px-2 pt-1`}
            >
                無効な e メール形式です。
            </div>
            <div className="flex justify-around items-center my-3 flex-wrap">
                <p className="sm:w-full text-sm md:w-[60%]">
                    メールアドレスを変更する場合は、新しいメールアドレスに変更のうえ、「仮登録メールを送信」を
                    クリックしてください。
                    送信後24時間以内に、メールに記載されているURLをクリックしていただくと、メールアドレス変更が完了となります。
                </p>
                <Button
                    variant="contained"
                    className="md:w-[20%] mt-3 bg-zinc-300 hover:bg-zinc-400 active:bg-zinc-500 cursor-pointer p-3 rounded-md"
                    disabled={isUpdateEmail}
                    onClick={handleUpdateEmail}
                >
                    仮登録メールを送信
                </Button>
            </div>
            <div className="py-3 my-3 px-4 border-l-4 border-l-indigo-600">
                パスワードの変更
            </div>
            <form className="md:w-[50%] sm:w-full">
                <div>
                    <label className="text-md mt-3">
                        変更したい現在使用中のパスワード入力
                    </label>
                    <div className="flex items-center">
                        <input
                            type={isShow ? "text" : "password"}
                            value={oldPassword}
                            disabled={user?.is_register_SNS}
                            onChange={(e) =>
                                setOldPassword(e.target.value.trim())
                            }
                            className="w-full p-2 text-lg border rounded-md"
                        />
                        <img
                            alt="eye"
                            src={
                                isShow
                                    ? "/image/eye-open.png"
                                    : "/image/eye-close.png"
                            }
                            onClick={handleShowClick}
                            className="cursor-pointer max-w-4 h-auto -ml-8"
                            style={{ maxHeight: "100%" }}
                        ></img>
                    </div>
                </div>
                <div>
                    <label className="text-md mt-3">
                        新しいパスワードを入力
                    </label>
                    <div className="flex items-center">
                        <input
                            type={isShowNew ? "text" : "password"}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-2 text-lg border rounded-md"
                        />
                        <img
                            alt="eye"
                            src={
                                isShowNew
                                    ? "/image/eye-open.png"
                                    : "/image/eye-close.png"
                            }
                            onClick={handleShowNewClick}
                            className="cursor-pointer max-w-4 h-auto -ml-8"
                            style={{ maxHeight: "100%" }}
                        ></img>
                    </div>
                </div>
                <div>
                    <label className="text-md mt-3">
                        新しいパスワードを再入力
                    </label>
                    <div className="flex items-center">
                        <input
                            type={isShowConfirm ? "text" : "password"}
                            value={newPasswordConfirm}
                            onChange={(e) =>
                                setNewPasswordConfirm(e.target.value)
                            }
                            className="w-full p-2 text-lg border rounded-md"
                        />
                        <img
                            alt="eye"
                            src={
                                isShowConfirm
                                    ? "/image/eye-open.png"
                                    : "/image/eye-close.png"
                            }
                            onClick={handleShowConfirmClick}
                            className="cursor-pointer max-w-4 h-auto -ml-8"
                            style={{ maxHeight: "100%" }}
                        ></img>
                    </div>
                    {!!isPasswordConfirmValid &&
                    !!isPasswordConfirmValid.length ? (
                        <div className="text-red-500 text-xs pl-2 py-1">
                            {isPasswordConfirmValid}
                        </div>
                    ) : (
                        <div className="text-red-500 text-xs pl-2 py-1">
                            &nbsp;
                        </div>
                    )}
                </div>
                <div className="flex items-center justify-end mb-4 mt-2">
                    <Button
                        className="w-[150px] text-center p-2  rounded-md cursor-pointer"
                        onClick={handleResetPassword}
                        variant="contained"
                        disabled={!isUpdatePassword}
                    >
                        パスワード変更
                    </Button>
                </div>
            </form>
            <div ref={mailRef} className="flex gap-10 items-center">
                <div className="py-3 px-4 border-l-4 border-l-indigo-600">
                    メール配信状況
                </div>
                <IOSSwitch
                    sx={{ m: 2 }}
                    checked={isEmailDelivery}
                    onChange={handleIsEmailDelivery}
                />
            </div>
            <div ref={askRef}>
                <div className="py-3 my-3 md:px-4 border-l-4 border-l-indigo-600">
                    ご相談・ご意見・ご質問
                </div>
                <div className="md:p-5">
                    <div>アカウント名：　{username}</div>
                    <div>返信先のメールアドレス：　{email}</div>
                    <label className="text-md mt-3">件名</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full p-2 text-lg border rounded-md"
                    />
                    <label className="text-md mt-3">本文</label>
                    <textarea
                        type="text"
                        rows={3}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="w-full p-2 text-lg border rounded-md"
                    />
                    <div className="flex items-center justify-end mb-4 mt-2">
                        <Button
                            onClick={handleSendMail}
                            variant="contained"
                            disabled={!isSendMail}
                            className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md bg-slate-300 hover:bg-slate-400 active:bg-slate-500 cursor-pointer"
                        >
                            送信する
                        </Button>
                    </div>
                </div>
            </div>
            <div className="flex justify-end w-full md:px-5">
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#f44336",
                        ":hover": {
                            backgroundColor: "#f44336",
                        },
                        ":active": {
                            backgroundColor: "#f44336",
                        },
                    }}
                    className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md cursor-pointer"
                    onClick={handleOpenDeleteModal}
                >
                    アカウントを削除
                </Button>
            </div>
            <ModalConfirm
                isOpen={isShowDeleteModal}
                onClose={() => setIsShowDeleteModal(false)}
                title="公開中ページも含めて、全ての登録情報が削除されますが本当によろしいですか？※課金プランをご利用中の場合も返金しません。"
                onOk={handleDeleteAccount}
            />
        </div>
    );
}
