import dayjs from "dayjs";
import { Button } from "@mui/material";

const MailTable = ({
    data,
    page,
    per_page,
    total,
    onChangePage,
    onSelectTemplate,
}) => {
    return (
        <div className="shadow-xl rounded-lg overflow-x-auto">
            <table className="w-full text-center">
                <tbody>
                    <tr className=" bg-[#F7F9FD] !text-center">
                        <th className="p-2">No</th>
                        <th className="p-2 w-36">送信開始日時</th>
                        <th className="p-2 w-[400px]">件名</th> 
                        <th className="p-2">送信数</th>
                        <th className="py-3">{""}</th>
                    </tr>
                    {data.length > 0 ? (
                        data?.map((item, index) => (
                            <tr
                                key={index}
                                className={`even:bg-white odd:bg-[#EBF2FF] !text-start`}
                            >
                                <td className="p-2 text-center">
                                    {(page - 1) * per_page + index + 1}
                                </td>
                                <td className="p-2 w-36">
                                    {dayjs(item.created_at).format(
                                        "YYYY-MM-DD HH:mm"
                                    )}
                                </td>
                                <td className="p-2 w-[400px] text-center">
                                    {item.title}
                                </td>
                                <td className="p-2 text-center">
                                    {item.submissionCount}
                                </td>

                                <td className="p-2">
                                    <div className="flex w-full justify-center items-center">
                                        <Button
                                            onClick={() =>
                                                onSelectTemplate(item)
                                            }
                                            className="!rounded-lg !p-1 cursor-pointer !text-white !bg-lime-400 hover:bg-lime-500 active:bg-lime-600 !text-sm px-1 md:px-4"
                                        >
                                            本文表示
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="p-2 pl-3">
                                データなし
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {/* Render the pagination controls here */}
            <div className="w-full p-3 flex justify-around items-center bg-white !text-slate-400">
                <div className="flex items-center">
                    <button
                        onClick={() => {
                            onChangePage(page - 1);
                        }}
                        disabled={page === 1}
                        className="mr-2 max-[400px]:ml-2 ml-10"
                    >
                        <svg
                            width="14"
                            height="23"
                            viewBox="0 0 14 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13 22L1 11.5L13 1"
                                stroke="#868FA0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                        {page} / {Math.ceil((total || data?.length) / per_page)}
                    </span>
                    <button
                        onClick={() => onChangePage(page + 1)}
                        disabled={
                            page ===
                                Math.ceil((total || data?.length) / per_page) ||
                            !data ||
                            data?.length === 0
                        }
                        className="mx-2"
                    >
                        <svg
                            width="14"
                            height="23"
                            viewBox="0 0 14 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 1L13 11.5L1 22"
                                stroke="#868FA0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MailTable;
