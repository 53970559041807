import React, { useEffect, useState } from "react";
import { selfProfile } from "../const/common.js";
import ColorBgPicker from "./ColorBgPicker";

export default function Selfprofile({ isClear, onChangeData, data }) {
    const [openColorDialog, setOpenColorDialog] = useState(false);
    const [state, setState] = useState({
        title: "",
        color: "",
        text: "",
    });

    const handleTitleType = (e) => {
        e.preventDefault();
        const color = selfProfile.find(
            (item) => item.type === e.target.innerHTML
        )?.color;
        setState({ ...state, title: e.target.innerHTML, color: color });
    };

    const onChangeColor = (color) => {
        setState({ ...state, color: color.hex });
    };

    useEffect(() => {
        if (data) {
            setState({
                title: data.title ?? "",
                color: data.color ?? "",
                text: data.text ?? data.content ?? "",
            });
        }
    }, [data]);

    useEffect(() => {
        if (isClear) {
            setState({
                title: "",
                color: "",
                text: "",
            });
        }
    }, [isClear]);

    useEffect(() => {
        onChangeData({
            data: state,
            type: "selfProfile",
        });
    }, [state]);
    return (
        <div>
            <div className="flex flex-wrap gap-1 md:gap-3 mb-2 md:mb-5">
                {selfProfile.map((item, index) => (
                    <div
                        key={index}
                        style={{ backgroundColor: item.color }}
                        className="py-2 px-4 text-white rounded-lg cursor-pointer hover:opacity-90 active:opacity-80"
                        onClick={handleTitleType}
                    >
                        {item.type}
                    </div>
                ))}
            </div>
            <div>
                <div>
                    <ColorBgPicker
                        isOpen={openColorDialog}
                        onClose={() => setOpenColorDialog(false)}
                        onChangeColor={onChangeColor}
                        color={state.color || "#FFFFFF"}
                    >
                        <div className="p-[10px] flex justify-end">
                            <div
                                onClick={() => {
                                    setOpenColorDialog(true);
                                }}
                                className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white mr-3 px-5 py-1 rounded-lg"
                            >
                                背景
                            </div>
                        </div>
                    </ColorBgPicker>
                </div>
                <div
                    style={{ backgroundColor: state.color || "white" }}
                    className="p-2 rounded-md"
                >
                    <input
                        className="p-3 w-full border mb-3 rounded-md bg-white/70"
                        value={state.title}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                title: e.target.value,
                            }));
                        }}
                        placeholder="タイトル選択"
                    />
                    <textarea
                        className="p-3 w-full border rounded-md bg-white/70 max-h-[200px]"
                        value={state.text}
                        onChange={(e) => {
                            setState((prevState) => ({
                                ...prevState,
                                text: e.target.value,
                            }));
                        }}
                        placeholder="テキスト入力"
                    />
                </div>
            </div>
        </div>
    );
}
