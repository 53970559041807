import { Button, createTheme, ThemeProvider } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
} from "tw-elements-react";
import { myConfig } from "../../../api/index";
import { useAuthContext } from "../../../auth/context";

const theme = createTheme({
    palette: {
        line: {
            main: "#06C755",
        },
        black: {
            main: "#000000",
        },
        normal: {
            main: "#01AEC2",
        },
    },
});

const LoginSelectModal = () => {
    const {
        setUserInfo,
        showLoginModal,
        setShowLoginModal,
        setShowLoginEmailModal,
        liff,
    } = useAuthContext();
    // Login/Register Data

    // Login by google
    const login = useGoogleLogin({
        onSuccess: async (response) => {
            setShowLoginModal(false);

            // バックエンドにトークンを送信してログイン
            try {
                const res = await fetch(`${myConfig.apiUrl}/account/google`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        access_token: response.access_token,
                    }),
                });
                const data = await res.json();
                if (!res.ok) {
                    throw new Error(data.error);
                }
                setUserInfo(data);
                toast.success(`ログインに成功しました`);
            } catch (error) {
                console.error("Error:", error);
                toast.error(error.message);
            }
        },
        onFailure: (response) => {
            console.log(response);
        },
    });

    // Login by Line
    const handleLoginByLine = async () => {
        localStorage.setItem(
            "redirectUrl",
            window.location.href.replace("https://degime.net", "")
        );
        liff.login();
    };

    // Login by Line
    const handleLoginNormal = async () => {
        setShowLoginModal(false);
        setShowLoginEmailModal(true);
    };

    return (
        <>
            <TEModal
                show={showLoginModal}
                setShow={setShowLoginModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        display: "flex",
                        WebkitTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%) translateZ(0)",
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        maxHeight: "100%",
                        // overflowY: "auto", // 縦方向の余剰コンテンツをスクロールできるように設定
                        outline: 0, // フォーカス時の青い枠線を消す
                        maxWidth: "100%",
                    }}
                >
                    <TEModalContent>
                        <TEModalBody
                            style={{
                                maxHeight: "calc(100% - 0px)",
                                // overflowY: "auto",
                                width: "100%",
                            }}
                        >
                            <ThemeProvider theme={theme}>
                                <div className="relative">
                                    <button
                                        type="button"
                                        className="absolute right-0 top-0 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none z-10"
                                        onClick={() => {
                                            const event = new Event(
                                                "cancelLoginModal"
                                            );
                                            window.dispatchEvent(event);
                                            setShowLoginModal(false);
                                        }}
                                        aria-label="Close"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <div className=" login md:mx-8 min-w-[300px] md:min-w-[400px]">
                                        <div className="flex justify-center relative w-full">
                                            <Link
                                                alt="logo"
                                                className="logo"
                                                to=""
                                                onClick={() =>
                                                    setShowLoginModal(false)
                                                }
                                            ></Link>
                                        </div>

                                        <div className="socialLinks flex flex-col w-72 mt-[60px] mx-auto justify-between mb-[30px]">
                                            <div className="cursor-pointer hover:opacity-70 active:opacity-50 mt-4">
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        padding: "8px",
                                                        borderRadius: "20px",
                                                        textTransform: "none",
                                                    }}
                                                    color="black"
                                                    className="w-full"
                                                    onClick={login}
                                                    disableRipple
                                                >
                                                    <div className="flex justify-between items-center w-full px-10 text-black font-bold">
                                                        <img
                                                            className="w-6 h-auto"
                                                            alt="google"
                                                            src="/image/google.png"
                                                            style={{
                                                                maxHeight:
                                                                    "100%",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                        <div>
                                                            Googleでサインイン
                                                        </div>
                                                    </div>
                                                </Button>
                                            </div>

                                            <div className="cursor-pointer hover:opacity-70 active:opacity-50 mt-4">
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        padding: "8px",
                                                        borderRadius: "20px",
                                                    }}
                                                    color="line"
                                                    className="w-full"
                                                    onClick={handleLoginByLine}
                                                >
                                                    <div className="flex justify-between items-center w-full px-10 text-white font-bold">
                                                        <img
                                                            className="w-6 h-auto"
                                                            alt="line"
                                                            src="/image/line_44.png"
                                                            style={{
                                                                maxHeight:
                                                                    "100%",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                        <div>
                                                            LINEでサインイン
                                                        </div>
                                                    </div>
                                                </Button>
                                            </div>

                                            <div className="cursor-pointer hover:opacity-70 active:opacity-50 mt-10 mb-28">
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        padding: "8px",
                                                        borderRadius: "20px",
                                                    }}
                                                    color="normal"
                                                    className="w-full"
                                                    onClick={handleLoginNormal}
                                                >
                                                    <div className="flex justify-center items-center w-full px-10 text-white font-bold">
                                                        <div>
                                                            メールアドレスでサインイン
                                                        </div>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ThemeProvider>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </>
    );
};

export default LoginSelectModal;
