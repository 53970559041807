import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { CircularProgress } from "@mui/material";


const PdfReader = ({FileUpload, isClear, onChangeData, data}) => {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [pdfWidth, setPdfWidth] = useState(0);
  const docContainerRef = useRef(null);

  const updatePdfWidth = () => {
    if (docContainerRef.current) {
      const { width } =
        docContainerRef.current.getBoundingClientRect();
      setPdfWidth(width);
    }
  };

  useEffect(() => {
      window.addEventListener("resize", updatePdfWidth);
      updatePdfWidth(); // Initial pdf width
      return () => window.removeEventListener("resize", updatePdfWidth); // Cleanup listener
  });

  useEffect(() => {
    if (data) {
      setFile(data.file ?? data.url ?? "")
      setTitle(data.title)
      setText(data.text)
      setNumPages(data.numPages)
    }
  }, [data]) 

  useEffect(()=>{
    if(isClear) {
      setTitle("");
      setText("");
      setFile(null);
      setTitle("")
      setText("")
      setNumPages(null);
    }
  }, [isClear]);
  const onFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setIsLoading(true);
        const uploaded_url = await FileUpload(selectedFile);
        onChangeData({data:{file:uploaded_url, title:title, text:text, numPages}, type:"slideImage"})
        setFile(uploaded_url);
        e.target.value = null;
        setIsLoading(false);
      } catch (error) {
        console.error('Error creating object URL:', error);
      }
    } else {
      console.error('No file selected');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const LoadingComponent = (
    <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex items-center justify-center">
      <CircularProgress />
    </div>
  )
  return (
    <div className="w-full">
      <label htmlFor='pdfUpload' className='py-1 px-3 inline-block opacity-80 mb-1 text-white cursor-pointer hover:opacity-100 active:opacity-40 rounded-full bg-orange-400' >PDFアップロード <input type="file" id="pdfUpload" className='hidden' onChange={onFileChange} /></label>
      <div className='relative overflow-y-auto w-full aspect-[1.41/1] bg-slate-300' ref={docContainerRef}>
        {isLoading ? LoadingComponent : !file ? 
          <div className='flex items-center justify-center w-full h-full'><span>PDFデータのみに対応しています</span></div>
        : (
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={LoadingComponent} >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pdfWidth} />
            ))}
          </Document>
        )}
      </div>
      <input className='w-full text-sm border border-black rounded-md p-2 px-3 my-1 mt-2' name="link-1" placeholder='タイトル' value={title} onChange={(e) =>{setTitle(e.target.value); onChangeData({data:{file:file, title:e.target.value, text:text}, type:"slideImage"}) }} />
      <input className='w-full text-sm border border-black rounded-md p-2 px-3 my-1' name="link-2" placeholder='テキスト' value={text} onChange={(e) =>{setText(e.target.value); onChangeData({data:{file:file, title:title, text:e.target.value}, type:"slideImage"}) }} />
    </div>
  );
};

export default PdfReader;