import React, { useEffect, useState } from "react";
import { TERipple } from "tw-elements-react";

export default function SearchChat({ onSearch }) {
    const [search, setSearch] = useState("");
    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };
    const handleSearch = () => {
        onSearch(search);
    };
    useEffect(() => {
        // Debounce search
        const timeout = setTimeout(() => {
            onSearch(search);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);
    return (
        <div className="mb-3 md:w-96 mx-auto">
            <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                    <input
                        type="search"
                        className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l-lg border-solid border-neutral-300 bg-gray-700 bg-clip-padding px-3 py-[0.25rem] 
                                  font-normal leading-[1.6] text-neutral-200 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-200
                                  focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 
                                  dark:focus:border-primary"
                        placeholder="探索"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        onChange={handleSearchInput}
                    />

                    {/* <!--Search button--> */}
                    <TERipple color="light">
                        <button
                            className="relative z-[2] flex items-center rounded-r-lg bg-black opacity-80 px-3 py-2.5 text-xs ont-medium uppercase leading-tight text-white shadow-md transition
                        duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800
                        active:shadow-lg"
                            type="button"
                            id="button-addon1"
                            onClick={handleSearch}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="h-5 w-5"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </TERipple>
                </div>
            </form>
        </div>
    );
}
