import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import { useAuthContext } from "../auth/context";
import { CircularProgress } from "@mui/material";

export default function DashboardLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, loading } = useAuthContext();

    // useEffect(() => {
    //     if (!loading && !user && location.pathname !== "/dashboard") {
    //         navigate("/dashboard");
    //     }
    // }, [loading, user, location.pathname, navigate]);

    if (loading) {
        return (
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
                size={40}
                thickness={4}
                value={100}
            />
        );
    }

    return (
        <div className="Dashboard min-h-[calc(100vh-151px)]">
            <Sidebar />
            <div className="">
                <Outlet />
            </div>
        </div>
    );
}