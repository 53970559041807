import Modal from "@mui/material/Modal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link, useNavigate } from "react-router-dom";

import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
} from "tw-elements-react";

import { toast } from "react-toastify";

import AvatarUploader from "../components/Circleimage";
import Fourimagelink from "../components/Fourimagelink";
import Linkitem from "../components/Linkitem";
import Listcomponent from "../components/Listcomponent";
import Map from "../components/Map";
import Oneimagelink from "../components/Oneimagelink";
import PdfReader from "../components/Pdfreader";
import PublicViewSocial from "../components/PublicViewSocial";
import Selfprofile from "../components/Selfprofile";
import Spaceitem from "../components/Spaceitem";
import ImageSlider from "../components/Squareimage";
import TextEditor from "../components/Textedit";
import Threeimagelink from "../components/Threeimagelink";
import Twoimagelink from "../components/Twoimagelink";
import VideoLinkItem from "../components/VideoLinkItem.jsx";

import { Close } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash/debounce";
import { Apis } from "../api"; // Import ChromePicker from react-color
import ColorBgPicker from "../components/ColorBgPicker.js";
import PublishCompleteModal from "../components/PublishCompleteModal";
import SettingImageDialog from "../components/SettingImageDialog.jsx";
import { useAuthContext } from "../auth/context.jsx";
import { AppContext } from "../App.js";

const EditSocial = ({ profileButton }) => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [open, setOpen] = useState(false);
    const [arrangementOpen, setArrangementOpen] = useState(false);
    const [createType, setCreateType] = useState("");
    const [isClear, setIsClear] = useState(false);
    const [showPublihModal, setShowPublihModal] = useState(false);
    const [publishLink, setPublishLink] = useState("");
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    const [isShowConfirmDisableDownload, setIsShowConfirmDisableDownload] =
        useState(false);
    const [isConfirmDownload, setIsConfirmDownload] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#f3f4f6");
    const [linkColor, setLinkColor] = useState("");
    const [openColorDialog, setOpenColorDialog] = useState(false);

    const [showMore, setShowMore] = useState(false);
    const textareaRef = useRef(null);
    const changeRef = useRef(false);
    const { user, setShowLoginModal } = useAuthContext();

    const [publishData, setPublishData] = useState({
        bgColor: "",
        bgURL: "",
        wordColor: "",
        cardColor: "",
        cardURL: "",
        url_name: "",
        faceImg: "",
        realName: "",
        company_url: "",
        companyName: "",
        linkColor: "",
        position: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        mailAddress: "",
        address: "",
        idCard: {
            idCard: [],
        },
        profile: "",
        socialLink: {},
        snsTree_Data: {
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [],
            slideLink: [],
            spaceAdd: [],
        },
    });

    const [backgroundImage, setBackgroundImage] = useState(
        publishData.bgURL || ""
    );
    const [profile, setProfile] = useState("");

    const [isEditWidget, setIsEditWidget] = useState(false);
    const [editWidgetData, setEditWidgetData] = useState(null);

    useEffect(() => {
        if (!user) {
            setShowLoginModal(true);
            return;
        }
        getPublish();
    }, [user]);

    useEffect(() => {
        if (user) {
            setPublishLink(`${user.username}-2`);
        }
    }, [user]);

    useEffect(() => {
        adjustTextareaHeight();
    }, [profile]);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleTextarea = (e) => {
        setProfile(e.target.value);
        adjustTextareaHeight();
        setPublishData((pre) => ({ ...pre, profile: e.target.value }));
        changeRef.current = true;
    };

    //Cloudinary upload
    const FileUpload = async (file) => {
        const cloud_name = "dz6r3o4w0";
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "dfuqz9xv");
        formData.append("cloud_name", cloud_name);

        try {
            const res = await fetch(
                "https://api.cloudinary.com/v1_1/dz6r3o4w0/auto/upload",
                {
                    method: "POST",
                    body: formData,
                }
            );
            const data = await res.json();
            return data.secure_url;
        } catch (err) {
            toast.error(err.message);
            console.error(err);
        }
    };
    const moveItem = (fromIndex, toIndex) => {
        const updatedItems = [...profileData];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setProfileData(updatedItems);
        changeRef.current = true;
    };
    // icon0.addEventListener("click", () => {
    //   for (let i = 0; i < linkIcons.length; i++) {
    //     linkIcons[i].classList.toggle("hidden");
    //   }
    // });
    const handleProfileData = (data) => {
        if (isDirty) {
            if (
                ((data.type === "oneImage" || data.type === "imgLink1") &&
                    data.data) ||
                ((data.type === "twoImage" || data.type === "imgLink2") &&
                    ((data.data.length === 2 &&
                        data.data.every((i) => i?.imageLink)) ||
                        data.data)) ||
                ((data.type === "threeImage" || data.type === "imgLink3") &&
                    ((data.data.length === 3 &&
                        data.data.every((i) => i?.imageLink)) ||
                        data.data)) ||
                ((data.type === "fourImage" || data.type === "imgLink4") &&
                    ((data.data.length === 4 &&
                        data.data.every((i) => i?.imageLink)) ||
                        data.data)) ||
                (data.type === "videoLink" && data.data) ||
                ((data.type === "text" || data.type === "textLink") &&
                    data.data) ||
                ((data.type === "map" || data.type === "mapLink") &&
                    data.data) ||
                (["space", "spaceAdd"].includes(data.type) && data.data) ||
                (data.type === "selfProfile" && data.data) ||
                (["slideImage", "slideLink"].includes(data.type) &&
                    data.data) ||
                ((data.type === "dataLink" || data.type === "link") &&
                    data.data)
            ) {
                if (
                    ["slideLink", "slideImage"].includes(data.type) &&
                    !isChecked &&
                    !isConfirmDownload
                ) {
                    setIsShowConfirmDisableDownload(true);
                    return;
                }
                if (data.startTime && data.endTime) {
                    if (data.startTime > data.endTime) {
                        toast.error(
                            "開始時間は終了時間より前に設定してください"
                        );
                        return;
                    }
                }
                const temp = [...profileData];
                if (isEditWidget) temp[editWidgetData?.index] = data;
                const newData = isEditWidget ? temp : [...profileData, data];
                setProfileData(newData.sort((a, b) => a.order - b.order));
                changeRef.current = true;
                handleClose();
            } else {
                console.log("Just fill in all fields.");
            }
        } else {
            handleClose();
        }
    };

    const handleClose = () => {
        setIsEditWidget(false);
        setEditWidgetData(null);
        setOpen(false);
        handleClear();
        setIsChecked(false);
        setIsConfirmDownload(false);
    };

    const deleteProfileData = (id) => {
        const updatedData = profileData.filter(
            (element) => profileData.indexOf(element) !== id
        );
        setProfileData(updatedData);
        changeRef.current = true;
    };

    //Current Editing Data
    const handleCurrentData = (data) => {
        setIsDirty(true);
        setCurrentData((prev) => ({ ...prev, ...data }));
    };

    const handleCheck = (check) => {
        if (currentData.data) {
            const separatedata = { ...currentData };

            const updatedData = {
                data: { ...separatedata.data, isDownload: check },
                type: "slideImage",
            };
            setCurrentData(updatedData);
            setIsChecked(check);
        } else {
            console.log("First Upload please");
        }
    };

    //Current data clear
    const handleClear = () => {
        setIsClear(true);
        setCurrentData({});
        setArrangementOpen(false);
        setIsDirty(false);
        setOpen(false);
        setTimeout(() => {
            setIsClear(false);
        }, 1000);
    };

    const handleModal = (e) => {
        setCreateType(e.target.getAttribute("type"));
        setOpen(true);
    };

    //Update all publish data
    const updateAllForPublish = () => {
        let newOnlineCardData = {
            dataLink: [],
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [],
            slideLink: [],
            spaceAdd: [],
        };
        for (const item in profileData) {
            switch (profileData[item].type) {
                case "oneImage":
                    newOnlineCardData.imgLink1.push({
                        title: profileData[item].data.title,
                        text: profileData[item].data.text,
                        url: profileData[item].data?.imageLink,
                        link: profileData[item].data.url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "imgLink1":
                    newOnlineCardData.imgLink1.push({
                        title: profileData[item].data.title,
                        text: profileData[item].data.text,
                        url: profileData[item].data.url,
                        link: profileData[item].data.link,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "twoImage":
                    newOnlineCardData.imgLink2.push({
                        title1: profileData[item].data[0].title,
                        text1: profileData[item].data[0].text,
                        url1: profileData[item].data[0]?.imageLink,
                        link1: profileData[item].data[0].url,
                        title2: profileData[item].data[1].title,
                        text2: profileData[item].data[1].text,
                        url2: profileData[item].data[1]?.imageLink,
                        link2: profileData[item].data[1].url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "imgLink2":
                    newOnlineCardData.imgLink2.push({
                        title1: profileData[item].data.title1,
                        text1: profileData[item].data.text1,
                        url1: profileData[item].data.url1,
                        link1: profileData[item].data.link1,
                        title2: profileData[item].data.title2,
                        text2: profileData[item].data.text2,
                        url2: profileData[item].data.url2,
                        link2: profileData[item].data.link2,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "imgLink3":
                    newOnlineCardData.imgLink3.push({
                        title1: profileData[item].data.title1,
                        text1: profileData[item].data.text1,
                        url1: profileData[item].data.url1,
                        link1: profileData[item].data.link1,
                        title2: profileData[item].data.title2,
                        text2: profileData[item].data.text2,
                        url2: profileData[item].data.url2,
                        link2: profileData[item].data.link2,
                        title3: profileData[item].data.title3,
                        text3: profileData[item].data.text3,
                        url3: profileData[item].data.url3,
                        link3: profileData[item].data.link3,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "threeImage":
                    newOnlineCardData.imgLink3.push({
                        title1: profileData[item].data[0].title,
                        text1: profileData[item].data[0].text,
                        url1: profileData[item].data[0]?.imageLink,
                        link1: profileData[item].data[0].url,
                        title2: profileData[item].data[1].title,
                        text2: profileData[item].data[1].text,
                        url2: profileData[item].data[1]?.imageLink,
                        link2: profileData[item].data[1].url,
                        title3: profileData[item].data[2].title,
                        text3: profileData[item].data[2].text,
                        url3: profileData[item].data[2]?.imageLink,
                        link3: profileData[item].data[2].url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "fourImage":
                    newOnlineCardData.imgLink4.push({
                        title1: profileData[item].data[0].title,
                        text1: profileData[item].data[0].text,
                        url1: profileData[item].data[0]?.imageLink,
                        link1: profileData[item].data[0].url,
                        title2: profileData[item].data[1].title,
                        text2: profileData[item].data[1].text,
                        url2: profileData[item].data[1]?.imageLink,
                        link2: profileData[item].data[1].url,
                        title3: profileData[item].data[2].title,
                        text3: profileData[item].data[2].text,
                        url3: profileData[item].data[2]?.imageLink,
                        link3: profileData[item].data[2].url,
                        title4: profileData[item].data[3].title,
                        text4: profileData[item].data[3].text,
                        url4: profileData[item].data[3]?.imageLink,
                        link4: profileData[item].data[3].url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "imgLink4":
                    newOnlineCardData.imgLink4.push({
                        title1: profileData[item].data.title1,
                        text1: profileData[item].data.text1,
                        url1: profileData[item].data.url1,
                        link1: profileData[item].data.link1,
                        title2: profileData[item].data.title2,
                        text2: profileData[item].data.text2,
                        url2: profileData[item].data.url2,
                        link2: profileData[item].data.link2,
                        title3: profileData[item].data.title3,
                        text3: profileData[item].data.text3,
                        url3: profileData[item].data.url3,
                        link3: profileData[item].data.link3,
                        title4: profileData[item].data.title4,
                        text4: profileData[item].data.text4,
                        url4: profileData[item].data.url4,
                        link4: profileData[item].data.link4,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime ??
                            "",
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime ??
                            "",
                    });
                    break;
                case "videoLink":
                    newOnlineCardData.videoLink.push({
                        url:
                            profileData[item].data.url ||
                            profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "text":
                case "textLink":
                    newOnlineCardData.textLink.push({
                        text:
                            profileData[item].data.text ||
                            profileData[item].data,
                        bgColor:
                            profileData[item].data.bgColor || "transparent",
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "map":
                    newOnlineCardData.mapLink.push({
                        imgLink: "",
                        mapLink: profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "mapLink":
                    newOnlineCardData.mapLink.push({
                        imgLink: "",
                        mapLink: profileData[item].data.mapLink,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "selfProfile":
                    newOnlineCardData.selfProfile.push({
                        title: profileData[item].data.title,
                        content:
                            profileData[item].data.text ??
                            profileData[item].data.content,
                        color: profileData[item].data.color,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "slideImage":
                    newOnlineCardData.slideLink.push({
                        url: profileData[item].data.file,
                        title: profileData[item].data.title,
                        text: profileData[item].data.text,
                        order: profileData.indexOf(profileData[item]),
                        enableDownload: profileData[item].data?.enableDownload,

                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "slideLink":
                    newOnlineCardData.slideLink.push({
                        ...profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        enableDownload: profileData[item].data?.enableDownload,
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "space":
                case "spaceAdd":
                    newOnlineCardData.spaceAdd.push({
                        space:
                            profileData[item].data?.space ??
                            profileData[item].data ??
                            0,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                case "link":
                case "dataLink":
                    newOnlineCardData.dataLink.push({
                        ...profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ??
                            profileData[item].data.startTime,
                        endTime:
                            profileData[item].endTime ??
                            profileData[item].data.endTime,
                    });
                    break;
                default:
                    break;
            }
        }

        setPublishData((pre) => {
            return { ...pre, snsTree_Data: newOnlineCardData };
        });

        return { ...publishData, snsTree_Data: newOnlineCardData };
    };
    const handlePublish = async () => {
        const newPublishData = updateAllForPublish();
        let completepublishData = { ...newPublishData, url_name: publishLink };

        try {
            const res = await Apis.myPut(
                `social/private/snstree`,
                completepublishData
            );
            if (res && res.success) {
                setPublishData((pre) => ({ ...pre, url_name: publishLink }));
                setShowPublihModal(true);
                return;
            }
            throw new Error("編集に失敗しました");
        } catch (error) {
            toast.error(error.message);
            console.error(error);
        }
    };

    const handleAutoSave = async () => {
        const data = updateAllForPublish();
        const completeDraftData = { ...data, url_name: publishLink };
        try {
            await Apis.myPut(`social/private/snstree/draft`, completeDraftData);
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        if (publishData) {
            setBackgroundImage(publishData.bgURL);
            setProfile(publishData.profile);
        }
        const autoSave = debounce(async () => {
            await handleAutoSave();
            changeRef.current = false;
        }, 500);
        if (changeRef.current) {
            autoSave();
        }
        return () => {
            autoSave.cancel();
        };
    }, [publishData, profileData]);

    const getPublish = async () => {
        let config = { username: publishLink, url_name: publishLink };

        try {
            const response = await Apis.myGet(
                `social/private/snstree/draft`,
                config
            );
            if (response && response.data && response.data.length > 0) {
                setPublishData({
                    ...response.data[0],
                    accountName: response.data[0].accountName || user.full_name,
                });
                const resultArray = Object.entries(
                    response.data[0].snsTree_Data
                )
                    .flatMap(([key, value]) => {
                        return value
                            .filter((item) => Object.keys(item).length > 0)
                            .map((item) => ({
                                type: key,
                                data: item,
                            }));
                    })
                    .filter((item) => item.type !== undefined)
                    .sort((a, b) => a.data.order - b.data.order);

                setProfileData(resultArray);
                if (response.data[0].bgColor) {
                    setBackgroundColor(response.data[0].bgColor);
                }
                if (response.data[0].linkColor) {
                    setLinkColor(response.data[0].linkColor);
                }
            } else {
                setPublishData({
                    ...publishData,
                    accountName: user.full_name,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleColorChange = (color) => {
        setBackgroundColor(color.hex);
        setPublishData((pre) => ({ ...pre, bgColor: color.hex }));
        changeRef.current = true;
    };

    const handleChangeLinkColor = (color) => {
        setLinkColor(color.hex);
        setPublishData((pre) => ({ ...pre, linkColor: color.hex }));
        changeRef.current = true;
    };
    const handleImageUpload = (uploaded_url) => {
        setBackgroundImage(uploaded_url);
        setPublishData((pre) => ({ ...pre, bgURL: uploaded_url }));
        changeRef.current = true;
    };

    const deleteImageUpload = async () => {
        setBackgroundImage("");
        setPublishData((pre) => ({ ...pre, bgURL: "" }));
        changeRef.current = true;
    };

    const ProfileText = ({ text }) => {
        const [showMore, setShowMore] = useState(false);
        const maxLines = 4;
        const lineHeight = 20; // 行の高さを指定

        const toggleShowMore = () => {
            setShowMore(!showMore);
        };

        if (text.length <= maxLines * lineHeight) {
            return <p>{text}</p>;
        }

        return (
            <div>
                <p
                    style={{
                        maxHeight: showMore
                            ? "none"
                            : `${maxLines * lineHeight}px`,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {text}
                </p>
                <button onClick={toggleShowMore}>
                    {showMore ? "閉じる" : "続きを読む"}
                </button>
            </div>
        );
    };

    const handleEditWidget = (item, index) => () => {
        setOpen(true);
        setIsChecked(!!item?.data?.enableDownload);
        setCreateType(item.type);
        setIsEditWidget(true);
        setCurrentData(item);
        setEditWidgetData({ data: item.data, index });
    };
    const handleCloseModalDisableDownload = () => {
        setIsShowConfirmDisableDownload(false);
    };
    const handleConfirmDisableDownload = () => {
        setIsConfirmDownload(true);
        handleCloseModalDisableDownload();
    };
    useEffect(() => {
        if (isConfirmDownload) {
            handleProfileData(currentData);
        }
    }, [isConfirmDownload]);

    const { previousPage } = useContext(AppContext);

    return (
        <div className="editSocial">
            <div className="pc:w-[960px] w-full fixed top-0 flex h-24 items-center justify-around bg-gray-100 z-[16] shadow-sm">
                <Link to={previousPage ? -1 : "/d-dashboard"}>
                    <img
                        src="/image/turn-left.png"
                        alt="edit"
                        className="w-[40px] h-[40px] cursor-pointer"
                    ></img>
                </Link>
                <Link
                    onClick={handlePublish}
                    className="rounded-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 focus:bg-orange-700 text-white text-base md:text-lg p-2"
                >
                    公開する
                </Link>
                <Link
                    onClick={() => {
                        updateAllForPublish();
                        setShowPreviewModal(true);
                    }}
                    className="rounded-lg bg-purple-500 hover:bg-purple-600 active:bg-purple-700 focus:bg-purple-800 text-white text-base md:text-lg p-2"
                >
                    プレビュー
                </Link>
                <Link
                    to="/d-online"
                    className="rounded-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 focus:bg-orange-700 text-white text-base md:text-lg p-2"
                >
                    ビジネス向け
                </Link>
            </div>
            <div
                className="relative mt-24 pb-4"
                style={{
                    backgroundColor: backgroundColor,
                    // backgroundImage:
                    //     backgroundImage !== "" ? `url(${backgroundImage})` : "",
                    // backgroundSize: "contain",
                    // backgroundPosition: "top center",
                    // backgroundRepeat: "no-repeat",
                }}
            >
                {backgroundImage && (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            backgroundColor: "white",
                            display: "flex",
                            top: 0,
                            right: 0,
                            left: 0,
                            // zIndex: 0,
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                                backgroundColor: "#f0f0f0",
                            }}
                        >
                            <img
                                src={`${backgroundImage ?? ""}`}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "contain",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    opacity: 0.6,
                                }}
                                alt="..."
                            />
                        </div>
                    </div>
                )}
                <div className="relative min-w-[320px] w-[50%] mx-auto z-10 bg-transparent">
                    <ColorBgPicker
                        isOpen={openColorDialog}
                        onClose={() => setOpenColorDialog(false)}
                        onChangeColor={handleColorChange}
                        color={backgroundColor}
                        enableBgImage
                        backgroundImage={backgroundImage}
                        onChangeImageUrl={handleImageUpload}
                        onDeleteImageUpload={deleteImageUpload}
                    >
                        <div className="p-[10px] flex justify-end">
                            <div
                                onClick={() => {
                                    setOpenColorDialog(true);
                                }}
                                className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white mr-3 px-5 py-1 rounded-lg"
                            >
                                背景
                            </div>
                            {/* <Link to ="/setting"><img className="w-[27px] h-[27px] cursor-pointer" alt="edit" src="/image/setting1.png"></img></Link> */}
                        </div>
                    </ColorBgPicker>
                    <div className="border border-white">
                        <ImageSlider
                            onChangeData={(data) => {
                                setPublishData((pre) => ({
                                    ...pre,
                                    idCard: { idCard: data },
                                }));
                                changeRef.current = true;
                            }}
                            initialData={publishData.idCard.idCard}
                            backgroundColor={"bg-transparent"}
                        />
                    </div>

                    <div className="relative flex flex-col justify-center mb-[40px] mt-3 items-center">
                        <div className="absolute max-[800px]:-top-12 -top-16 z-[11] w-24 h-24">
                            <AvatarUploader
                                onChangeData={(data) => {
                                    setPublishData((pre) => ({
                                        ...pre,
                                        faceImg: data ?? "",
                                    }));
                                    changeRef.current = true;
                                }}
                                initialData={publishData.faceImg}
                                style={{
                                    height: "96px",
                                    width: "96px",
                                }}
                            />
                        </div>
                        <input
                            type="text"
                            value={publishData.accountName}
                            onChange={(e) => {
                                setPublishData((pre) => ({
                                    ...pre,
                                    accountName: e.target.value,
                                }));
                                changeRef.current = true;
                            }}
                            placeholder="アカウント名"
                            className="border rounded-lg p-3 mb-4 max-[800px]:mt-18 mt-20 text-lg w-[70%]"
                        />
                        <textarea
                            ref={textareaRef}
                            value={profile}
                            onChange={handleTextarea}
                            style={{
                                fontSize: "80%", // 文字サイズを半分に縮小
                                overflow: "hidden", // スクロールバーを非表示
                                resize: "none", // 手動リサイズを無効化
                                width: "70%", // 幅を100%に設定
                                minHeight: "6rem",
                                boxSizing: "border-box", // パディングとボーダーを含む
                                borderRadius: "6px",
                                padding: "12px",
                            }}
                            placeholder="プロフィール"
                        />
                        {publishData.profile.length > 100 && (
                            <button onClick={toggleShowMore} className="mt-2">
                                {showMore ? "閉じる" : "続きを読む"}
                            </button>
                        )}
                    </div>
                    {/* <DraggableForm /> */}
                    {/* <DndProvider backend={HTML5Backend}> */}
                    {profileData &&
                        Array.isArray(profileData) &&
                        profileData.map((item, index) => (
                            <Listcomponent
                                key={index}
                                id={index}
                                index={index}
                                profileListData={item}
                                moveItem={moveItem}
                                isDelete={deleteProfileData}
                                onClickEdit={handleEditWidget(item, index)}
                                linkColor={linkColor}
                                length={profileData.length}
                            />
                        ))}
                    {/* </DndProvider> */}
                    <div className="mt-[40px]">
                        {profileButton.map((item, key) => (
                            <div
                                key={key}
                                type={item.type}
                                className="p-3 px-5 border rounded-md my-3 cursor-pointer border-black text-black/30 hover:text-black bg-white"
                                onClick={handleModal}
                            >
                                {item.placeholder}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                style={{ backgroundColor: backgroundColor ?? "#F3F4F6 " }}
                className="h-16 md:h-24 flex justify-center items-center  w-full"
            >
                <Link to={`/d-dashboard`}>
                    <div className="">
                        <img
                            className="mx-auto w-[100px] h-auto"
                            alt="logo"
                            src="/image/logo.png"
                        />
                    </div>
                </Link>
            </div>
            <Modal
                open={open}
                onClose={() => {
                    handleProfileData(currentData);
                }}
                keepMounted
                className="w-full flex justify-center items-center"
            >
                <div className="relative w-[40%] tablet:w-[50%] mobile:w-[320px] rounded-lg p-[20px] bg-white items-center max-h-screen overflow-auto">
                    {isLoading && (
                        <div className="absolute inset-0 flex justify-center items-center z-[10] bg-zinc-500/30">
                            <CircularProgress />
                        </div>
                    )}
                    <div className="h-[calc(100%-40px)]">
                        {["fourImage", "imgLink4"].includes(createType) && (
                            <Fourimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {["threeImage", "imgLink3"].includes(createType) && (
                            <Threeimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {["twoImage", "imgLink2"].includes(createType) && (
                            <Twoimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {["oneImage", "imgLink1"].includes(createType) && (
                            <Oneimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {createType === "videoLink" && (
                            <VideoLinkItem
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {["textLink", "text"].includes(createType) && (
                            <TextEditor
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                enableChangeBgColor
                            />
                        )}
                        {["map", "mapLink"].includes(createType) && (
                            <Map
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {["dataLink", "link"].includes(createType) && (
                            <div className="relative">
                                <Linkitem
                                    FileUpload={FileUpload}
                                    isClear={isClear}
                                    onChangeData={handleCurrentData}
                                    data={editWidgetData?.data}
                                    onChangeColor={handleChangeLinkColor}
                                    linkColor={linkColor}
                                />
                            </div>
                        )}
                        {["spaceAdd", "space"].includes(createType) && (
                            <Spaceitem
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {createType === "selfProfile" && (
                            <Selfprofile
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {["slideImage", "slideLink"].includes(createType) && (
                            <div className="w-full">
                                <PdfReader
                                    FileUpload={FileUpload}
                                    isClear={isClear}
                                    onChangeData={handleCurrentData}
                                    data={editWidgetData?.data}
                                />
                                <label htmlFor="download">
                                    <input
                                        type="checkbox"
                                        id="download"
                                        name="download"
                                        checked={isChecked}
                                        disabled={!currentData?.data}
                                        onChange={(e) => {
                                            handleCheck(!isChecked);
                                            const newData = Object.assign(
                                                currentData.data,
                                                {
                                                    enableDownload:
                                                        e.target.checked,
                                                }
                                            );
                                            handleCurrentData({
                                                data: newData,
                                                type: currentData.type,
                                            });
                                        }}
                                    />
                                    ダウンロードを許可する
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end gap-x-4 mt-2">
                        <img
                            alt="trash"
                            src="/image/trash.svg"
                            width="30"
                            className="cursor-pointer hover:opacity-70"
                            onClick={handleClear}
                        />
                        <div
                            className="rounded-md py-2 px-4 bg-sky-400 text-white cursor-pointer hover:bg-sky-500"
                            onClick={() => {
                                handleProfileData(currentData);
                            }}
                        >
                            保存
                        </div>
                        <div
                            className="rounded-md py-2 px-4 bg-red-400 text-white cursor-pointer hover:bg-red-500"
                            onClick={() => {
                                setArrangementOpen(true);
                            }}
                        >
                            予約
                        </div>
                    </div>
                    <SettingImageDialog
                        open={arrangementOpen}
                        onclose={() => setArrangementOpen(false)}
                        onPublish={(data) => {
                            setIsDirty(true);
                            editWidgetData?.data
                                ? setCurrentData((prev) => ({
                                      ...prev,
                                      endTime: data.endTime
                                          ? new Date(data.endTime).getTime()
                                          : "",
                                  }))
                                : setCurrentData((prev) => ({
                                      ...prev,
                                      endTime: data.endTime
                                          ? new Date(data.endTime).getTime()
                                          : "",
                                  }));
                        }}
                        onReservation={(data) => {
                            setIsDirty(true);
                            editWidgetData?.data
                                ? setCurrentData((prev) => ({
                                      ...prev,
                                      startTime: data.startTime
                                          ? new Date(data.startTime).getTime()
                                          : "",
                                      endTime: data.endTime
                                          ? new Date(data.endTime).getTime()
                                          : "",
                                  }))
                                : setCurrentData((prev) => ({
                                      ...prev,
                                      startTime: data.startTime
                                          ? new Date(data.startTime).getTime()
                                          : "",
                                      endTime: data.endTime
                                          ? new Date(data.endTime).getTime()
                                          : "",
                                  }));
                        }}
                        isClear={isClear}
                        data={currentData}
                    />
                </div>
            </Modal>
            <ModalConfirmDownload
                open={isShowConfirmDisableDownload}
                onOk={handleConfirmDisableDownload}
                onClose={handleCloseModalDisableDownload}
            />
            <PublishCompleteModal
                type={"SNS"}
                showPublishModal={showPublihModal}
                setShowPublishModal={setShowPublihModal}
                link={publishLink}
            />
            <TEModal show={showPreviewModal} setShow={setShowPreviewModal}>
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "700px",
                        height: "90%",
                        maxHeight: "1000px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader
                            style={{
                                backgroundColor: "#38bdf8",
                                height: "50px",
                            }}
                        >
                            <h1 className="text-2xl p-3 text-white">
                                プレビュー
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowPreviewModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                paddingRight: 0,
                                paddingLeft: 0,
                            }}
                        >
                            {showPreviewModal && (
                                <div style={{ width: "100%" }}>
                                    <PublicViewSocial
                                        notShowBack
                                        publishData={publishData}
                                    />
                                </div>
                            )}
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    );
};

export default EditSocial;

export const ModalConfirmDownload = ({ open, onClose, onOk }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    padding: "24px",
                    borderRadius: "8px",
                }}
            >
                <h1 className="p-6 text-xl font-semibold">
                    ダウンロードを許可していませんがよろしいですか？
                </h1>
                <div className="w-full p-4 text-lg flex justify-between">
                    <Button variant="contained" color="inherit" onClick={onOk}>
                        はい
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: "#FB923C",
                        }}
                        onClick={onClose}
                    >
                        いいえ
                    </Button>
                </div>
                <Close
                    sx={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        color: "black",
                        cursor: "pointer",
                    }}
                    onClick={onClose}
                />
            </Box>
        </Modal>
    );
};
