import React, { useState, useEffect, useRef } from "react";
import ImageSlider from "../components/Squareimage";

export default function Imagelinkitem({
    FileUpload,
    id,
    isClear,
    onChangeState,
    type,
    data,
    setIsLoading,
}) {
    const no_img = "/image/imagelink.png";
    const [image, setImage] = useState(no_img);
    const [images, setImages] = useState([]);
    const ref = useRef(null);
    const [state, setState] = useState({
        imageLink: "",
        title: "",
        text: "",
        url: "",
        id: 0,
    });

    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [state.text]);

    const handleImageUpload = async (event) => {
        event.preventDefault();
        const uploadedImages = Array.from(event.target.files);
        const lastImage = uploadedImages[uploadedImages.length - 1];
        if (lastImage) {
            try {
                setIsLoading(true);
                const lastImageUrl = URL.createObjectURL(lastImage);
                // setImage(lastImageUrl);
                const uploaded_url = await FileUpload(lastImage);
                handleChange({
                    ...state,
                    imageLink: uploaded_url,
                    id: id,
                });
                event.target.value = null;
                return () => URL.revokeObjectURL(lastImageUrl);
            } catch (error) {
                console.error("Error creating object URL:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.error("No file selected");
        }
    };

    useEffect(() => {
        if (data) {
            setState({
                imageLink: data?.imageLink ?? data.url ?? "",
                title: data.title ?? "",
                text: data.text ?? "",
                url: data.link ?? data.url ?? "",
                id: data.id ?? 0,
            });

            if (type === "one-link") {
                setImages(data?.imageLink ?? data.url);
            }
        }
    }, [data]);

    useEffect(() => {
        if (isClear) {
            setImage(no_img);
            setImages([]);
            setState({
                imageLink: "",
                title: "",
                text: "",
                url: "",
                id: 0,
            });
        }
    }, [isClear]);

    const handleChange = (value) => {
        setState(value);
        onChangeState(value);
    };

    return (
        <div>
            {type === "one-link" ? (
                <ImageSlider
                    key="one-link"
                    onChangeData={(data) => {
                        handleChange({
                            ...state,
                            imageLink: data,
                            id: id,
                        });
                    }}
                    initialData={images}
                    name="oneLink"
                />
            ) : (
                <div
                    className="relative w-full aspect-[16/9] border-black border-2 mx-auto"
                    style={{
                        backgroundImage: `url(${
                            image !== no_img
                                ? image
                                : state?.imageLink
                                ? state?.imageLink
                                : no_img
                        })`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        name={`image-upload-${id ?? 0}`}
                        onChange={handleImageUpload}
                    />
                </div>
            )}
            <input
                className="w-full text-sm border border-black rounded-md p-2 px-3 my-1 mt-2"
                name={`link-1-${id ?? 0}`}
                placeholder="タイトル"
                value={state.title}
                onChange={(e) => {
                    handleChange({
                        ...state,
                        title: e.target.value,
                        id: id,
                    });
                }}
            />
            <textarea
                ref={textareaRef}
                className="w-full h-fit max-h-[400px] text-sm border border-black rounded-md p-2 px-3 my-1 resize-none"
                name={`link-2-${id ?? 0}`}
                placeholder="テキスト"
                value={state.text}
                onChange={(e) => {
                    handleChange({
                        ...state,
                        text: e.target.value,
                        id: id,
                    });
                }}
            />
            <input
                className="w-full text-sm border border-black rounded-md p-2 px-3 my-1 mb-2"
                name={`link-3-${id ?? 0}`}
                placeholder="URL"
                value={state.url}
                onChange={(e) => {
                    handleChange({
                        ...state,
                        url: e.target.value,
                        id: id,
                    });
                }}
            />
        </div>
    );
}
