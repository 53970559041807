import React, { useEffect, useState } from "react";
import InputLinkItem from "./Inputlinkitem";
import ColorBgPicker from "./ColorBgPicker.js";
import { linkTypes } from "../const/common.js";

export default function Linkitem({
    FileUpload,
    isClear,
    onChangeData,
    data,
    onChangeColor,
    linkColor,
}) {
    const [urlLink, setUrlLink] = useState("");
    const [openColorDialog, setOpenColorDialog] = useState(false);
    const [state, setState] = useState({
        urlLink: "",
        title: "",
        text: "",
        iconLink: "",
        id: 0,
    });

    useEffect(() => {
        if (data) {
            setState({
                urlLink: data.urlLink,
                title: data.title,
                text: data.text,
                iconLink: data.iconLink,
                id: data.id,
            });
            setUrlLink(data.urlLink);
        }
    }, [data]);

    useEffect(() => {
        if (isClear) {
            setState({
                urlLink: "",
                title: "",
                text: "",
                iconLink: "",
                id: 0,
            });
            setUrlLink("");
        }
    }, [isClear]);

    const onChange = (newData) => {
        onChangeData({
            data: {
                ...state,
                urlLink: newData.urlLink,
                title: newData.title,
                iconLink: newData.iconLink,
            },
            type: "link",
        });
        setState((pre) => ({
            ...pre,
            urlLink: newData.urlLink,
            title: newData.title,
            iconLink: newData.iconLink,
        }));
        setUrlLink(newData.urlLink);
    };
    const handleText = (e) => {
        onChangeData({
            data: { ...state, text: e.target.value },
            type: "link",
        });
        setState((pre) => ({ ...pre, text: e.target.value }));
    };
    const handleTitle = (e) => {
        onChangeData({
            data: { ...state, title: e.target.value },
            type: "link",
        });
        setState((pre) => ({ ...pre, title: e.target.value }));
    };

    return (
        <>
            <div className="w-full ">
                <ColorBgPicker
                    isOpen={openColorDialog}
                    onClose={() => setOpenColorDialog(false)}
                    onChangeColor={onChangeColor}
                    color={linkColor}
                >
                    <div className="p-[10px] flex justify-end">
                        <div
                            onClick={() => {
                                setOpenColorDialog(true);
                            }}
                            className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white mr-3 px-5 py-1 rounded-lg"
                        >
                            背景
                        </div>
                    </div>
                </ColorBgPicker>
            </div>
            <div
                className="grid grid-cols-2 md:px-[20px] pb-3 justify-between items-center"
                style={{ backgroundColor: linkColor ?? "white" }}
            >
                <div className="p-3">
                    <InputLinkItem
                        FileUpload={FileUpload}
                        isClear={isClear}
                        onChangeData={onChange}
                        initialData={data}
                    />
                    <input
                        className="w-full border text-sm border-black rounded-md p-2 px-3 my-1 mb-1 cursor-not-allowed"
                        value={urlLink}
                        disabled
                        name="link-3"
                        placeholder="URL"
                    />
                </div>
                <div className="p-3">
                    {/* <div className="w-full h-10 border text-sm border-black rounded-md p-2 px-3 my-1 mt-2 bg-white/30">
                        {state.title || "タイトル"}
                    </div> */}
                    <input
                        className="w-full border text-sm border-black rounded-md p-2 px-3 my-1 mt-2 bg-white/30"
                        name="title"
                        placeholder="タイトル"
                        value={state.title}
                        onChange={handleTitle}
                    />
                    <input
                        className="w-full border text-sm border-black rounded-md p-2 px-3 my-1 bg-white/30"
                        name="link-2"
                        placeholder={
                            state.title &&
                            (state.title === "Mail" ||
                                !linkTypes.includes(state.title))
                                ? "テキスト"
                                : "アカウント名"
                        }
                        value={state.text}
                        onChange={handleText}
                    />
                </div>
            </div>
        </>
    );
}
