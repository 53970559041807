import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { myConfig } from "../api";

export default function Profile() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const id = params.get("uid");
    const type = params.get("type");
    useEffect(() => {
        if (!id) {
            navigate("/d-dashboard");
        }
        const getProfile = async () => {
            try {
                const response = await fetch(
                    `${myConfig.apiUrl}/account/profile/user?userId=${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.ok) {
                    const { data } = await response.json();
                    if (data.username) {
                        navigate(
                            `/${data.username}${
                                type === "business" ? "" : "-2"
                            }`
                        );
                        return;
                    }
                }
                throw new Error("User not found");
            } catch (error) {
                console.error(error);
                navigate("/d-dashboard");
            }
        };
        getProfile();
    }, [id, type]);

    return <></>;
}
