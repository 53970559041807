import React, { useState } from "react";
import { Document, Page } from "react-pdf";

export default function PdfLoader({ file, pdfWidth }) {
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    return (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_el, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pdfWidth}
                />
            ))}
        </Document>
    );
}
