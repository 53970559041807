import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export default function PageFooter() {
    const location = useLocation();

    const isShow =
        !location.pathname?.startsWith("/admin") &&
        location.pathname.startsWith("/d-");

    return (
        isShow && (
            <div className="h-24 flex w-full justify-center items-center bg-gray-100">
                <Link to={`/d-dashboard`}>
                    <img
                        className="mx-auto w-[100px] h-auto"
                        alt="logo"
                        src="/image/logo.png"
                    />
                </Link>
            </div>
        )
    );
}
