import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { Apis, urlAPI } from "../../api";
import { useAuthContext } from "../../auth/context.jsx";
import CustomEditor from "../../components/Editor/CustomEditor.jsx";
import MailTable from "../../components/MailTable";
import { MAIL_DEFAULT } from "../../const/MailContent.js";
import { formatHtmlAspectRatio } from "../../utilities/helper.js";

export default function Emailmarketing({ emails, onSend }) {
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [header, setHeader] = useState(MAIL_DEFAULT.header);
    const [footer, setFooter] = useState(MAIL_DEFAULT.footer);
    const [mailHistory, setMailHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [template, setTemplate] = useState([]);
    const { setLoading } = useAuthContext();

    const getEmailTemplates = async () => {
        const res = await Apis.myGet(
            `settingEmail/settingEmail?mailType=other`
        );
        if (res && res.success) {
            setEmailTemplates(res.data.items);
        }
    };

    const handleGetMailHistory = async (targetpage) => {
        try {
            const res = await Apis.myGet(
                `${urlAPI.mailAdminHistory}?page=${targetpage}&per_page=10`
            );
            if (res.success) {
                setMailHistory(res.data.data);
                setTotal(res.data.total);
                return;
            }
            setMailHistory([]);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleSendEmail = async () => {
        if (!subject) {
            toast.error("件名を入力してください");
            return;
        }
        if (emails) {
            try {
                setLoading(true);
                const res = await Apis.myPost(urlAPI.sendMailUser, {
                    emails,
                    subject,
                    content: formatHtmlAspectRatio(content),
                    header: formatHtmlAspectRatio(header),
                    footer: formatHtmlAspectRatio(footer),
                });
                if (res.data.success) {
                    toast.success("メールが送信されました");
                    return;
                }
                throw new Error("メールの送信に失敗しました");
            } catch (error) {
                console.error(error);
                toast.error("メールの送信に失敗しました");
            } finally {
                setLoading(false);
                onSend();
            }
        }
    };

    const handleSelectTemplate = (template) => {
        setSubject(template.title ?? "");
        setHeader(template.header ?? "");
        setContent(template.content ?? "");
        setFooter(template.footer ?? "");
    };

    useEffect(() => {
        handleGetMailHistory(page);
    }, [page]);

    useEffect(() => {
        getEmailTemplates();
    }, []);

    return (
        <div className="relative">
            <Link to="/d-dashboard" className="inline-block">
                <img
                    alt="logo"
                    src="/image/logo.png"
                    width="200"
                    className="ml-9 pt-4"
                />
            </Link>
            <div className="p-10">
                <h1 className="pb-4 text-lg">メール作成</h1>
                <div className="py-2">
                    <label htmlFor="product-code" className="text-md">
                        メールテンプレート
                    </label>
                    <div>
                        <Select
                            labelId="demo-select-small-label"
                            className="!bg-white px-2 py-1 w-full no-padding"
                            value={template}
                            input={<OutlinedInput />}
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) => {
                                if (!e.target.value) return;
                                const template = emailTemplates.find(
                                    (item) => item.id === e.target.value
                                );
                                if (!template) return;
                                setSubject(template.subject);
                                setHeader(template.header);
                                setContent(template.content);
                                setFooter(template.footer);
                                setTemplate(e.target.value);
                            }}
                        >
                            {emailTemplates.map((row) => (
                                <MenuItem value={row.id}>{row.title}</MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="flex w-full items-center pt-2">
                    <label className="w-10">件名</label>
                    <input
                        type="text"
                        value={subject}
                        className="w-full p-1 rounded border-2"
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </div>
                <h2 className="mt-5 mb-3 text-md">ヘッダー</h2>
                <CustomEditor value={header} onChange={(e) => setHeader(e)} />
                <h2 className="mt-5 mb-3 text-md">本文</h2>
                <CustomEditor value={content} onChange={(e) => setContent(e)} />
                <h2 className="mt-5 mb-3 text-md">フッター</h2>
                <CustomEditor value={footer} onChange={(e) => setFooter(e)} />
                <h1 className="text-lg py-4">送信履歴</h1>
                <MailTable
                    data={mailHistory}
                    page={page}
                    per_page={10}
                    total={total}
                    onChangePage={setPage}
                    onSelectTemplate={handleSelectTemplate}
                />
            </div>
            <div className="w-full p-2 px-10 flex justify-end">
                <button
                    className=" bg-sky-400 text-white hover:bg-sky-500 active:bg-sky-600 cursor-pointer inline-block rounded-lg p-1 px-3"
                    onClick={handleSendEmail}
                >
                    送信する
                </button>
            </div>
        </div>
    );
}
