import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Apis } from "../api";
import { toast } from "react-toastify";

export default function MemoComponent({
    setShowMemoPane,
    showMemoPane,
    data,
    lastUpdate,
    id,
    onReloadData,
}) {
    const [content, setContent] = useState(data);
    const [lastUpdated, setLastUpdated] = useState(lastUpdate);
    const [error, setError] = useState(null);
    const dropdownRef = useRef(null);

    const handleButtonClick = () => {
        setShowMemoPane(!showMemoPane);
        setContent(data);
        setLastUpdated(lastUpdate);
        // Add logic to set the content and last updated time
    };

    const handleUpdateClick = async () => {
        if (!content) {
            setError("メモを入力してください");
            return;
        }
        try {
            const res = await Apis.myPut(`social/private/memo`, {
                memo: content,
                contact_data_id: id,
            });
            if (res.success) {
                setShowMemoPane(false);
                setError(null);
                onReloadData({
                    memo: content,
                    last_updated: dayjs().format("YYYY-MM-DD HH:mm"),
                    id: id,
                });
                return;
            }
            throw new Error("Update memo failed");
        } catch (error) {
            console.error(error.message);
            toast.error("メモの更新に失敗しました");
        }
    };

    const handleCancelClick = () => {
        setShowMemoPane(false);
        // Add logic to reset the content and last updated time
        setContent("");
        setLastUpdated("");
    };

    const handleDelete = async () => {
        try {
            const res = await Apis.myDelete(`social/private/memo`, {
                contact_data_id: id,
            });
            if (res.success) {
                setShowMemoPane(false);
                onReloadData({
                    memo: "",
                    last_updated: "",
                    id: id,
                });
                return;
            }
            throw new Error("Delete memo failed");
        } catch (error) {
            console.error(error.message);
            toast.error("メモの削除に失敗しました");
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowMemoPane(false); // Hide the dropdown
                setError(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            <button onClick={handleButtonClick}>
                <img
                    alt="edit memo"
                    src="/image/edit-memo.png"
                    width="20"
                    height="20"
                />
            </button>
            {showMemoPane && (
                <div className="absolute transform -translate-y-[100%] bg-slate-200 top-0 right-0 p-2 rounded-md">
                    <div className="relative">
                        {lastUpdated && content && (
                            <div className="flex justify-between items-center mb-2">
                                <span>メモ</span>{" "}
                                <span>
                                    {dayjs(lastUpdated).format(
                                        "YYYY-MM-DD HH:mm"
                                    )}
                                </span>
                            </div>
                        )}
                        <textarea
                            className="w-full block rounded-md p-2"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                        {error && <p className="text-red-400">{error}</p>}
                        <button
                            className="mx-[20px] mt-2 p-1 px-4 hover:opacity-70 rounded-full border-blue-500 border-2 text-blue-500"
                            onClick={handleUpdateClick}
                        >
                            更新
                        </button>
                        <button
                            className="mx-[20px] mt-2 p-1 px-4 hover:opacity-70 rounded-full border-red-500 border-2 text-red-500"
                            onClick={handleCancelClick}
                        >
                            キャンセル
                        </button>
                        <button
                            className="mx-[20px] hover:opacity-70 "
                            onClick={handleDelete}
                        >
                            <img alt="delete" src="/image/codicon_trash.png" />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
