import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Apis, urlAPI } from "../../../api";
import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useAuthContext } from "../../../auth/context";
import Pagination from "../../../components/Pagination";
import { concatenateParams } from "../../../utilities/helper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledDateTime = styled(TableRow)(() => ({
    ".rdtPicker": {
        left: -75,
    },
}));

export default function ShippingList() {
    const { getNoticeShipping, setLoading } = useAuthContext();
    const [shippings, setShippings] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [shippingDate, setShippingDate] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [total, setTotal] = useState(0);
    const today = new Date();

    const isValidDate = (currentDate) => {
        return currentDate.isSameOrAfter(today, "day");
    };

    const loadOrders = async () => {
        try {
            setLoading(true);

            const res = await getNoticeShipping(page, perPage);

            if (res) {
                setShippings(res.items);
                setPage(res.page);
                setPerPage(res.per_page);
                setTotal(res.total);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadOrders().then();
    }, [page, perPage]);

    //ボタン押下された行を保持
    //入力日付を初期化
    const handleEdit = (id) => {
        setEditingRowId(id);
        setShippingDate("");
    };

    // 注文を発送済みにする
    const handleShippingClick = async (id) => {
        if (shippingDate === "") {
            toast.error(`失敗しました! 日付を入力してください!`);
            return;
        }

        const shippingDateFormat = moment(shippingDate).format("YYYY-MM-DD");

        try {
            setLoading(true);

            const query = concatenateParams({
                url: urlAPI.orderView,
                query: {
                    ShippingStatus: 2,
                    ShippingDate: shippingDateFormat,
                    orderId: id,
                },
            });

            const res = await Apis.myPut(query);
            if (res && res.success) {
                toast.success(`配送が正常に更新されました!`);
                loadOrders();
                return;
            }
            if (res && res.data.error) {
                toast.error(res.data.error);
            }
        } catch (error) {
            toast.error(`失敗しました! もう一度お試しください!`);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeDateTime = (date) => {
        setShippingDate(date ? moment(date).format("YYYY-MM-DD") : "");
    };

    return (
        <div className="px-10 mb-3">
            <div className="flex justify-start items-center mb-2 gap-4">
                <button className="bg-indigo-700 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center">
                    発送待ち
                </button>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                注文日
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文番号
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                会員ID
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                アカウント名
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                氏名
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                className="max-w-[120px]"
                            >
                                郵便番号
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                住所
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                電話番号
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文品
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文数
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文金額
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                決済手数料
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                合計
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                ご要望
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shippings.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={15} align="center">
                                    該当する注文は 0 件 です
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        {/* 同じ注文の集計 */}
                        {shippings.map((p, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {moment(p.order_id.date).format(
                                        "YYYY/MM/DD"
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.customer_id.id}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="max-w-[200px] min-w-[150px]"
                                >
                                    <p className="break-all line-clamp-1">
                                        {p.order_id.customer_id.username}
                                    </p>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="max-w-[200px] min-w-[150px]"
                                >
                                    <p className="break-all line-clamp-1">
                                        {p.order_id.recipient_name}
                                    </p>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.post_code}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.address}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.phone_number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.product_id.title}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.total_price}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.closing_fee}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {(p.order_id.total_price || 0) +
                                        (p.order_id.closing_fee || 0)}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="max-w-[120px] break-words"
                                >
                                    {p.order_id.note}
                                </StyledTableCell>
                                {/* ボタン押下された行のボタンを日付入力に変更 */}
                                <StyledTableCell align="center" width={125}>
                                    {editingRowId === p.order_id.id ? (
                                        <div>
                                            <StyledDateTime>
                                                <Datetime
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    inputProps={{
                                                        className: "py-1 px-2",
                                                        placeholder:
                                                            "YYYY-MM-DD",
                                                    }}
                                                    value={shippingDate}
                                                    onChange={
                                                        handleChangeDateTime
                                                    }
                                                    isValidDate={isValidDate}
                                                />
                                            </StyledDateTime>
                                            <br />
                                            <button
                                                className="bg-red-600 opacity-80 hover:opacity-90 active:opacity-50 text-white py-1 max-md:px-1 md:px-5 rounded-full text-center"
                                                onClick={() =>
                                                    handleShippingClick(
                                                        p.order_id.id
                                                    )
                                                }
                                            >
                                                発送
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className="bg-blue-600 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center"
                                            onClick={() =>
                                                handleEdit(p.order_id.id)
                                            }
                                        >
                                            発送
                                        </button>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                page={page}
                perPage={perPage}
                total={total}
                onChangePage={(p) => setPage(p)}
                onChangePerPage={(l) => setPerPage(l)}
            />
        </div>
    );
}
