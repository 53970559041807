import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import MemoComponent from "./Memocomponent";
import { Apis } from "../api";
import { Box, Modal } from "@mui/material";
import PersonDescription from "./PersonDescription";

export default function Contactitem({
    url,
    person,
    username,
    email,
    is_new,
    id,
    onReRender,
    type,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(4);
    const [requestState, setRequestState] = useState(0);
    const dropdownRef = useRef(null);
    const modalTitle = [
        "この人を名刺管理に戻しますか？",
        "非通知にしますがよろしいですか?",
        "ブロックにしますがよろしいですか?",
        "削除にしますがよろしいですか?",
    ];
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const location = useLocation();
    // For example, '/block' is the path where we don't want to show the "blockList"
    const shouldHideBlockItem =
        location.pathname === "/d-layout/list/blocklist";
    const shouldHideNocontactItem =
        location.pathname === "/d-layout/list/nocontactlist";
    const shouldHideDeletedItem =
        location.pathname === "/d-layout/list/deletedlist";
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false); // Hide the dropdown
            }
        };
        if (isModalOpen >= 4) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalOpen]);

    // post contact update request
    const requestParam = ["None", "Unannounce", "Block", "Deleted"];

    const handleModalOpen = async (num) => {
        setIsOpen(true);
        setIsModalOpen(num);
        if (num < 4) setRequestState(num);
    };
    const handleRequest = async (num) => {
        if (requestState < 4) {
            try {
                const response = await Apis.myPut(
                    `social/private/contactdata`,
                    {
                        member: username,
                        block_setting: requestParam[requestState],
                    }
                );
                onReRender(true);
            } catch (err) {
                toast.error(err.message);
                console.error(err);
            }
            setIsModalOpen(num);
        } else setIsModalOpen(num);
        setIsOpen(false);
    };

    return (
        <>
            <li className="flex py-4 first:pt-0 last:pb-0 relative items-center">
                <img
                    className="h-10 w-10 rounded-full bg-white"
                    src={url}
                    alt={`${username}-avatar`}
                />
                {/* {
              is_new && <img className='absolute left-[32px]' src='/image/new-badge.svg' alt='newly viewed' />
          } */}
                <div className="ml-3 overflow-hidden w-fit">
                    <p className="text-sm font-medium text-slate-900">
                        {person.member_account_name || person.member}
                        {/* {person?.contact_type === 'SNS' && <span className="p-1 leading-8 border rounded-md border-[#2ACEFF] bg-[#2ACEFF] text-white ml-2">サブページ</span>} */}
                    </p>
                    <PersonDescription person={person} />
                </div>
                {type && type === "Deleted" ? (
                    <button
                        className="ml-auto rounded-2xl border border-[#A9A9A9] text-[#A9A9A9] px-[10px] py-1 font-extrabold text-sm cursor-pointer bg-white"
                        onClick={() => {
                            handleModalOpen(0);
                        }}
                    >
                        元に戻す
                    </button>
                ) : (
                    <>
                        <div
                            className="ml-auto mobile:!flex-shrink-0"
                            ref={dropdownRef}
                        >
                            <div
                                className="bg-orange-400 text-sm cursor-pointer text-white font-bold py-[5px]  mobile:px-3 pc:px-10 rounded-full hover:bg-orange-500 focus:outline-none focus:shadow-outline transform transition-colors duration-150 self-start my-auto"
                                onClick={toggleDropdown}
                            >
                                設定
                            </div>
                            {isOpen && (
                                <div className="downList">
                                    <div className="recoverList list cursor-pointer">
                                        <div
                                            className="listLink"
                                            onClick={() => {
                                                handleModalOpen(0);
                                            }}
                                        >
                                            元に戻す
                                        </div>
                                    </div>
                                    {!shouldHideNocontactItem && (
                                        <div className="nocontactList list cursor-pointer">
                                            <div
                                                className="listLink"
                                                onClick={() => {
                                                    handleModalOpen(1);
                                                }}
                                            >
                                                非通知する
                                            </div>
                                        </div>
                                    )}
                                    {!shouldHideBlockItem && (
                                        <div className="blockList list cursor-pointer">
                                            <div
                                                className="listLink"
                                                onClick={() => {
                                                    handleModalOpen(2);
                                                }}
                                            >
                                                ブロックする
                                            </div>
                                        </div>
                                    )}
                                    {!shouldHideDeletedItem && (
                                        <div className="deletedList list cursor-pointer">
                                            <div
                                                className="listLink"
                                                onClick={() => {
                                                    handleModalOpen(3);
                                                }}
                                            >
                                                削除する
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="inline-block ml-3">
                            {id < 2 && <MemoComponent />}
                        </div>
                    </>
                )}
            </li>

            <Modal open={isModalOpen < 4} className="mobile:w-full">
                <Box className=" !bg-gray-100 mobile:w-[90%]">
                    <div
                        className="flex justify-between flex-col absolute inset-1/2 transform -translate-x-1/2 top-[50%] left-[50%] -translate-y-1/2 z-50 pc:w-[500px] w-[90%] h-[200px] p-6 bg-white rounded-lg"
                        style={{ border: "solid 3px black" }}
                    >
                        <h2 className="text-zinc-600 text-center font-bold pc:text-[24px] text-base mt-2">
                            {modalTitle[isModalOpen]}
                        </h2>
                        <div className="flex justify-between mobile:mt-[20px] mt-[70px] font-light px-6">
                            <button
                                className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                onClick={() => {
                                    handleRequest(4);
                                }}
                            >
                                はい
                            </button>
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsModalOpen(4);
                                    setIsOpen(false);
                                }}
                            >
                                いいえ
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
