function Policy() {
    return (
        <div className="absolute max-w-screen top-0 left-0 min-h-screen bg-white p-4">
            <p>プライバシーポリシー</p>
            <br />
            <p>＜個人情報保護に関する基本方針＞</p>
            <p>
                当ショップは個人情報の重要性を認識し、その保護を徹底するために、個人情報保護方針を制定、実施し
            </p>
            <p>てまいります。</p>
            <p>■基本方針について</p>
            <p>
                1.個人情報の収集、利用にあたっては、その目的を明確に定め、業務に必要な範囲で取り扱います。
            </p>
            <p>
                2.個人情報の管理にあたっては、外部への流出防止に努め、外部からの不正アクセス、個人情報の紛失、
            </p>
            <p>
                破壊、改ざん、漏えいなどの危険に対し、適切かつ合理的な安全対策を講じます。
            </p>
            <p>
                3.個人情報は、法令に基づき司法機関、行政機関より法的義務を伴う要請を受けた場合を除き、お客様ご
            </p>
            <p>自身の同意なしに第三者に開示・提供はいたしません。</p>
            <p>
                4.個人情報は業務上必要がなくなったと判断した場合、本サイトにおける個人情報の取り扱いに基づき消
            </p>
            <p>去いたします。</p>
            <p>5.個人情報に関する法令およびその他の規範を遵守いたします。</p>
            <p>■本サイトにおける個人情報の取り扱いについて</p>
            <p>
                1.個人情報の収集について本サイトを利用して商品の注文、会員登録、プレゼント応募、メルマガ購読、
            </p>
            <p>
                お問い合せ、アンケートをご利用いただく際に、個人情報を収集いたします。
            </p>
            <p>2.個人情報を収集する目的について</p>
            <p>1. 注文商品の発送、代金の回収のため。</p>
            <p>
                2.
                お客様が個人情報の利用および目的を同意の上提供された場合、お客様に有益なサービス
            </p>
            <p>
                を電子メール、郵便により送信もしくは送付、またはお電話などでご案内させていただくため。
            </p>
            <p>3. プレゼントの抽選、発送のため。</p>
            <p>
                4.
                取得した閲覧履歴や購買履歴等の情報を分析して趣味・趣向に応じた新商品に関する広告のため。
            </p>
            <p>5. メールマガジンの配信のため。</p>
            <p>6. お問い合わせにお答えするため。</p>
            <p>
                3.個人情報の保護・管理について本サイトを利用して商品の注文、会員登録、プレゼント応募、メルマガ購読、お買い物アンケートをご利用いただく際に収集した個人情報は日本国内においてのみ取り扱い、紛失、破壊、改ざん及び漏洩などのリスクに対しての施策を講じるとともに、当ショップの従業員に対し、安全な管理や関連法令に準じた適切な取扱いを徹底して参ります。また、お客さまの同意を得た上で、個人情報を第三者に提供する際は、その第三者からの漏えい・再提供の防止などを図ります。
            </p>
            <p>
                1.個⼈情報に関するお客様からのお問い合わせなどについてお客様からお客様⾃身の個⼈情報について、次の要請
            </p>
            <p>
                を受けた場合は、問い合せに対応するため窓口を設置いたします。 1.
            </p>
            <p>個⼈情報の開示 </p>
            <p>2. 修正／削除</p>
            <p>3.個⼈情報の利⽤について同意した⼀部、または全部の撤回</p>
            <p>
                2.個⼈情報に関するお問い合わせを希望される場合は、本⼈確認の為、住⺠票の写し又は運転免許証の写しと、開示・修正・削除の場合には⼿数料として750円の定額⼩為替を同封していただきますので、あらかじめご了承ください。
            </p>
            <p>
                3.個⼈情報の開示・提供・破棄について次の項目に該当する場合、お客様の個⼈情報を開示する場合がございます。
            </p>
            <p>
                1.
                お客様が他のお客様、弊社の権利、利益、名誉などを損ねるような⾏為をしたことが判明した場合、
            </p>
            <p>
                お客様の個⼈情報をその当事者、警察、関連機関に通知することがございます。
            </p>
            <p>
                2.
                警察等関連機関、その他⾏政機関などより、お客様の個⼈情報についての開示要請があった場合、こ
            </p>
            <p>れを開示することがございます。</p>
            <p>
                4.カード発⾏会社がおこなうリスクベース認証(不正利⽤検知・防⽌)のために、お客様が利⽤されているカード発⾏会社へ以下の個⼈情報等を提供させていただきます。
            </p>
            <p>
                1.
                電話番号、emailアドレス、注⽂者住所、配送先住所、インターネット利⽤環境に関する情報など
            </p>
            <p>
                5.お客様が利⽤されているカード発⾏会社が海外にある場合、これらの情報は当該発⾏会社が所属する国に移転される場合があります。
            </p>
            <p>
                6.個⼈情報は業務上必要がなくなった後は以下の各号に該当する場合の除き、お客様の個⼈情報を遅滞な、かつ、第三者への漏洩がないよう破棄いたします。
            </p>
            <p>1. 法令の規定に基づき、保存しなければならないとき </p>
            <p>2.本⼈の同意があるとき </p>
            <p>
                3.当ショップが⾃⼰の業務の遂⾏に必要な限度で個⼈情報を保存する場合であって、当該個⼈情報を消去しないことについて相当な理由があるとき
            </p>
            <p>
                4.
                前三号に掲げる場合のほか、当該個⼈情報を消去しないことについて特別の理由があるとき
            </p>
            <p>
                7.Cookie情報の使⽤について当ショップでは本サイトを閲覧された時など、お客様により良いサービスを提供するために、ご使⽤デバイスを識別するCookie情報を記録する事がありますが、これにより個⼈を特定できる情報の収集を⾏えるものではございません。
            </p>
            <p>
                ご使⽤になるブラウザの設定によって、本サイトから送付されるクッキー情報の受取を拒否する事ができます。その場合、本サイトの⼀部サービスが正常に動作しない可能性がございます。
            </p>
            <p>
                8.アクセスログの取得について当ショップでは、本サイトのアクセスログを取得しています。
            </p>
            <p>
                アクセスログは、アクセスされた方のIPアドレス、ホスト名、ご利⽤のブラウザやOSの種類、アクセス⽇時などの情報が含まれますが、
            </p>
            <p>
                個⼈を特定できる情報を含むものではございません。
                アクセスログは、今後のサイトの利便性向上や、万⼀問題が発生した際の原因追及、利⽤状況に関する統計・分析処理などに使⽤するために採取しており、それ以外の目的には使⽤致しません。
            </p>
            <br />
            {/* Phần còn lại của nội dung tương tự */}
            <p>■お問い合わせ先について</p>
            <p>株式会社シンカリンク</p>
            <p>代表: 田中</p>
            <p>Tel: 011-500-9188</p>
            <a href="mailto:support@degime.net">E-mail: support@degime.net</a>
            <p>制定︓2024/07/12</p>
        </div>
    );
}
export default Policy;
