import { useState, useEffect } from "react";
import Datetimepicker from "../components/Datetimepicker";
import { Button, Modal, Box } from "@mui/material";

export default function SettingImageDialog({
    open,
    onclose,
    onReservation,
    onPublish,
    isClear,
    data,
}) {
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState();

    useEffect(() => {
        if (data) {
            setStartTime(data.startTime ?? data.data?.startTime ?? new Date());
            setEndTime(data.endTime ?? data.data?.endTime ?? "");
        }
    }, [data]);

    useEffect(() => {
        if (isClear) {
            setStartTime(new Date());
            setEndTime(undefined);
        }
    }, [isClear]);

    const handleCloseModal = () => {
        setStartTime(new Date());
        setEndTime();
        onclose();
    };

    const handleReservation = () => {
        onReservation({ startTime, endTime });
        handleCloseModal();
    };
    const handlePublish = () => {
        onPublish({ endTime });
        handleCloseModal();
    };
    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "4px",
                    boxShadow: 24,
                }}
            >
                <div className="min-w-[320px] h-[300px] flex flex-col items-center mx-auto shadow rounded-md bg-white">
                    <div className="w-full flex justify-between items-center p-2 bg-slate-400 mb-10 rounded-t-md">
                        <h2 className="font-extrabold text-lg">予約設定</h2>
                        <div
                            className="w-[20px] h-[20px] aspect-square bg-cover cursor-pointer"
                            style={{
                                backgroundImage: 'url("image/close.svg")',
                            }}
                            onClick={onclose}
                        ></div>
                    </div>
                    <div>
                        <h2>公開日時</h2>
                        <Datetimepicker
                            value={startTime}
                            disableValue={new Date()}
                            onChange={(date) => {
                                setStartTime(date ? new Date(date) : "");
                            }}
                        />
                    </div>
                    <div>
                        <h2>終了日時</h2>
                        <Datetimepicker
                            value={endTime}
                            disableValue={startTime}
                            onChange={(date) =>
                                setEndTime(date ? new Date(date) : "")
                            }
                        />
                    </div>
                    <div className="flex gap-3 mt-3">
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                            onClick={handlePublish}
                        >
                            すぐに公開する
                        </button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReservation}
                        >
                            予約を設定
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
        // <>
        //     {open && (
        //         <div className="absolute flex w-full h-full z-30 bg-black/70 top-0 left-0 justify-center items-center">
        //             <div className="w-[400px] h-[300px] flex flex-col items-center mx-auto shadow rounded-md bg-white">
        //                 <div className="w-full flex justify-between items-center p-2 bg-slate-400 mb-10 rounded-t-md">
        //                     <h2 className="font-extrabold text-lg">予約設定</h2>
        //                     <div
        //                         className="w-[20px] h-[20px] aspect-square bg-cover cursor-pointer"
        //                         style={{
        //                             backgroundImage: 'url("image/close.svg")',
        //                         }}
        //                         onClick={onclose}
        //                     ></div>
        //                 </div>
        //                 <div>
        //                     <h2>公開日時</h2>
        //                     <Datetimepicker
        //                         value={startTime}
        //                         disableValue={new Date()}
        //                         onChange={(date) => {
        //                             setStartTime(date ? new Date(date) : "");
        //                         }}
        //                     />
        //                 </div>
        //                 <div>
        //                     <h2>終了日時</h2>
        //                     <Datetimepicker
        //                         value={endTime}
        //                         disableValue={startTime}
        //                         onChange={(date) =>
        //                             setEndTime(date ? new Date(date) : "")
        //                         }
        //                     />
        //                 </div>
        //                 <div className="flex gap-3 mt-3">
        //                     <button
        //                         className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        //                         onClick={handlePublish}
        //                     >
        //                         すぐに公開する
        //                     </button>

        //                     <Button
        //                         variant="contained"
        //                         color="primary"
        //                         onClick={handleReservation}
        //                     >
        //                         予約を設定
        //                     </Button>
        //                 </div>
        //             </div>
        //         </div>
        //     )}
        // </>
    );
}
