import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./editor.css";
import { FileUpload } from "../../utilities/upload";

import {
    DecoupledEditor,
    AccessibilityHelp,
    Alignment,
    Autosave,
    BalloonToolbar,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontSize,
    Heading,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    List,
    Paragraph,
    RemoveFormat,
    SelectAll,
    SimpleUploadAdapter,
    SpecialCharacters,
    Strikethrough,
    TodoList,
    Underline,
    Undo,
    ImageResize,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

export default function CustomEditor({ value, onChange, ...props }) {
    const editorContainerRef = useRef(null);
    const editorMenuBarRef = useRef(null);
    const editorToolbarRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);

    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    const uploadAdapter = (loader) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then(async (file) => {
                        try {
                            const isSuccess = await FileUpload(file);
                            if (isSuccess) {
                                resolve({
                                    default: isSuccess,
                                });
                            }
                            throw new Error(
                                "An error occurs while uploading media. Try again!"
                            );
                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            },
        };
    };

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "heading",
                "|",
                "fontSize",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "bold",
                "italic",
                "underline",
                "|",
                "link",
                "insertImage",
                "|",
                "alignment",
                "|",
                "bulletedList",
                "numberedList",
                "todoList",
            ],
            shouldNotGroupWhenFull: false,
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            Autosave,
            BalloonToolbar,
            Bold,
            Essentials,
            FontColor,
            FontBackgroundColor,
            FontSize,
            Heading,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageToolbar,
            ImageUpload,
            Italic,
            Link,
            List,
            Paragraph,
            RemoveFormat,
            SelectAll,
            SimpleUploadAdapter,
            SpecialCharacters,
            Strikethrough,
            TodoList,
            Underline,
            Undo,
            ImageResize,
        ],
        balloonToolbar: [
            "bold",
            "italic",
            "|",
            "link",
            "insertImage",
            "|",
            "bulletedList",
            "numberedList",
        ],
        fontSize: {
            options: [10, 12, 14, "default", 18, 20, 22],
            supportAllValues: true,
        },
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },
        image: {
            resizeUnit: "px",
        },
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: "https://",
            decorators: {
                toggleDownloadable: {
                    mode: "manual",
                    label: "Downloadable",
                    attributes: {
                        download: "file",
                    },
                },
            },
        },
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
        extraPlugins: [uploadPlugin],
    };

    return (
        <div>
            <div className="main-container">
                <div
                    className="editor-container editor-container_document-editor"
                    ref={editorContainerRef}
                >
                    <div
                        className="editor-container__menu-bar hidden"
                        ref={editorMenuBarRef}
                    ></div>
                    <div
                        className="editor-container__toolbar"
                        ref={editorToolbarRef}
                    ></div>
                    <div className="editor-container__editor-wrapper">
                        <div className="editor-container__editor">
                            <div ref={editorRef}>
                                {isLayoutReady && (
                                    <CKEditor
                                        {...props} // Docs: https://ckeditor.com/docs/ckeditor5/latest/getting-started/installation/react/react.html#component-properties
                                        onReady={(editor) => {
                                            editorToolbarRef.current?.appendChild(
                                                editor.ui.view.toolbar.element
                                            );
                                            editorMenuBarRef.current?.appendChild(
                                                editor.ui.view.menuBarView
                                                    .element
                                            );
                                        }}
                                        onAfterDestroy={() => {
                                            Array.from(
                                                editorToolbarRef.current
                                                    ?.children
                                            ).forEach((child) =>
                                                child.remove()
                                            );
                                            Array.from(
                                                editorMenuBarRef.current
                                                    ?.children
                                            ).forEach((child) =>
                                                child.remove()
                                            );
                                        }}
                                        data={value ?? ""}
                                        onChange={(_event, editor) => {
                                            onChange(editor.getData());
                                        }}
                                        editor={DecoupledEditor}
                                        config={editorConfig}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
