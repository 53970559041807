import React, { useState, useEffect } from "react";
import {
    caseShortUrl,
    generateEmbedUrl,
    getEmbedUrl,
} from "../utilities/helper";

const Map = ({ isClear, onChangeData, data }) => {
    const [address, setAddress] = useState("");

    useEffect(() => {
        setAddress(data?.mapLink ?? data ?? "");
    }, [data]);

    const handleAddressChange = (e) => {
        onChangeData({ data: e.target.value, type: "map" });
        setAddress(e.target.value);
    };

    useEffect(() => {
        if (address) {
            const getData = async () => {
                if (address.startsWith("https://maps.app.goo.gl")) {
                    const res = await caseShortUrl(address);
                    onChangeData({ data: res, type: "map" });
                    setAddress(res);
                }
            };
            getData();
        }
    }, [address]);

    useEffect(() => {
        isClear && setAddress("");
    }, [isClear]);

    return (
        <div className="h-[350px]">
            <input
                type="text"
                value={address}
                onChange={handleAddressChange}
                className="p-2 border rounded-md mb-3"
                placeholder="住所を入力"
            />
            {address && (
                <div style={{ width: "100%" }} className="h-full">
                    <iframe
                        title="Google Map"
                        width="100%"
                        height="80%"
                        style={{ border: "0" }}
                        src={
                            address.startsWith(
                                "https://www.google.com/maps/embed"
                            )
                                ? address
                                : address.startsWith(
                                      "https://www.google.com/maps/"
                                  )
                                ? generateEmbedUrl(address)
                                : getEmbedUrl(address)
                        }
                        className="aspect-video"
                        allowFullScreen
                    />
                </div>
            )}
        </div>
    );
};

export default Map;
