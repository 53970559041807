import React, { useEffect, useRef, useState } from 'react';
import CropImageComponent from './cropImage';
import { Box, Modal } from '@mui/material';

const CropImageModal = ({ id, onChange, disabled, accept, className }) => {
    const [open, setOpen] = useState(false)
    const [image, setImage] = useState();
    const fileInput = useRef();
    const handleCrop = (croppedImg) => {
        onChange(croppedImg);
    };
    const onFileUpload = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0] || fileInput.current.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImage(reader.result);
            setOpen(true);
        };
    }

    return (
        <div>
            <input
                type="file"
                className={className}
                id={id}
                accept={accept}
                ref={fileInput}
                onChange={onFileUpload}
                disabled={disabled}
            />
            <Modal open={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '1px solid #000',
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                }} className=" !bg-gray-100">
                    <CropImageComponent
                        open={open}
                        imageSrc={image}
                        onCrop={handleCrop}
                        onClose={() => setOpen(false)}
                    /></Box>
            </Modal>
        </div>
    );
};

export default CropImageModal;
