import React, { useState, useEffect, useRef } from "react";
import {
    TextSnippet,
    FileDownloadOutlined,
    MoreHoriz,
} from "@mui/icons-material";
import { formatBytes, handleDownload } from "../../../utilities/helper";
import ReactPlayer from "react-player";
import PreviewModal from "../../../components/PreviewImageModal";
import { Avatar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalConfirm from "../../../components/Modal/ModalConfirm";
import { Apis } from "../../../api/index";
import { toast } from "react-toastify";

export default function SentChat({
    user,
    avatar,
    text,
    name,
    size,
    type,
    username,
    messageId,
    onRemoveItem,
    roomName,
}) {
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElForMobile, setAnchorElForMobile] = useState(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const open = Boolean(anchorEl);
    const openForMobile = Boolean(anchorElForMobile);
    const [isShowAction, setIsShowAction] = useState(false);
    let longPressTimer = null;
    const messageRef = useRef(null);
    const menuItemRef = useRef(null);

    const handleImageClick = (url) => {
        setImageUrl(url);
        setShowModal(true);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElForMobile(null);
        setIsShowAction(false);
    };

    const handleDeleteMessage = async () => {
        try {
            const response = await Apis.myDelete(`chat/private/message`, {
                messageId,
                roomName,
            });
            if (response && response.success) {
                onRemoveItem(messageId);
                return;
            }
            throw new Error("メッセージの削除に失敗しました");
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsShowModal(false);
            handleClose();
        }
    };

    const handleMouseDown = () => {
        longPressTimer = setTimeout(() => {
            setIsShowAction(true);
        }, 500);
    };
    const handleMouseUp = () => {
        clearTimeout(longPressTimer);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                messageRef.current &&
                !messageRef.current.contains(event.target) &&
                menuItemRef.current &&
                !menuItemRef.current.contains(event.target)
            ) {
                console.log(1231323);

                setIsShowAction(false);
            }
        };

        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex justify-end">
            <ModalConfirm
                isOpen={isShowModal}
                onClose={() => {
                    setIsShowModal(false);
                    handleClose();
                }}
                title="メッセージ内容を削除してもよろしいですか?"
                onOk={handleDeleteMessage}
            />
            <div
                className="flex w-11/12 flex-row-reverse parent-hover"
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                ref={messageRef}
            >
                <div className="flex items-center">
                    <div
                        className={`h-fit pr-3 hidden ${
                            open ? "lg:flex" : "child-display"
                        }`}
                    >
                        <button
                            id={`button_chat_message_${messageId}`}
                            aria-controls={
                                open ? `menu_item_${messageId}` : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <MoreHoriz className="my-auto cursor-pointer" />
                        </button>
                        <Menu
                            id={`menu_item_${messageId}`}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            MenuListProps={{
                                "aria-labelledby": `button_chat_message_${messageId}`,
                            }}
                        >
                            <MenuItem onClick={() => setIsShowModal(true)}>
                                削除
                            </MenuItem>
                        </Menu>
                    </div>
                    {isShowAction && (
                        <div className={`h-fit pr-3 flex`}>
                            <button
                                id={`button_chat_message_${messageId}`}
                                aria-controls={
                                    openForMobile
                                        ? `menu_item_${messageId}`
                                        : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    openForMobile ? "true" : undefined
                                }
                                onClick={handleClick}
                            >
                                <MoreHoriz className="my-auto cursor-pointer" />
                            </button>
                            <Menu
                                id={`menu_item_${messageId}`}
                                anchorEl={anchorElForMobile}
                                open={openForMobile}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                MenuListProps={{
                                    "aria-labelledby": `button_chat_message_${messageId}`,
                                }}
                            >
                                <MenuItem
                                    ref={menuItemRef}
                                    onClick={() => setIsShowModal(true)}
                                >
                                    削除
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                    {type === "text" && (
                        <div className="relative">
                            <div className="relative max-w-[300px] overflow-hidden break-all rounded-[20px] bg-[#FF6A68] px-4 py-2 self-start shadow-lg">
                                {text &&
                                    text.split("\n").map((item, key) => (
                                        <>
                                            <span
                                                key={key}
                                                className="text-[22px] md:text-sm font-medium text-heading"
                                            >
                                                {item}
                                            </span>
                                            <br />
                                        </>
                                    ))}
                            </div>

                            <div className="absolute flex-col items-center justify-center flex bottom-0 -right-[10px]">
                                <Avatar
                                    src={avatar ?? ""}
                                    alt="avatar"
                                    className="inline-block rounded-full bg-white"
                                    sx={{ height: "24px", width: "24px" }}
                                >
                                    {username[0]}
                                </Avatar>
                            </div>
                        </div>
                    )}
                    {type.includes("image/") && (
                        <>
                            <div
                                className={`h-full pr-3 hidden ${
                                    open ? "lg:flex" : "child-display"
                                }`}
                            >
                                <button
                                    onClick={() => handleDownload(text, name)}
                                >
                                    <FileDownloadOutlined className="my-auto cursor-pointer" />
                                </button>
                            </div>
                            {isShowAction && (
                                <div className={`h-full pr-3 flex`}>
                                    <button
                                        onClick={() =>
                                            handleDownload(text, name)
                                        }
                                    >
                                        <FileDownloadOutlined className="my-auto cursor-pointer" />
                                    </button>
                                </div>
                            )}
                            <img
                                src={text}
                                className="max-w-[200px] h-auto object-contain rounded-lg cursor-pointer"
                                alt={name}
                                onClick={() => handleImageClick(text)}
                            />
                        </>
                    )}
                    {(type.includes("application/") ||
                        type.includes("text/")) && (
                        <>
                            <div
                                className={`h-full pr-3 hidden ${
                                    open ? "lg:flex" : "child-display"
                                }`}
                            >
                                <button
                                    onClick={() => handleDownload(text, name)}
                                >
                                    <FileDownloadOutlined className="my-auto cursor-pointer" />
                                </button>
                            </div>
                            {isShowAction && (
                                <div className={`h-full pr-3 flex`}>
                                    <button
                                        onClick={() =>
                                            handleDownload(text, name)
                                        }
                                    >
                                        <FileDownloadOutlined className="my-auto cursor-pointer" />
                                    </button>
                                </div>
                            )}
                            <div className="flex items-center gap-2 bg-zinc-500 w-fit p-2 rounded-lg">
                                <TextSnippet
                                    sx={{
                                        color: "#BBBCBD",
                                        width: "20px",
                                        height: "20px",
                                    }}
                                />
                                <div className="flex flex-col text-white">
                                    <p className="break-all">{name}</p>
                                    <p className="text-sm leading-4">
                                        {formatBytes(size)}
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {type.includes("video/") && (
                        <>
                            <div
                                className={`h-full pr-3 hidden ${
                                    open ? "lg:flex" : "child-display"
                                }`}
                            >
                                <button
                                    onClick={() => handleDownload(text, name)}
                                >
                                    <FileDownloadOutlined className="my-auto cursor-pointer" />
                                </button>
                            </div>
                            {isShowAction && (
                                <div className={`h-full pr-3 flex`}>
                                    <button
                                        onClick={() =>
                                            handleDownload(text, name)
                                        }
                                    >
                                        <FileDownloadOutlined className="my-auto cursor-pointer" />
                                    </button>
                                </div>
                            )}
                            <div className="preview-player">
                                <ReactPlayer
                                    url={text}
                                    controls
                                    height={150}
                                    width={"auto"}
                                />
                            </div>
                        </>
                    )}
                    {type.includes("audio/") && (
                        <>
                            <div
                                className={`h-full pr-3 hidden ${
                                    open ? "lg:flex" : "child-display"
                                }`}
                            >
                                <button
                                    onClick={() => handleDownload(text, name)}
                                >
                                    <FileDownloadOutlined className="my-auto cursor-pointer" />
                                </button>
                            </div>
                            {isShowAction && (
                                <div className={`h-full pr-3 flex`}>
                                    <button
                                        onClick={() =>
                                            handleDownload(text, name)
                                        }
                                    >
                                        <FileDownloadOutlined className="my-auto cursor-pointer" />
                                    </button>
                                </div>
                            )}
                            <div className="preview-player-audio">
                                <ReactPlayer
                                    url={text}
                                    controls
                                    height={50}
                                    width={200}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <PreviewModal
                open={showModal}
                onClose={() => setShowModal()}
                imageUrl={imageUrl}
            />
        </div>
    );
}
