import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Apis } from "../api";
import { toast } from "react-toastify";
import PublicView from "../components/PublicView";
import { useAuthContext } from "../auth/context";
import { AppContext } from "../App";

export default function ContactDetail() {
    const { reAuth } = useAuthContext();
    const [profileData, setProfileData] = useState({
        bgColor: "",
        bgURL: "",
        wordColor: "",
        cardColor: "",
        cardURL: "",
        url_name: "",
        faceImg: "",
        realName: "",
        company_url: "",
        companyName: "",
        position: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        mailAddress: "",
        address: "",
        idCard: {
            idCard: [],
        },
        socialLink: {
            socialLink: [],
        },
        onlineCard_Data: {
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [],
            slideLink: [],
            spaceAdd: [],
        },
    });
    const navigate = useNavigate();
    const { member } = useParams();

    const fetchDataProfile = async () => {
        try {
            const BusinessResponse = await Apis.myGet(
                `social/public/online?username=${encodeURIComponent(member)}`
            );
            if (BusinessResponse.data && BusinessResponse.data.length) {
                setProfileData(BusinessResponse.data[0]);
                reAuth();
                return;
            }
            throw new Error("ページが存在しません!");
        } catch (error) {
            toast.error("ページが存在しません!");
            navigate("/d-contact");
            console.error(error.message);
        }
    };

    useEffect(() => {
        fetchDataProfile();
    }, []);

    const { previousPage } = useContext(AppContext);

    return (
        <>
            <div className="flex justify-between items-center">
                <div>
                    {previousPage /* window.history.length > 2 */ && (
                        <Link to={-1}>
                            <img
                                src="/image/turn-left.png"
                                alt="return"
                                className="w-[40px] h-[60px] cursor-pointer ml-5 pt-5"
                            ></img>
                        </Link>
                    )}
                </div>
                <div className="flex mr-5 pt-5 relative"></div>
            </div>
            <div className="w-[95%] md:w-[80%] mx-auto">
                <div className="flex gap-3 items-center my-[20px] md:mt-[50px] md:mb-[35px]">
                    <PublicView publishData={profileData} username={member} />
                </div>
            </div>
        </>
    );
}
