import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth/context";

export default function MenuBar() {
    const navigate = useNavigate();
    const { user, setShowLoginModal } = useAuthContext();
    const onClickMenu = (path) => {
        if (user) {
            navigate(path);
        } else {
            setShowLoginModal(true);
        }
    };
    const bottomItems = [
        {
            name: "shop",
            icon: "/image/shop.png",
            link: "/d-shop",
            content: "ショップ",
        },
        {
            name: "chat",
            icon: "/image/chat.png",
            link: "/d-chat/list",
            content: "チャット",
        },
        {
            name: "contactManageBottom",
            icon: "/image/contact.png",
            link: "/d-contact",
            content: "名刺管理",
        },
        {
            name: "home",
            icon: "/image/home.png",
            link: "/d-dashboard",
            content: "トップ",
        },
        {
            name: "data",
            icon: "/image/data.png",
            link: "/d-data",
            content: "データ管理",
        },
        {
            name: "setting",
            icon: "/image/setting.png",
            link: "/d-setting",
            content: "設定",
        },
    ];
    return (
        <>
            <div className="w-full h-[70px] md:hidden"></div>
            <div className="md:hidden bottom z-[19]">
                <div className="h-full flex items-center justify-around">
                    {bottomItems.map((item, index) => {
                        const isActived = window.location.pathname.includes(
                            item.link
                        );
                        return (
                            <div
                                key={index}
                                className={`relative flex flex-col items-center p-1 rounded-lg ${
                                    isActived ? "bg-slate-300" : ""
                                }`}
                                onClick={() => onClickMenu(item.link)}
                            >
                                <img
                                    className="bottomIcon"
                                    alt={item.name}
                                    src={item.icon}
                                ></img>
                                <span
                                    className={`${isActived ? "" : "hidden"}`}
                                >
                                    {item.content}
                                </span>
                                {user &&
                                    user?.have_message_unread &&
                                    item.name === "chat" && (
                                        <div className="absolute top-0 -right-5 h-4 w-4 bg-red-400 rounded-full"></div>
                                    )}
                                {user &&
                                    user?.count_new_contact > 0 &&
                                    item.name === "contactManageBottom" && (
                                        <div className="absolute top-0 -right-2 aspect-square min-w-5 w-fit bg-[#FFC000] rounded-full text-sm flex justify-center items-center">
                                            <p>{user.count_new_contact}</p>
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
