import React, { createContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Dashboard from "./pages/Dashboard.js";
import Login from "./pages/Login.js";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import PublicShow from "./components/PublicShow.js";
import Useful from "./components/useful.js";
import ContactDetail from "./pages/ContactDetail.js";
import ContactList from "./pages/Contactlist.js";
import EditOnline from "./pages/EditOnline.js";
import EditSocial from "./pages/EditSocial.js";
import Layout from "./pages/Layout.js";
import Online from "./pages/Online.js";
import Pagenotfound from "./pages/Pagenotfound.js";
import Register from "./pages/Register.js";
import Setting from "./pages/Setting.js";
import Social from "./pages/Social.js";
import Adminlayout from "./pages/admin/Adminlayout.js";
import Chartlist from "./pages/admin/Chartlist.js";
import Emailmarketing from "./pages/admin/Emailmarketing.js";
import Gennealogylist from "./pages/admin/Genealogylist.js";
import Primaryagency from "./pages/admin/Primaryagency.js";
import Secondaryagency from "./pages/admin/Secondaryagency.js";
import Userlist from "./pages/admin/Userlist.js";
import NewProduct from "./pages/admin/product/NewProduct";
import ProductList from "./pages/admin/product/ProductList";
import UpdateProduct from "./pages/admin/product/UpdateProduct";
import ChatAddList from "./pages/chat/ChatAddList.js";
import Chatlist from "./pages/chat/Chatlist.js";
import Chatpane from "./pages/chat/Chatpane.js";
import BlockList from "./pages/list/BlockList.js";
import DeletedList from "./pages/list/DeletedList.js";
import NocontactList from "./pages/list/NocontactList.js";
import ContractFour from "./pages/shop/ContractFour.js";
import ContractOne from "./pages/shop/ContractOne.js";
import ContractThree from "./pages/shop/ContractThree.js";
import ShopList from "./pages/shop/ShopList.js";
import Shopdetail from "./pages/shop/Shopdetail.js";

import { acceptedList, pendingList, profileButton } from "./data.js";
import DashboardLayout from "./pages/DashboardLayout";
import Line from "./pages/Line.jsx";
import AdminSetting from "./pages/admin/AdminSetting";
import AgencyManagement from "./pages/admin/AgencyManagement";
import EmailSetting from "./pages/admin/email/EmailSetting";
import NewEmail from "./pages/admin/email/NewEmail";
import ShippedList from "./pages/admin/orders/ShippedList";
import ShippingList from "./pages/admin/orders/ShippingList";
import SalesList from "./pages/admin/sales/SalesList";
import EmailVerify from "./pages/home/EmailVerify.jsx";
import Policy from "./pages/home/Policy.jsx";
import SpecifiedCommercialTransactions from "./pages/home/SpecifiedCommercialTransactions.jsx";
import TermsOfUse from "./pages/home/TermsOfUse.jsx";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { liff } from "@line/liff";
import { Helmet } from "react-helmet";
import { pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import Loading from "../src/components/Loading.jsx";
import { websocketurl } from "./api/index.js";
import { useAuthContext } from "./auth/context.jsx";
import MenuBar from "./components/MenuBar.jsx";
import LoginModal from "./components/Modal/Auth/login.jsx";
import LoginSelectModal from "./components/Modal/Auth/loginSelect.jsx";
import PageFooter from "./components/PageFooter.js";
import Profile from "./pages/profile.jsx";

export const AppContext = createContext({ previousPage: "" });
/**
 * Define the "App" component as a function.
 */
const App = () => {
    const [pathName, setPathName] = React.useState("");
    const [previousPage, setPreviousPage] = React.useState("");

    const token = localStorage.getItem("access_token");

    const location = useLocation();
    useEffect(() => {
        if (pathName !== location.pathname) {
            setPreviousPage(pathName);
        }
        if (window.location.pathname === "/dashboard") {
            window.location.href = "https://top.degime.net/";
        }
        setPathName(window.location.pathname);
    }, [location]);

    // Required lifecycle method: defines what
    // shows up on screen

    // Set the workerSrc path for PDF Document
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const { loading, setSocket, user, setLiff } = useAuthContext();

    useEffect(() => {
        if (!user) return;
        const client = new WebSocket(
            `${
                process.env.REACT_APP_WEBSOCKET_PROTOCOL || "wss"
            }://${websocketurl}/ws/personal_chat/${token}/${user.username}`
        );
        setSocket(client);
        client.onopen = () => {
            console.log("Websocket successfully connected");
        };

        client.onclose = () => {
            console.log("Websocked is already closed");
        };
        return () => {
            client.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    useEffect(() => {
        const initializeLiff = async () => {
            try {
                await liff.init({ liffId: "2005830145-p9AqqgA3" });
                setLiff(liff);
            } catch (error) {
                console.error("error", error);
            }
        };
        initializeLiff();
    }, []);

    return (
        <AppContext.Provider value={{ previousPage }}>
            <div className="App-container">
                <Helmet>
                    {/* <!-- Google tag (gtag.js) --> */}
                    <script
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=G-6VRERDETHH"
                    ></script>
                    <script type="text/javascript">
                        {`
                    window.dataLayer = window.dataLayer || []; function gtag()
                    {dataLayer.push(arguments)}
                    gtag('js', new Date()); gtag('config', 'G-6VRERDETHH');
                `}
                    </script>
                    <script src="https://static.line-scdn.net/liff/2.1/sdk.js"></script>
                </Helmet>
                {loading && <Loading />}
                <GoogleOAuthProvider clientId="642242551944-9nl9lgdae3gp39912490aop16ucvif66.apps.googleusercontent.com">
                    {/* <NavBar /> */}
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <Routes>
                        {/* <Route path="/" element={<Feed />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<NotFound />} /> */}
                        <Route path="*" element={<Pagenotfound />} />
                        <Route path="/" redirect element={<DashboardLayout />}>
                            <Route
                                index
                                element={<Navigate to="d-dashboard" />}
                            />
                            <Route path="d-dashboard" element={<Dashboard />} />

                            <Route path="d-data" element={<Chartlist />} />
                            <Route path="d-setting" element={<Setting />} />

                            <Route
                                path="d-contact/*"
                                element={<ContactList />}
                            />
                            <Route
                                path="d-contact/:member"
                                element={<ContactDetail />}
                            />
                            <Route path="d-profile" element={<Profile />} />
                            <Route path="/d-chat">
                                <Route index element={<Navigate to="list" />} />
                                <Route
                                    path="list"
                                    element={
                                        <Chatlist
                                            pendingList={pendingList}
                                            acceptedList={acceptedList}
                                        />
                                    }
                                />
                                <Route path="channel" element={<Chatpane />} />
                                <Route path="add" element={<ChatAddList />} />
                            </Route>

                            <Route path="/d-shop">
                                <Route index element={<Navigate to="list" />} />
                                <Route path="list" element={<ShopList />} />
                                <Route
                                    path="detail/:code"
                                    element={<Shopdetail />}
                                />
                                <Route
                                    path="contract/1"
                                    element={<ContractOne />}
                                />
                                {/* <Route
                                path="contract/2"
                                element={<ContractTwo />}
                            /> */}
                                <Route
                                    path="contract/2"
                                    element={<ContractThree />}
                                />
                                <Route
                                    path="contract/3"
                                    element={<ContractFour />}
                                />
                            </Route>
                            <Route
                                path="/d-onlineclosed"
                                element={<Online />}
                            />
                            <Route path="/d-social" element={<Social />} />
                            <Route
                                path="/d-online"
                                element={
                                    <EditOnline profileButton={profileButton} />
                                }
                            />
                            <Route
                                path="/d-editsocial"
                                element={
                                    <EditSocial profileButton={profileButton} />
                                }
                            />
                            <Route
                                path="/d-layout"
                                element={
                                    <Layout contact_badge={4} chat_badge={8} />
                                }
                            >
                                <Route path="list">
                                    <Route
                                        index
                                        element={
                                            <Navigate to="nocontactlist" />
                                        }
                                    />
                                    <Route
                                        path="nocontactlist"
                                        element={<NocontactList />}
                                    />
                                    <Route
                                        path="deletedlist"
                                        element={<DeletedList />}
                                    />
                                    <Route
                                        path="blocklist"
                                        element={<BlockList />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="/d-home">
                            <Route
                                index
                                element={<Navigate to="terms_of_use" />}
                            />
                            <Route
                                path="terms_of_use"
                                element={<TermsOfUse />}
                            />
                            <Route path="c-policy" element={<Policy />} />
                            <Route
                                path="d-verify-email"
                                element={<EmailVerify />}
                            />
                            <Route
                                path="specified-commercial-transactions"
                                element={<SpecifiedCommercialTransactions />}
                            />
                        </Route>
                        <Route path="/d-line" element={<Line />} />
                        <Route path="/d-login" element={<Login />} />
                        {/* <Route path="/useful" element={<Useful/>} /> */}
                        <Route path="/d-register" element={<Register />} />

                        <Route path="/d-useful" element={<Useful />} />
                        <Route path="/user" element={<Adminlayout />}>
                            <Route index element={<Navigate to="agency" />} />
                            <Route
                                path="agency"
                                element={<AgencyManagement />}
                            />
                            <Route
                                path="genealogies"
                                element={<Gennealogylist />}
                            />
                        </Route>
                        <Route path="/admin" element={<Adminlayout />}>
                            <Route index element={<Navigate to="users" />} />
                            <Route path="users" element={<Userlist />} />
                            <Route
                                path="genealogies"
                                element={<Gennealogylist />}
                            />
                            <Route
                                path="primaryagency"
                                element={<Primaryagency />}
                            />
                            <Route
                                path="secondaryagency"
                                element={<Secondaryagency />}
                            />
                            <Route path="products">
                                <Route path="" element={<ProductList />} />
                                <Route path="new" element={<NewProduct />} />
                                <Route
                                    path="edit"
                                    element={<UpdateProduct />}
                                />
                            </Route>
                            <Route path="orders">
                                <Route
                                    path="shipping"
                                    element={<ShippingList />}
                                />
                                <Route
                                    path="shipped"
                                    element={<ShippedList />}
                                />
                                {/* <Route path="canceled" element={<CanceledList />} /> */}
                            </Route>
                            <Route path="sales" element={<SalesList />} />
                            <Route path="emails">
                                <Route path="" element={<EmailSetting />} />
                                <Route path="new" element={<NewEmail />} />
                            </Route>
                            <Route
                                path="agency"
                                element={<AgencyManagement />}
                            />
                            <Route path="setting" element={<AdminSetting />} />
                        </Route>

                        <Route path="d-mail" element={<Emailmarketing />} />
                        <Route path="/:url_name" element={<PublicShow />} />
                    </Routes>
                    <LoginSelectModal />
                    <LoginModal />
                </GoogleOAuthProvider>
                {!(
                    pathName === "/d-online" ||
                    pathName === "/d-editsocial" ||
                    pathName.includes("/d-home") ||
                    pathName.includes("/d-chat/channel")
                ) && <PageFooter />}
                <MenuBar />
            </div>
        </AppContext.Provider>
    );
};

export default App;
