// components/DialogWithTable.js
import {
    Dialog,
    DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";

const DialogFeeHistory = ({ open, handleClose, data }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
                <div className="mb-6 w-full text-center text-2xl font-bold">
                    振込履歴
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>振込日時</TableCell>
                                <TableCell>購入者数</TableCell>
                                <TableCell>推定収益合計</TableCell>
                                <TableCell>手数料</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.date}</TableCell>
                                    <TableCell>{row.total_buyer}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.fee}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
};

export default DialogFeeHistory;
