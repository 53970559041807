import { DeleteForever } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Apis } from "../api";
import { useAuthContext } from "../auth/context";
import PersonDescription from "./PersonDescription";

export default function Chatitem({
    url,
    username,
    content,
    person,
    is_online,
    date,
    onClick,
    isCreateShow,
    tab,
    id,
    isSelectedState,
    messageUnread,
    setReload,
    memberCount,
    data,
    onShowModalDelete,
    isNew,
}) {
    const [isSelected, setisSelected] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user, reAuth } = useAuthContext();

    const handleSelect = () => {
        if (tab && isCreateShow) {
            isSelectedState(id);
            isShow && setisSelected((pre) => !pre);
        } else if (tab) {
            onClick(id);
        }
    };

    useEffect(() => {
        if (tab) {
            if (isCreateShow) {
                if (memberCount > 1) return;
                setIsShow(true);
            } else {
                setIsShow(false);
                setisSelected(false);
            }
        }
    }, [isCreateShow, tab]);

    const handleApply = async () => {
        const data = await Apis.myPut("social/private/contactdata", {
            member: username,
            is_chat_available: "True",
        });
        data && setIsHidden(true);
        setIsModalOpen(false);
        setReload();
        reAuth();
    };

    return (
        <>
            <li
                className={`flex py-2 md:py-5 first:pt-0 last:pb-0 items-center ${
                    isHidden && "hidden"
                }`}
                onClick={() => (isCreateShow ? handleSelect() : null)}
            >
                <div
                    className="relative h-[60px] w-[60px] flex justify-center items-center cursor-pointer"
                    onClick={handleSelect}
                >
                    {tab ? (
                        <>
                            <Avatar
                                className="rounded-full object-contain"
                                src={url ?? ""}
                                alt={`${username}-avatar`}
                                sx={{ width: "50px", height: "50px" }}
                            >
                                {username && username[0]}
                            </Avatar>
                            {(messageUnread > 0 || isNew) && (
                                <UnReadArea
                                    messageUnread={
                                        messageUnread > 0
                                            ? messageUnread
                                            : undefined
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <Link target="_blank" to={`/${username}`}>
                            <Avatar
                                className="rounded-full object-contain"
                                src={url ?? ""}
                                alt={`${username}-avatar`}
                                sx={{ width: "50px", height: "50px" }}
                            >
                                {username && username[0]}
                            </Avatar>
                        </Link>
                    )}
                    <div
                        className={`absolute bottom-1 right-1 w-[15px] h-[15px] rounded-full ${
                            is_online ? "bg-green-500" : "bg-red-500"
                        } `}
                    ></div>
                </div>
                <div
                    className="mobile:ml-3 pc:ml-10 w-[50%] md:w-[35%] overflow-hidden cursor-pointer"
                    onClick={handleSelect}
                >
                    <p className="text-[15px] font-normal text-slate-900 truncate">
                        {person?.member_full_name || username}
                        {/* {person.contact_type === 'SNS' && <span className="p-1 leading-8 border rounded-md border-[#2ACEFF] bg-[#2ACEFF] text-white ml-2">サブページ</span>} */}
                    </p>
                    {!content && person && (
                        <PersonDescription person={person} />
                    )}
                    <p className="text-[13px] font-light text-primary truncate">
                        {content}
                    </p>
                    <p className="text-[9px] text-slate-900 truncate">{date}</p>
                </div>

                {tab ? (
                    isShow ? (
                        <div className="ml-auto">
                            {!isSelected ? (
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="9"
                                        cy="9"
                                        r="8.75"
                                        stroke="#777777"
                                        strokeWidth="0.5"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        fill="#4C31F4"
                                    />
                                    <line
                                        x1="3.35355"
                                        y1="9.64645"
                                        x2="7.35355"
                                        y2="13.6464"
                                        stroke="white"
                                    />
                                    <line
                                        x1="6.64645"
                                        y1="13.6464"
                                        x2="14.6464"
                                        y2="5.64645"
                                        stroke="white"
                                    />
                                </svg>
                            )}
                        </div>
                    ) : (
                        !isCreateShow &&
                        data.member.length > 1 &&
                        user?.username === data.creator.url_name && (
                            <div className="ml-auto">
                                <button onClick={() => onShowModalDelete(id)}>
                                    <DeleteForever />
                                </button>
                            </div>
                        )
                    )
                ) : (
                    <div
                        className="mobile:px-1 pc:px-5 py-2 text-white ml-auto rounded-lg bg-red-600 hover:bg-red-700 active:bg-red-800 mobile:text-sm"
                        onClick={() => setIsModalOpen(true)}
                    >
                        承認する
                    </div>
                )}
                {isModalOpen && (
                    <>
                        <div
                            className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50  pc:w-[500px] w-[90%] h-[250px] p-6 bg-white rounded-lg"
                            style={{ border: "solid 3px black" }}
                        >
                            <h2 className="text-zinc-600 text-center font-bold text-[24px] mt-2">
                                本当にこのユーザーをチャットに
                                <br />
                                承認しますか？
                            </h2>
                            <div className="flex justify-between mobile:mt-[20px] mt-[70px] font-light px-6">
                                <button
                                    className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                    onClick={handleApply}
                                >
                                    はい
                                </button>
                                <button
                                    className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                    onClick={() => {
                                        setIsModalOpen(false);
                                    }}
                                >
                                    いいえ
                                </button>
                            </div>
                        </div>
                        <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30"></div>
                    </>
                )}
            </li>
        </>
    );
}

export const UnReadArea = ({ messageUnread }) => {
    return (
        <div className="absolute top-0 z-7 -right-1 p-[3px] h-6 w-6 rounded-full flex justify-center items-center bg-danger">
            {messageUnread && (
                <p className="text-white text-xs">{messageUnread}</p>
            )}
        </div>
    );
};
