import { Close } from "@mui/icons-material";
import {
    Box,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Apis, urlAPI } from "../../api";

export default function ModalHistoryPayment({
    userId,
    startDate,
    endDate,
    isOpen,
    onClose,
    isRootMember,
}) {
    const [data, setData] = useState([]);
    const getHistoryByUser = async (query) => {
        try {
            const res = await Apis.myGet(query);
            if (res && res.success) {
                setData(res.data.data);
                return;
            }
            setData([]);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (isOpen && userId) {
            getHistoryByUser(
                `${urlAPI.userPaymentHistory}?userId=${userId}${
                    isRootMember ? `&isRootMember=${isRootMember}` : ""
                }${startDate ? `&startDate=${startDate}` : ""}${
                    endDate ? `&endDate=${endDate}` : ""
                }`
            );
        }
    }, [isOpen, userId]);
    const formatDate = "YYYY/MM/DD HH:mm";

    const handleCloseModal = () => {
        setData([]);
        onClose();
    };
    return (
        <Modal
            open={isOpen}
            className="mobile:w-full"
            onClose={handleCloseModal}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minWidth: 300,
                    minHeight: 300,
                    bgcolor: "background.paper",
                    borderRadius: "4px",
                    boxShadow: 24,
                    padding: "10px",
                }}
            >
                <div className="flex justify-end cursor-pointer ">
                    <Close
                        onClick={handleCloseModal}
                        className="text-zinc-500"
                    />
                </div>
                <div>
                    <TableContainer
                        className="max-h-[400px]"
                        // component={Paper}
                    >
                        <Table
                            sx={{
                                minWidth: 450,
                                maxHeight: 400,
                                overflow: "auto",
                            }}
                            stickyHeader
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <p className="text-lg font-semibold">
                                            日付
                                        </p>
                                    </TableCell>
                                    <TableCell align="center">
                                        <p className="text-lg font-semibold">
                                            合計
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                Array.isArray(data) &&
                                data.length > 0 ? (
                                    data.map((row) => (
                                        <TableRow
                                            key={row.date}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                className="flex justify-center items-center"
                                            >
                                                <div className="text-center">
                                                    {dayjs(row.date).format(
                                                        formatDate
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell className="flex justify-center items-center">
                                                <div className="text-center">
                                                    {row.total}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">
                                            データなし
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Modal>
    );
}
