import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

const OrderListTable = ({
    data,
    changePaging,
    handleOpenInfoProfile,
    total,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedValue, setSelectedValue] = useState(100);
    const [info, setInfo] = useState();

    const handleSelectChange = (event) => {
        setSelectedValue(parseInt(event.target.value));
    };

    const [openModalEditUser, setOpenInfoProfile] = useState(false);
    const handleCloseModalEditUser = () => {
        setOpenInfoProfile(false);
        setInfo(undefined);
    };

    //   const handleOpenInfoProfile = (item) => {
    //     setInfo(item);
    //     setOpenInfoProfile(true);
    //   }

    const style = {
        position: "absolute",
        top: "70%",
        left: "55%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    useEffect(() => {
        if (changePaging) {
            setCurrentPage(1);
            changePaging(selectedValue, 1);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (changePaging) {
            changePaging(selectedValue, currentPage);
        }
    }, [currentPage]);

    // Render the table
    return (
        <div className="shadow-xl rounded-lg overflow-x-auto">
            <table className="w-full min-w-[900px] text-center">
                <tbody>
                    <tr className=" bg-[#F7F9FD]">
                        <th className="pt-10 pb-2">No</th>
                        <th className="pt-10 pb-2">氏名</th>
                        <th className="pt-10 pb-2">ユーザーID</th>
                        <th className="pt-10 pb-2">紹介した人数</th>
                        <th className="pt-10 pb-2">新規登録日時</th>
                        <th className="pt-10 pb-2">購入日時</th>
                        <th className="pt-10 pb-2">購入した製品/個数</th>
                        <th className="pt-10 pb-2">合計代金</th>
                        <th className="py-3"></th>
                    </tr>
                    {data?.map((item, index) => (
                        <tr
                            key={index}
                            className={`even:bg-white odd:bg-[#EBF2FF]`}
                        >
                            <td className="p-2 w-10">{item.id}</td>
                            <td className="p-2 min-w-[100px]">
                                <p className="break-all line-clamp-1">
                                    {item.fullName}
                                </p>
                            </td>
                            <td className="p-2 min-w-[100px]">
                                <p className="break-all line-clamp-1">
                                    {item.username}
                                </p>
                            </td>
                            <td className="p-2 min-w-[100px]">
                                {item.countIntroduced}
                            </td>
                            <td className="p-2">
                                {item.registerDate.split("T")[0]}
                            </td>
                            <td className="p-2">{item.date.split("T")[0]}</td>
                            <td className="p-2">{item.order}</td>
                            <td className="p-2">{item.total}</td>
                            <td className="p-2">
                                <Button
                                    onClick={() => handleOpenInfoProfile(item)}
                                    className="!rounded-[10px] cursor-pointer !text-white !bg-lime-400 hover:bg-lime-500 active:bg-lime-600 !text-sm !px-4 !py-2"
                                >
                                    詳細を見る
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Render the pagination controls here */}
            <div className="w-full  min-w-[900px] p-3 flex justify-around items-center bg-white !text-slate-400">
                <div className=" w-24">{`${
                    currentPage * selectedValue - (selectedValue - 1)
                }-${
                    currentPage * selectedValue > total
                        ? total
                        : currentPage * selectedValue
                } of ${total}`}</div>
                <div className="w-full justify-center flex gap-2">
                    <div>
                        <span className=" text-slate-400 hidden md:inline-block">
                            ページあたりの行数：
                        </span>
                        <select
                            className=" border-none"
                            value={selectedValue}
                            onChange={handleSelectChange}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                            disabled={currentPage === 1}
                            className="mr-2 max-[400px]:ml-2 ml-10"
                        >
                            <svg
                                width="14"
                                height="23"
                                viewBox="0 0 14 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 22L1 11.5L13 1"
                                    stroke="#868FA0"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                            {currentPage} /{" "}
                            {Math.ceil((total || data?.length) / selectedValue)}
                        </span>
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={
                                currentPage ===
                                    Math.ceil(
                                        (total || data?.length) / selectedValue
                                    ) ||
                                !data ||
                                data?.length === 0
                            }
                            className="mx-2"
                        >
                            <svg
                                width="14"
                                height="23"
                                viewBox="0 0 14 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L13 11.5L1 22"
                                    stroke="#868FA0"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={openModalEditUser}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className=" w-full p-2 overflow-auto"
            >
                <Box sx={{ ...style }} className=" w-1/2 rounded-xl !p-10">
                    <div className="w-full flex justify-end gap-10">
                        <Button
                            className=" !bg-[#FF6A68] rounded-xl !text-white !font-bold !w-52 !h-16"
                            onClick={handleCloseModalEditUser}
                        >
                            戻る
                        </Button>
                    </div>
                    <div className="font-light">
                        <label className=" p-5 font-bold text-black">
                            氏名
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.fullName || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className=" p-5 font-bold text-black">
                            ユーザーID
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.username || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className=" p-5 font-bold text-black">
                            紹介した人数
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.countIntroduced || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            新規登録日時
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={new Date(info?.registerDate || "")}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            購入日時
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={new Date(info?.date || "")}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            購入した製品/個数
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.order || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            合計代金
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.total || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default OrderListTable;
