import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TECarousel, TECarouselItem } from "tw-elements-react";
import { Apis } from "../../api";

import Table from "../../components/ItemTable";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useAuthContext } from "../../auth/context.jsx";

export default function ShopList() {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [sliderImages, setSliderImages] = useState([]);
    const [carouselItems, setCarouselItems] = useState(<></>);
    const [numberCartItem, setNumberCartItem] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        GetSliderImage();
        // GetProducts();
        if (user) {
            getNumberCartItem();
        }
    }, []);
    useEffect(() => {
        GetProducts(page, perPage);
    }, [page, perPage]);

    //商品の取得
    const GetProducts = async (page, perPage) => {
        const res = await Apis.myGet(
            `shop/productList?page=${page}&per_page=${perPage}`
        );
        if (res && res.success && res.data.items.length != 0) {
            let forSetData = [];
            res.data.items.map((p, index) => {
                let forSetDic = {
                    id: p.id,
                    cardName: p.title,
                    image: p.image_urls[0],
                    price: p.price,
                    stock: p.stock,
                    variant: p.stock,
                    code: p.code,
                };
                forSetData.push(forSetDic);
            });
            setData(forSetData);
            setTotal(res.data.total);
        }
    };

    const getNumberCartItem = async () => {
        try {
            const response = await Apis.myGet("account/cart");
            if (response && response.success) {
                setNumberCartItem(response.data.data.length);
                return;
            }
        } catch (error) {
            console.error(error);
        }
    };

    //スライダー画像の取得
    const GetSliderImage = async () => {
        const res = await Apis.myGet(`settingImage/settingImage?imageUsage=3`);
        if (res && res.success && res.data.items.length != 0) {
            setSliderImages(res.data.items);
        }
    };

    useEffect(() => {
        if (sliderImages.length !== 0) {
            const newCarouselItems = (
                <TECarousel showControls showIndicators ride="carousel">
                    <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                        {sliderImages.map((p, index) => (
                            <TECarouselItem
                                key={index}
                                itemID={index + 1}
                                className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none slide-container"
                            >
                                <a href={p.link}>
                                    {p.image_url.endsWith(".mp4") ? (
                                        <video
                                            className="slide-content"
                                            src={p.image_surl}
                                            autoPlay
                                            muted
                                            loop
                                        />
                                    ) : (
                                        <img
                                            className="block slide-content"
                                            src={p.image_url}
                                            alt="..."
                                        />
                                    )}
                                </a>
                            </TECarouselItem>
                        ))}
                    </div>
                </TECarousel>
            );
            setCarouselItems(newCarouselItems);
        } else {
            setCarouselItems([]);
        }
    }, [sliderImages]);

    const handleProcessContract = () => {
        navigate("/d-shop/contract/1");
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <Link to="/d-dashboard">
                    <img
                        alt="logo"
                        src="/image/logo.png"
                        width="150"
                        className="pt-3 pl-5"
                    />
                </Link>
                <div>
                    <Button
                        className="rounded-full"
                        onClick={handleProcessContract}
                    >
                        <Badge badgeContent={numberCartItem} color="warning">
                            <ShoppingCartOutlined sx={{ color: "#977AFF" }} />
                        </Badge>
                    </Button>
                </div>
            </div>
            <div className="mt-2">{carouselItems}</div>
            <div className="w-full overflow-x-auto">
                <Table
                    data={data}
                    total={total}
                    currentPage={page}
                    selectedValue={perPage}
                    setCurrentPage={setPage}
                    setSelectedValue={setPerPage}
                />
            </div>
        </div>
    );
}
