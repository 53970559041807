import React, { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AutoEmailList from "./AutoEmailList";
import AskList from "./AskList";
import OtherEmailList from "./OtherEmailList";
import { Apis } from "../../../api";
import { useSearchParams } from "react-router-dom";

export default function EmailSetting() {
    const [params] = useSearchParams();
    const [tab, setTab] = useState("1");
    const [noReplyAskNumber, setNoReplyAskNumber] = useState(0);

    useEffect(() => {
        loadAsks();
        SetTabValue();
    }, []);

    const loadAsks = async () => {
        const res = await Apis.myGet(`ask/ask?isReply=0`);
        if (res && res.success) {
            setNoReplyAskNumber(res.data.items.length);
        }
    }

    const SetTabValue = async () => {
        const tabValue = params.get('tab');
        if (tabValue) {
            setTab(tabValue)
        }
    }

    const askHeader = (
        <div className="flex items-center">
            <label>問い合わせ</label>
            {noReplyAskNumber !== 0 && (
                <span className="text-xs bg-red-500 rounded-full w-4 h-4 text-center mb-4">{noReplyAskNumber}</span>
            )}
        </div>
    );

    return (
        <div className='px-10 mb-3'>
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value) => setTab(value)}>
                        <Tab label="自動メール設定" value="1" className="w-1/2" />
                        <Tab label="その他メール設定" value="2" className="w-1/2" />
                        <Tab label={askHeader} value="3" className="w-1/2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <AutoEmailList />
                </TabPanel>
                <TabPanel value="2">
                    <OtherEmailList />
                </TabPanel>
                <TabPanel value="3">
                    <AskList />
                </TabPanel>
            </TabContext>
        </div>

    );
}