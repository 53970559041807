import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Apis } from "../../api";
import { AppContext } from "../../App";
import ChatAdditem from "../../components/ChatAdditem";
import Chatitem from "../../components/Chatitem";
import SearchChat from "../../components/SearchChat";
import { getDatafromDatabase } from "../../utilities/config";
import { useAuthContext } from "../../auth/context";

export default function Chatlist() {
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const tab = useMemo(() => param.get("tab"), [param]);
    const [reload, setReload] = useState(false);
    const { user, setShowLoginModal } = useAuthContext();

    // Array to hold navigation tab data
    const navTab = [
        { title: "承認者", id: 1 },
        { title: "申請者", id: 0 },
    ];
    const username = user ? user.username : "";
    // State variables
    const [isActive, setIsActive] = useState(1);
    const [selectedList, setSelectedList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [addChatList, setAddChatList] = useState([]);
    const [chatGroup, setChatGroup] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [isCreate, setIsCreate] = useState(false);
    const [idDeleted, setIdDeleted] = useState();
    const [countGroupHasMessageUnread, setCountGroupHasMessageUnread] =
        useState(0);
    const [keySearch, setKeySearch] = useState("");
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            if (tab) {
                const index = navTab.findIndex((item) => item.title === tab);
                if (index !== -1) {
                    const currentTab = navTab.find(
                        (item) => item.title === tab
                    );
                    setIsActive(currentTab.id);
                } else {
                    setIsActive(1);
                }
            } else {
                setIsActive(1);
            }

            isFirstRender.current = false;
            return;
        }
    }, [tab]);

    // begin Chat
    const startChat = (index) => {
        navigate(`/d-chat/channel?room=${chatGroup[index].room_name}`);
    };

    // Function to enable delete state

    useEffect(() => {
        if (navTab.some((item) => item.id === isActive)) {
            const newTab = navTab.find((item) => item.id === isActive);
            navigate(`/d-chat/list?tab=${newTab.title}`, {
                replace: true,
            });
        }
        setIsCreate(false);
    }, [isActive]);

    // Function to handle deletion
    const handleDelete = async (id) => {
        try {
            const roomNames = chatGroup
                .filter((_item, index) => {
                    return index === id;
                })
                .map((item) => item.room_name);

            const response = await Apis.myDelete(`chat/private/chatroom`, {
                room_names: roomNames,
            });
            if (response.success) {
                const res = await Apis.myGet(
                    "chat/private/chatroom?key_search=" + keySearch
                );
                if (res.success) {
                    const { data } = res;
                    for (const id in data) {
                        const newMember = data[id].member.filter(
                            (item) => item.username !== username
                        );
                        data[id] = { ...data[id], member: newMember };
                    }

                    setChatGroup(data);
                }
                setIsModalOpen(false);
                return;
            }
            throw new Error("データの削除中にエラーが発生しました");
        } catch (error) {
            console.error(error);
            toast.error("データの削除中にエラーが発生しました");
        }
    };

    const handleShowModalDelete = (id) => {
        const roomNames = chatGroup.filter((item, index) => index === id);

        if (
            roomNames.some(
                (item) =>
                    item.member.length > 2 && item.creator.url_name !== username
            )
        ) {
            toast.error("このグループ チャットを削除する権限がありません");
            return;
        }
        setIdDeleted(id);
        setIsModalOpen(true);
    };

    // Function to handle creation Group
    const handleCreate = async () => {
        try {
            let memberList = [];
            for (const i of selectedList) {
                const names = chatGroup[i].member.map((item) => item.username);
                memberList = memberList.concat(names);
            }
            memberList = memberList.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            if (memberList.length < 2)
                toast.error("メンバーは2より大きくなければなりません");
            else {
                let res = await Apis.myPut("chat/private/chatroom", {
                    new_room: "True",
                    chat_group: groupName,
                    member: memberList,
                });
                if (res.success) {
                    toast.success("グループが作成されました");
                    let appliedData = await Apis.myGet("chat/private/chatroom");
                    if (appliedData.data) {
                        const { data } = appliedData;
                        for (const id in data) {
                            const newMember = data[id].member.filter(
                                (item) => item.username !== username
                            );
                            data[id] = {
                                ...data[id],
                                member: newMember,
                            };
                        }
                        setChatGroup(data);
                    }
                    setIsNewGroupModalOpen(false);
                    setIsCreate(false);
                    setSelectedList([]);
                    setGroupName("");
                    return;
                }
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message);
        }
    };

    // Function to handle new group creation
    const handleNewGroup = () => {
        setIsNewGroupModalOpen(true);
    };

    // Function to handle selection of an item
    const handleSelect = (id) => {
        if (!selectedList.includes(id)) {
            const list = [...selectedList];
            list.push(id);
            setSelectedList(list);
        } else {
            const list = selectedList.filter((index) => index !== id);
            setSelectedList(list);
        }
    };

    const handleSearchChat = (search) => {
        setKeySearch(search);
    };

    const formatLastMessageBytype = (message, type) => {
        if (type === "text") {
            return message;
        }
        if (type.includes("image/")) {
            return "画像";
        }
        if (type.includes("application/")) {
            return "ファイル";
        }
        if (type.includes("video/")) {
            return "動画";
        }
        if (type.includes("audio/")) {
            return "音声";
        }
        return message;
    };

    // get Data
    useEffect(() => {
        async function dispatch() {
            try {
                const chatGroupPromise = Apis.myGet(
                    `chat/private/chatroom?key_search=${keySearch}`
                );
                const incomeDataPromise = Apis.myGet(
                    `social/private/contactdata?is_incoming=True&key_search=${keySearch}`
                );
                const newDataPromise = getDatafromDatabase(
                    "addChat",
                    keySearch
                );

                const [response, incomeData, newData] = await Promise.all([
                    chatGroupPromise,
                    incomeDataPromise,
                    newDataPromise,
                ]);
                if (response.data) {
                    const { data } = response;
                    const chatRoom = [];
                    let countGroupUnread = 0;
                    for (const id in data) {
                        const newMember = data[id].member.filter(
                            (item) => item.username !== username
                        );
                        const countGroupMember = newMember.length;
                        if (
                            data[id].count_message_unread > 0 ||
                            data[id].is_new
                        )
                            countGroupUnread++;
                        chatRoom.push({
                            ...data[id],
                            member: newMember,
                            isGroup: countGroupMember > 1,
                        });
                    }
                    setCountGroupHasMessageUnread(countGroupUnread);
                    setChatGroup(chatRoom);
                }
                if (incomeData) {
                    const newIncomeData = incomeData.data.filter(
                        (item) => item.is_chat_available !== "True"
                    );
                    newIncomeData
                        ? setContactList(
                              newIncomeData.sort((a, b) =>
                                  dayjs(a.chat_request_datetime).isBefore(
                                      dayjs(b.chat_request_datetime)
                                  )
                                      ? -1
                                      : 1
                              )
                          )
                        : setContactList([]);
                }
                if (newData) {
                    const data = newData.filter(
                        (item) =>
                            item.is_chat_available !== "True" &&
                            item.is_incoming !== "True"
                    );

                    if (data) {
                        const uniqueData = data.reduce(
                            (accumulator, current) => {
                                if (
                                    !accumulator.some(
                                        (item) =>
                                            item.member_id === current.member_id
                                    )
                                ) {
                                    accumulator.push(current);
                                }
                                return accumulator;
                            },
                            []
                        );
                        setAddChatList(uniqueData);
                    }

                    // data && setAddChatList(data) && setAddChatList([]);
                }
            } catch (error) {
                console.error(error);
                toast.error("データ取得中にエラーが発生しました");
            }
        }

        if (!user) {
            setShowLoginModal(true);
            return;
        }

        dispatch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keySearch, isActive, reload, user]);

    const { previousPage } = useContext(AppContext);

    return (
        <div>
            <div className="header pt-8 pc:px-10 px-2">
                <div className="flex items-center mb-[70px]">
                    {previousPage /* window.history.length > 2 */ && (
                        <Link to={-1}>
                            <img
                                src="/image/turn-left.png"
                                alt="return"
                                className="absolute top-3 w-[40px] h-[60px] cursor-pointer py-2"
                            ></img>
                        </Link>
                    )}
                    <h1 className="mx-auto text-2xl font-bold">
                        チャットページ
                    </h1>
                </div>
                <SearchChat onSearch={handleSearchChat} />
            </div>
            <div className="w-full pc:w-[80%] px-2 pc:mx-auto relative">
                {/* {isDeleteState && (
                    <div
                        className="absolute right-5 top-24 trash rounded-md cursor-pointer hover:opacity-60 active:opacity-90"
                        onClick={handleShowModalDelete}
                    >
                        <img
                            width="24"
                            height="24"
                            alt="trash"
                            src="/image/codicon_trash.png"
                        />
                    </div>
                )} */}
                {
                    /* isDeleteState || */ isCreate && (
                        <div
                            className="absolute -right-5 top-24 close rounded-md cursor-pointer hover:opacity-60 active:opacity-90"
                            onClick={() => {
                                setIsCreate(false);
                            }}
                        >
                            <img
                                width="24"
                                height="24"
                                alt="close"
                                src="/image/close.png"
                            />
                        </div>
                    )
                }
                <div className="flex gap-x-2">
                    {isCreate &&
                        selectedList.map((item, index) => (
                            <img
                                key={index}
                                alt="selected user"
                                src={
                                    chatGroup[item].member.length > 1
                                        ? "/image/focus-group.png"
                                        : chatGroup[item].member[0].avatar ||
                                          "/image/user_default.png"
                                }
                                width={50}
                                height={50}
                                className="rounded-full h-[60px] w-[60px] object-contain"
                            />
                        ))}
                </div>
                <ul className="w-full flex justify-around overflow-x mb-5">
                    {navTab.map((item, index) => (
                        <li
                            key={index}
                            className={`relative text-xl mt-[50px] pb-2 w-28 text-center h-30 cursor-pointer ${
                                isActive === item.id
                                    ? "text-purple-500 border-b-2 border-purple-500"
                                    : "text-slate-600"
                            }`}
                            onClick={() => {
                                setIsActive(item.id);
                            }}
                        >
                            {item.title}
                            {item.id === 1 &&
                                countGroupHasMessageUnread > 0 && (
                                    <div className="absolute top-2 right-0 p-[2px] h-6 w-6 rounded-full flex justify-center items-center bg-danger">
                                        <p className="text-white text-xs">
                                            {countGroupHasMessageUnread}
                                        </p>
                                    </div>
                                )}
                            {item.id === 0 && contactList.length > 0 && (
                                <div className="absolute top-2 right-0 p-[2px] h-6 w-6 rounded-full flex justify-center items-center bg-danger">
                                    <p className="text-white text-xs">
                                        {contactList.length}
                                    </p>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
                <ul className="md:p-6">
                    {!isActive ? (
                        <>
                            {contactList.length > 0 &&
                                contactList.map((person, index) => (
                                    <Chatitem
                                        key={index}
                                        url={person.member_avatar}
                                        username={person.member}
                                        person={person}
                                        content=""
                                        is_online={person.is_online}
                                        date={person.date}
                                        id={index}
                                        tab={isActive}
                                        setReload={() =>
                                            setReload((prevState) => !prevState)
                                        }
                                    />
                                ))}
                            {addChatList.length > 0 &&
                                addChatList.map((person, index) => (
                                    <ChatAdditem
                                        key={index}
                                        url={person.member_avatar}
                                        username={person.member}
                                        content={person}
                                        is_online=""
                                        full_name={
                                            Boolean(person.member_account_name)
                                                ? person.member_account_name
                                                : person.member
                                        }
                                        date=""
                                        isAdd={true}
                                        id={index}
                                    />
                                ))}
                        </>
                    ) : (
                        chatGroup.map((person, index) => {
                            const formatLastMessage = JSON.parse(
                                person.last_message
                            );

                            const memberWithCondition = person.member.find(
                                (item) => item.username !== username
                            );

                            const fullName = Boolean(
                                memberWithCondition?.full_name
                            )
                                ? memberWithCondition?.full_name
                                : memberWithCondition?.username;
                            return (
                                <Chatitem
                                    key={index}
                                    url={
                                        person.member.length > 1
                                            ? "/image/focus-group.png"
                                            : person.member[0]?.avatar
                                    }
                                    username={
                                        person.member.length > 1
                                            ? person.chat_group
                                            : fullName
                                    }
                                    content={
                                        formatLastMessage
                                            ? `${
                                                  formatLastMessage.sender ===
                                                  username
                                                      ? "あなた"
                                                      : formatLastMessage.sender
                                              }:${formatLastMessageBytype(
                                                  formatLastMessage.message,
                                                  formatLastMessage.type
                                              )}`
                                            : ""
                                    }
                                    is_online={person.member.some(
                                        (item) => item.is_online
                                    )}
                                    date={
                                        formatLastMessage
                                            ? `${dayjs(
                                                  formatLastMessage.created_at
                                              ).format("YYYY/MM/DD")}`
                                            : ""
                                    }
                                    id={index}
                                    tab={isActive}
                                    messageUnread={person.count_message_unread}
                                    isNew={person.is_new}
                                    isSelectedState={handleSelect}
                                    isCreateShow={isCreate}
                                    onClick={(e) => startChat(e)}
                                    memberCount={person.member.length}
                                    data={person}
                                    onShowModalDelete={handleShowModalDelete}
                                />
                            );
                        })
                    )}
                </ul>
            </div>
            {isActive === 1 && (
                <div
                    className="fixed bottom-20 md:bottom-10 left-[80%] md:left-[65%] bg-orange-400 p-4 rounded-full cursor-pointer hover:bg-orange-500 active:bg-orange-600"
                    onClick={handleNewGroup}
                >
                    <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            y1="17.5"
                            x2="35"
                            y2="17.5"
                            stroke="white"
                            strokeWidth="2"
                        />
                        <line
                            x1="17.5"
                            x2="17.5"
                            y2="35"
                            stroke="white"
                            strokeWidth="2"
                        />
                    </svg>
                </div>
            )}
            {isModalOpen && (
                <>
                    <div
                        className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50  pc:w-[500px] w-[96%] h-[250px] mobile:h-[150px] p-2 md:p-6 bg-white rounded-lg"
                        style={{ border: "solid 3px black" }}
                    >
                        <div className="pc:hidden">
                            <h2 className="text-zinc-600 text-center font-bold pc:text-[24px] mt-2 mobile:text-base">
                                チャット内容が削除されますがよろしいですか？
                            </h2>
                            <p className="text-zinc-600 text-center font-bold text-[24px] mt-2 mobile:text-base">
                                復旧はできません。
                            </p>
                        </div>

                        <h2 className="mobile:hidden text-zinc-600 text-center font-bold text-[24px] mobile:text-base mt-2">
                            チャット内容が削除されますがよろしいですか？復旧はできません。
                        </h2>

                        <div className="flex justify-between items-center mobile:mt-5 mt-[50px] font-light px-6">
                            <button
                                className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                onClick={() => handleDelete(idDeleted)}
                            >
                                はい
                            </button>
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                            >
                                いいえ
                            </button>
                        </div>
                    </div>
                    <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30"></div>
                </>
            )}
            {isNewGroupModalOpen && (
                <>
                    <div
                        className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 pc:w-[500px] w-[90%] h-[250px] p-6 bg-white rounded-lg mobile:px-4"
                        style={{ border: "solid 3px black" }}
                    >
                        <input
                            type="text"
                            placeholder="グループ名を入力"
                            className="border rounded-md mt-5 text-lg px-2 py-3 mb-3 block w-full"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                        <div className="flex justify-between mobile:mt-[20px] mt-[70px] font-light px-6">
                            <button
                                className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                onClick={() => {
                                    setIsCreate(true);
                                    setIsNewGroupModalOpen(false);
                                }}
                            >
                                はい
                            </button>
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsNewGroupModalOpen(false);
                                    setGroupName("");
                                }}
                            >
                                いいえ
                            </button>
                        </div>
                    </div>
                    <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30"></div>
                </>
            )}
            {isCreate && (
                <div
                    className="fixed right-1/2 bottom-20 translate-x-1/2 px-7 py-3 bg-red-400 button inline-block z-4 hover:bg-red-500 active:bg-red-600 text-white"
                    onClick={handleCreate}
                >
                    完成
                </div>
            )}
        </div>
    );
}
