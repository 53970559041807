import axios from "axios";
import { getAccessToken } from "../auth";

// export const myConfig = { apiUrl: 'https://ik1-129-71227.vs.sakura.ne.jp/api' };
export const myConfig = {
    apiUrl:
        process.env.REACT_APP_API_URL ||
        "https://ik1-129-71227.vs.sakura.ne.jp/api",
};

export const websocketurl =
    process.env.REACT_APP_WEBSOCKET_URL || "ik1-129-71227.vs.sakura.ne.jp";
export const urlAPI = {
    login: `account/login`,
    register: `account/register/vcode`,
    register_activate: `account/register/validate`,
    accountUsers: `account/admin/users`,
    contactUsers: `account/admin/contact-users`,
    primaryAgency: `account/admin/agency-level-1`,
    secondaryAgency: `account/admin/agency-level-2`,
    agencyManagement: `account/user/agency-management`,
    contactUsersNoPaging: `account/admin/contact-users-not-paging`,
    contactAgency: `account/user/contact-user`,
    contactAgencyNoPaging: `account/user/contact-user-not-paging`,
    userDetail: `account/admin/users/detail`,
    sendMailUser: "account/admin/users/send-email",
    mailAdminHistory: "mail/admin/mail-history",
    agencyPaymentHistory: "agency/payment-history/me",
    agency: `agency/agency`,
    paymentHistory: "agency/payment-history",
    searchBank: "account/search-bank",
    searchBankBranch: "account/search-bank-branch",
    createUserBank: "account/create-user-bank",
    orders: "order/admin/order",
    ordersNotPaging: "order/admin/order-not-paging",
    chartContact: "social/chart-contact",
    userSearch: "account/admin/user-search",
    productList: "shop/admin/product-list",
    updateProduct: "shop/product",
    orderView: "order/order",
    dataViewStatistic: "social/private/views/statistic",
    dataSaveStatistic: "social/private/save/statistic",
    userPaymentHistory: "account/payment-history/me",
    retrieveOrderIdByPaymentIntent:"order/retrieve-order-id-by-payment-intent",
};
export class Apis {
    static setAuthToken(token) {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `token ${token}`;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }
    }
    //
    static async login(body) {
        try {
            delete axios.defaults.headers.common["Authorization"];
            const res = await axios.post(
                `${myConfig.apiUrl}/${urlAPI.login}`,
                body
            );
            if (res.status === 201 || res.status === 200) {
                return { success: true, data: res.data };
            }
            return { success: false, data: res.data };
        } catch (error) {
            console.error(error.message);
            throw new Error(error.response.data.error);
        }
    }
    static async myPost(url, body) {
        try {
            const res = await axios.post(`${myConfig.apiUrl}/${url}`, body);
            if (res.status === 201 || res.status === 200) {
                return { success: true, data: res.data };
            }
            return { success: false, data: res.data };
        } catch (error) {
            console.error(error.message);
            throw new Error(error.response.data.error);
        }
    }

    static async myPut(url, body) {
        try {
            const res = await axios.put(`${myConfig.apiUrl}/${url}`, body);
            if (res.status === 200 || res.status === 201) {
                return { success: true, data: res.data };
            }
            return { success: false, data: res.data };
        } catch (error) {
            console.error(error.message);
            throw new Error(error.response.data.error);
        }
    }

    static async myGet(url) {
        try {
            const res = await axios.get(`${myConfig.apiUrl}/${url}`);
            if (res.status === 200) {
                return { success: true, data: res.data };
            }
            return { success: false, data: res.data };
        } catch (error) {
            console.error(error.message);
        }
    }

    static async myDelete(url, body) {
        try {
            const res = await axios.delete(`${myConfig.apiUrl}/${url}`, {
                data: body,
            });
            if (res.status === 204) {
                return { success: true, data: res.data };
            }
            return { success: false, data: res.data };
        } catch (error) {
            console.error(error.message);
        }
    }

    static async me() {
        return this.myGet("account/me");
    }
}

const accessToken = getAccessToken();
if (accessToken) {
    // axios set bearer token
    Apis.setAuthToken(accessToken);
}
