export default function PersonDescription({ person }) {
    const { member_company, member_position } = person;

    const hasPrimaryInfo = member_company || member_position;

    return (
        <>
            {hasPrimaryInfo ? (
                <div className="flex flex-wrap text-sm text-slate-500 gap-2">
                    {hasPrimaryInfo && (
                        <>
                            {member_company && <p>{member_company}</p>}
                            {member_company && member_position && <span> | </span>}
                            {member_position && <p>{member_position}</p>}
                        </>
                    )}
                </div>
            ) : null}
        </>
    );
}
