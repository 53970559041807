import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth/context";
import { useEffect } from "react";
import { myConfig } from "../api";
import { toast } from "react-toastify";

export default function Line() {
    const navigate = useNavigate();
    const { setLoading, setUserInfo, liff } = useAuthContext();
    const [params] = useSearchParams();
    const code = params.get("code");
    const redirectUrl = localStorage.getItem("redirectUrl");

    useEffect(() => {
        const handleLogin = async (accessToken, code, idToken) => {
            try {
                setLoading(true);
                const response = await fetch(
                    `${myConfig.apiUrl}/account/line`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            access_token: accessToken,
                            code,
                            id_token: idToken,
                            redirect_uri: `https://degime.net/d-line`,
                        }),
                    }
                );
                const data = await response.json();
                if (response.ok) {
                    setUserInfo(data);
                    toast.success(`ログインに成功しました`);
                    navigate(redirectUrl ?? "/d-dashboard", {
                        state: { login: true },
                    });
                    return;
                }
                throw new Error(data.error);
            } catch (error) {
                console.error(error);
                toast.error(
                    error.message || "ログイン中にエラーが発生しました"
                );
                navigate(redirectUrl ?? "/d-dashboard", {
                    state: { login: false },
                });
            } finally {
                setLoading(false);
            }
        };
        const timeoutId = setTimeout(() => {
            if (!liff) {
                toast.error("LIFFの初期化に失敗しました");
                navigate(redirectUrl ?? "/d-dashboard", {
                    state: { login: false },
                });
            }
        }, 10000);

        const checkLiffAndLogin = () => {
            if (liff) {
                clearTimeout(timeoutId);

                if (!liff.isLoggedIn()) {
                    navigate(redirectUrl ?? "/d-dashboard", {
                        state: { login: false },
                    });
                    return;
                }

                const accessToken = liff.getAccessToken();
                const idToken = liff.getIDToken();
                handleLogin(accessToken, code, idToken);
            }
        };

        checkLiffAndLogin();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [liff]);
    return <></>;
}
