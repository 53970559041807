import Croppie from 'croppie';
import 'croppie/croppie.css';
import React, { useEffect, useRef, useState } from 'react';

const CropImage = ({ imageSrc, onCrop, onClose }) => {
    const croppieRef = useRef(null);
    const croppieInstanceRef = useRef(null);
    const [zoomValue, setZoomValue] = useState(1);

    useEffect(() => {
        if (croppieRef.current) {
            if (croppieInstanceRef?.current) {
                croppieInstanceRef.current.destroy();
            }

            const size = window.innerWidth <= 500 ? window.innerWidth * 0.7 : 500

            croppieInstanceRef.current = new Croppie(croppieRef.current, {
                viewport: { width: size, height: size, type: 'circle' },
                boundary: { width: size, height: size },
                enforceBoundary: false,
            });
            if (imageSrc && imageSrc !== '')
                croppieInstanceRef?.current?.bind({
                    url: imageSrc,
                }).catch(error => {
                    console.error(error)
                });;
        }

        return () => {
            if (croppieInstanceRef.current) {
                croppieInstanceRef.current.destroy();
                croppieInstanceRef.current = null;
            }
        };
    }, [imageSrc]);

    useEffect(() => {
        if (zoomValue < 0) setZoomValue(0)
        croppieInstanceRef.current?.setZoom(zoomValue);
    }, [zoomValue])

    const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const buffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(buffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([buffer], { type: mimeString });
    };

    const handleCrop = () => {
        if (croppieInstanceRef.current) {
            croppieInstanceRef.current.result({ type: 'base64', size: 'viewport' }).then((croppedImage) => {
                const blob = base64ToBlob(croppedImage);
                onCrop(blob);
            });
        }
        onClose();
    };

    return (
        <div>
            <div className=" relative mb-4">
                <div ref={croppieRef} className=' !bg-white rounded-lg' />
                <button
                    className=" absolute left-5 bottom-1 font-bold rounded-full border-none shadow-none !align-text-top text-2xl text-gray-400 hover:text-primary-600"
                    onClick={() => setZoomValue(zoomValue - 0.1)}
                >-</button>
                <button className=" absolute right-5 bottom-1 font-bold rounded-full border-none shadow-none !align-text-top text-xl text-gray-400 hover:text-primary-600"
                    onClick={() => setZoomValue(zoomValue + 0.1)}
                >+</button>
            </div>
            <div className=' flex justify-end gap-2'>
                <button onClick={onClose} className=' bg-white px-4 py-2 rounded-lg cursor-pointer text-black'>キャンセル</button>
                <button onClick={handleCrop} className='bg-[#FF8F61] hover:bg-orange-500 px-4 py-2 rounded-lg cursor-pointer text-white'>保存</button>
            </div>
        </div>
    );
};

export default CropImage;
