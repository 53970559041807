import React, { useEffect, useState } from "react";
// import { toast } from 'react-toastify'

import { Apis } from "../../api";
import Contactitem from "../../components/Contactitem";
export default function BlockList() {
    const [blockList, setBlockList] = useState([]);
    const getDatafromDatabase = async () => {
        try {
            const response = await Apis.myGet(
                `social/private/contactdata?block_setting=Block`
            );
            const uniqueData = response.data.reduce((accumulator, current) => {
                if (
                    !accumulator.some(
                        (item) => item.member_id === current.member_id
                    )
                ) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
            setBlockList(uniqueData);

            return uniqueData;
        } catch (error) {
            setBlockList([]);
            console.error(error.message);
        }
    };

    useEffect(() => {
        getDatafromDatabase();
    }, []);

    return (
        <>
            <h1 className="text-xl text-slate-600 font-extrabold h-30 mb-5">
                ブロック一覧
            </h1>
            <ul className="divide-y divide-sky-700">
                {blockList.map((person, index) => (
                    <Contactitem
                        person={person}
                        onReRender={getDatafromDatabase}
                        key={index}
                        url={person.member_avatar || "/image/user_default.png"}
                        username={person.member}
                        email={person.member_email}
                        is_new={person.is_new}
                    />
                ))}
            </ul>
        </>
    );
}
