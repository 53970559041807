import { Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import Tree from "react-d3-tree";
import ModalHistoryPayment from "./Modal/ModalHistoryPayment";

const RenderRectSvgNode = ({ nodeDatum, toggleNode, onClick }) => {
    return (
        <>
            <rect width="20" height="20" x="-10" onClick={toggleNode} />
            <foreignObject
                x={nodeDatum.attributes?.parent ? -200 : -125}
                y="-120"
                width={nodeDatum.attributes?.parent ? "400" : `250`}
                height={nodeDatum.attributes?.parent ? "260" : "200"}
                className=""
            >
                <div className=" flex flex-col w-full h-fit p-2 border-[1px] border-black rounded-lg bg-white font-medium">
                    <div className=" flex justify-center mt-2 items-center aspect-video h-24">
                        <img
                            src={
                                nodeDatum.attributes?.avatar ||
                                "../image/no-avatar.png"
                            }
                            alt="avatar"
                            className="max-w-24 max-h-28"
                        />
                    </div>
                    <div className=" flex items-center justify-center pt-2 gap-1">
                        <p className="text-center !text-2xl font-medium break-all line-clamp-1">
                            {nodeDatum?.name}
                        </p>
                        {nodeDatum.attributes.agency &&
                            !nodeDatum.attributes?.parent &&
                            !nodeDatum.attributes.agency.is_active && (
                                <span className="text-red-500">(Deleted)</span>
                            )}
                    </div>
                    {nodeDatum.attributes?.parent ? (
                        <>
                            {nodeDatum.attributes.agency && (
                                <div className="p-2 px-4 text-xl text-center font-medium">
                                    {nodeDatum.attributes.amount}円
                                </div>
                            )}
                            <div className="px-4 text-xl text-center font-medium">
                                {nodeDatum.attributes.total ?? 0}円
                                <Button
                                    className="ml-2"
                                    onClick={() => {
                                        onClick(
                                            nodeDatum.attributes.id,
                                            nodeDatum.attributes?.parent
                                        );
                                    }}
                                >
                                    購入履歴
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="px-4 text-xl text-center font-medium">
                                {nodeDatum.attributes.amount ?? 0}円
                                <Button
                                    className="ml-2"
                                    onClick={() => {
                                        onClick(
                                            nodeDatum.attributes.id,
                                            nodeDatum.attributes?.parent
                                        );
                                    }}
                                >
                                    購入履歴
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </foreignObject>
        </>
    );
};

export const useCenteredTree = () => {
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: height / 6 });
        }
    }, []);
    return [translate, containerRef];
};

export default function ChartTreeOder({ data, zoom, startDate, endDate }) {
    const [translate, containerRef] = useCenteredTree();
    const [isOpen, setIsOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [isRootMember, setIsRootMember] = useState(false);

    const handleClickShowHistory = (id, isRootMember) => {
        setUserId(id);
        setIsOpen(true);
        setIsRootMember(isRootMember);
    };
    return (
        <div
            id="treeWrapper"
            className="h-full flex justify-center items-center p-4"
            ref={containerRef}
        >
            <Tree
                centeringTransitionDuration={true}
                orientation={"vertical"}
                pathFunc={"step"}
                data={data}
                renderCustomNodeElement={({ nodeDatum, toggleNode }) => (
                    <RenderRectSvgNode
                        nodeDatum={nodeDatum}
                        toggleNode={toggleNode}
                        onClick={handleClickShowHistory}
                    />
                )}
                translate={translate}
                zoom={zoom}
                nodeSize={{ x: 300, y: 350 }}
            />
            <ModalHistoryPayment
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                userId={userId}
                startDate={startDate}
                endDate={endDate}
                isRootMember={isRootMember}
            />
        </div>
    );
}
