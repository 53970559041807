import { Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { emailRegex, phoneRegex } from "../const/common";
import { getLocaltionByPostCode } from "../utilities/helper";
import ColorBgPicker from "./ColorBgPicker";
// Define the draggable item component
const DraggableItem = ({
    id,
    content,
    onChangeData,
    fontColor,
    value,
    mail,
}) => {
    const [inputValue, setInputValue] = useState(value || "");
    const isFirstRender = useRef(true);

    const setValue = (e) => {
        e.preventDefault();
        onChangeData({ id: id, value: e.target.value });
        setInputValue(e.target.value);
    };

    useEffect(() => {
        if (isFirstRender.current && value) {
            setInputValue(value || "");
            isFirstRender.current = false;
        }
    }, [value]);

    const isPublishedEmail = (email) => {
        return emailRegex.test(email) && email === mail;
    };
    const handleGenerateLocation = async () => {
        const location = await getLocaltionByPostCode(inputValue);
        if (!location) {
            toast.error("郵便番号情報を取得できません");
            return;
        }
        document.getElementById(
            "address"
        ).value = `${location.province}${location.city}${location.town}`;

        onChangeData({
            id: "address",
            value: `${location.province}${location.city}${location.town}`,
        });
    };

    const ButtonClearData = () => {
        return (
            <div
                className="absolute top-4 right-4 cursor-pointer"
                onClick={() => {
                    setInputValue("");
                    onChangeData({ id: id, value: "" });
                }}
            >
                <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.91498 15.4949L9.03117 11.3182L4.7256 7.07504L7.01035 4.75666L11.3159 8.99978L15.4321 4.823L17.8609 7.21656L13.7447 11.3933L18.0503 15.6365L15.7655 17.9548L11.46 13.7117L7.34376 17.8885L4.91498 15.4949Z"
                        fill="black"
                        fillOpacity="0.3"
                    />
                </svg>
            </div>
        );
    };

    return (
        <div className="relative">
            {id === "mobilePhoneNumber" &&
            phoneRegex.test(inputValue) &&
            !onChangeData ? (
                <>
                    <div
                        className="cursor-pointer bg-white rounded-md w-[96%]"
                        style={{
                            margin: "8px",
                            border: "1px solid #ccc",
                            padding: "8px",
                            color: fontColor,
                        }}
                    >
                        <a href={`tel:${inputValue}`}>携帯電話番号</a>
                    </div>
                    <ButtonClearData />
                </>
            ) : id === "mailAddress" &&
              isPublishedEmail(inputValue) &&
              !onChangeData ? (
                <>
                    <div
                        className="cursor-pointer bg-white rounded-md w-[96%]"
                        style={{
                            margin: "8px",
                            border: "1px solid #ccc",
                            padding: "8px",
                            color: fontColor,
                        }}
                    >
                        <a href={`mailto:${inputValue}`}>メールアドレス</a>
                    </div>
                    <ButtonClearData />
                </>
            ) : id === "postCode" ? (
                <div className=" flex gap-2 items-center ml-2">
                    <div className="relative w-[60%] ">
                        <input
                            name={id}
                            value={inputValue}
                            id={id}
                            onChange={setValue}
                            className="bg-white rounded-md w-[calc(100%-8px)]"
                            style={{
                                marginTop: "8px",
                                marginBottom: "8px",
                                marginRight: "8px",
                                border: "1px solid #ccc",
                                padding: "8px",
                                color: fontColor,
                            }}
                            placeholder={content}
                        />
                        <ButtonClearData />
                    </div>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: "black", height: "40px" }}
                        onClick={handleGenerateLocation}
                    >
                        住所取得
                    </Button>
                </div>
            ) : (
                <>
                    <input
                        name={id}
                        value={inputValue}
                        id={id}
                        onChange={setValue}
                        className="bg-white rounded-md w-[96%]"
                        style={{
                            margin: "8px",
                            border: "1px solid #ccc",
                            padding: "8px",
                            color: fontColor,
                        }}
                        placeholder={content}
                    />
                    <ButtonClearData />
                </>
            )}
        </div>
    );
};

// Define the main component
const DraggableList = ({ onChangeData, publishData, mail }) => {
    const [fontColor, setFontColor] = useState(publishData.wordColor || "#000");
    const [isFontColor, setIsFontColor] = useState(true);
    const [openColorDialog, setOpenColorDialog] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#000");
    const [backgroundImage, setBackgroundImage] = useState("");

    const [valueState, setValueState] = useState({});

    useEffect(() => {
        setBackgroundColor(publishData.cardColor || "#fff");
        setBackgroundImage(publishData.cardURL ?? "");
        setValueState({
            company_url: publishData.company_url || "",
            companyName: publishData.companyName || "",
            position: publishData.position || "",
            phoneNumber: publishData.phoneNumber || "",
            mobilePhoneNumber: publishData.mobilePhoneNumber,
            postCode: publishData.postCode || "",
            mailAddress: publishData.mailAddress || "",
            address: publishData.address || "",
            cardColor: publishData.cardColor || "",
            cardURL: publishData.cardURL || "",
            wordColor: publishData.wordColor || "",
        });
    }, [publishData]);

    // Update the value state ---important hhh----
    const handleValueState = (data) => {
        setValueState((prevState) => ({
            ...prevState,
            [data.id]: data.value,
            cardColor: backgroundColor,
            cardURL: backgroundImage,
            wordColor: fontColor,
        }));
        onChangeData({
            ...valueState,
            [data.id]: data.value,
            cardColor: backgroundColor,
            cardURL: backgroundImage,
            wordColor: fontColor,
        });
    };

    // Your initial items
    const defaultItems = useCallback(() => {
        return [
            {
                id: "company_url",
                content: "会社URL",
                value: valueState?.company_url,
            },
            {
                id: "companyName",
                content: "会社名",
                value: valueState?.companyName,
            },
            {
                id: "position",
                content: "役職・部署・支店名など",
                value: valueState?.position,
            },
            {
                id: "phoneNumber",
                content: "電話番号",
                value: valueState?.phoneNumber,
            },
            {
                id: "mobilePhoneNumber",
                content: "携帯電話番号",
                value: valueState?.mobilePhoneNumber,
            },
            {
                id: "mailAddress",
                content: "メールアドレス",
                value: valueState?.mailAddress,
            },
            {
                id: "postCode",
                content: "郵便番号",
                value: valueState?.postCode,
            },
            { id: "address", content: "住所", value: valueState?.address },
        ];
    }, [valueState]);

    const [items, setItems] = useState(defaultItems);
    useEffect(() => {
        setItems(defaultItems);
    }, [defaultItems, publishData]);

    const handleColorChange = (color) => {
        if (!isFontColor) {
            setBackgroundColor(color.hex);
            onChangeData({ ...valueState, cardColor: color.hex });
            setValueState((prevState) => ({
                ...prevState,
                cardColor: color.hex,
            }));
        } else {
            setFontColor(color.hex);
            onChangeData({ ...valueState, wordColor: color.hex });
            setValueState((prevState) => ({
                ...prevState,
                wordColor: color.hex,
            }));
        }
    };

    const handleImageUpload = (uploaded_url) => {
        onChangeData({ ...valueState, cardURL: uploaded_url });
        setBackgroundImage(uploaded_url);
    };

    const handleRemoveBackgroundImage = () => {
        setBackgroundImage("");
        onChangeData({
            ...valueState,
            cardURL: "",
        });
    };

    return (
        <div>
            <ColorBgPicker
                isOpen={openColorDialog}
                onClose={() => setOpenColorDialog(false)}
                onChangeColor={handleColorChange}
                color={isFontColor ? fontColor : backgroundColor}
                enableBgImage={!isFontColor}
                backgroundImage={backgroundImage}
                onChangeImageUrl={handleImageUpload}
                onDeleteImageUpload={handleRemoveBackgroundImage}
                title={isFontColor ? "フォント色を選択" : "背景を選択"}
            >
                <div className="p-[10px] flex justify-end">
                    <div
                        onClick={() => {
                            setOpenColorDialog(true);
                            setIsFontColor(true);
                        }}
                        className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white px-5 py-1 rounded-lg"
                    >
                        文字色
                    </div>

                    <div
                        onClick={() => {
                            setOpenColorDialog(true);
                            setIsFontColor(false);
                        }}
                        className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white ml-3 px-5 py-1 rounded-lg"
                    >
                        背景
                    </div>
                </div>
            </ColorBgPicker>
            <form
                style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: backgroundColor,
                    backgroundImage:
                        backgroundImage !== "" ? `url(${backgroundImage})` : "",
                    backgroundSize: "cover",
                    backgroundPosition: "center top",
                    backgroundRepeat: "no-repeat",
                }}
                className="rounded-md shadow-lg p-4 mb-[30px]"
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
            >
                {items.map((item, index) => (
                    <DraggableItem
                        onChangeData={handleValueState}
                        key={item.id}
                        id={item.id}
                        content={item.content}
                        index={index}
                        fontColor={fontColor}
                        value={valueState?.[item.id]}
                        mail={mail}
                    />
                ))}
            </form>
        </div>
    );
};

export default DraggableList;
