import React, { useEffect, useState } from "react";
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
} from "tw-elements-react";

export default function Modal({
    onChangeName,
    isShow,
    setIsShow,
    type,
    folder,
}) {
    const [folderName, setFolderName] = useState("");
    const [alternate, setAlternate] = useState("create");

    const title =
        alternate === "create"
            ? "フォルダーを作成する"
            : "フォルダー名を変更・削除する";

    useEffect(() => {
        if (isShow) {
            if (!type || type === "create") {
                setAlternate("create");
                setFolderName("");
            } else {
                setAlternate(type);
                setFolderName(folder ?? "");
            }
        }
    }, [isShow, type, folder]);

    const handleClick = () => {
        if (folderName) {
            alternate === "create"
                ? onChangeName({ content: folderName, type: "create" })
                : onChangeName({ content: folderName, type: "modify" });
            alternate === "create" && setFolderName("");
            setIsShow(false);
        }
    };
    const handleDelete = () => {
        onChangeName({ content: "", type: "delete" });
        setIsShow(false);
    };

    const handleToggleType = () => {
        setAlternate(alternate === "create" ? "modify" : "create");
        setFolderName(alternate === "create" ? folder : "");
    };

    return (
        <div>
            {/* <!-- Modal --> */}
            <TEModal show={isShow} setShow={setIsShow} staticBackdrop>
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="ml-3 text-xl font-bold leading-normal text-neutral-800 dark:text-neutral-200">
                                {title}
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setIsShow(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>
                            <div className="flex flex-col items-center w-full">
                                <div className="flex items-center justify-center w-full">
                                    <input
                                        className="rounded-md py-2 px-5 border"
                                        value={folderName}
                                        onChange={(e) =>
                                            setFolderName(e.target.value)
                                        }
                                        placeholder="フォルダー名を入力"
                                    />

                                    <button
                                        type="button"
                                        className="w-[73px] ml-3 inline-block rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                        onClick={handleClick}
                                        aria-label="Close"
                                    >
                                        {alternate === "create"
                                            ? "登録"
                                            : "編集"}
                                    </button>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <span>
                                        {alternate === "create"
                                            ? "編集"
                                            : "登録"}
                                        は
                                        <span
                                            className="text-blue-600 hover:underline hover:cursor-pointer"
                                            onClick={handleToggleType}
                                        >
                                            こちら→
                                        </span>
                                    </span>
                                </div>
                                {alternate !== "create" && (
                                    <p className="px-10 text-center mt-4">
                                        削除するとフォルダー内のデータがすべて消去されますがよろしいですか？
                                    </p>
                                )}
                            </div>
                        </TEModalBody>
                        <TEModalFooter>
                            {alternate !== "create" && (
                                <div className="w-full flex justify-around">
                                    <button
                                        type="button"
                                        className="inline-block rounded bg-primary-accent-200 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                        onClick={() => setIsShow(false)}
                                        aria-label="Close"
                                    >
                                        いいえ
                                    </button>
                                    <TERipple>
                                        <button
                                            type="button"
                                            className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                            onClick={handleDelete}
                                            aria-label="Close"
                                        >
                                            削除する
                                        </button>
                                    </TERipple>
                                </div>
                            )}
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
            {/* </div> */}
        </div>
    );
}
