import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TERipple } from "tw-elements-react";
import { Apis } from "../api";
import PublicView from "./PublicView";
import PublicViewSocial from "./PublicViewSocial";
import { useAuthContext } from "../auth/context";
import { Box, Button, Modal } from "@mui/material";
import { CancelRounded } from "@mui/icons-material";

const CARD_TYPE = {
    BUSINESS: "BUSINESS",
    SNS: "SNS",
};

export default function PublicShow() {
    const hasMounted = useRef(false);
    const [isExist, setIsExist] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isSavedContact, setIsSavedContact] = useState(false);
    const contactGroupData = ["Business", "Private"];
    const [showData, setShowData] = useState({
        bgColor: "",
        bgURL: "",
        wordColor: "",
        cardColor: "",
        cardURL: "",
        url_name: "",
        faceImg: "",
        realName: "",
        company_url: "",
        companyName: "",
        position: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        isEnableContact: false,
        mailAddress: "",
        address: "",
        idCard: {
            idCard: [],
        },
        socialLink: {
            socialLink: [],
        },
        onlineCard_Data: {
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [],
            slideLink: [],
            spaceAdd: [],
        },
    });
    const { user, setShowLoginModal } = useAuthContext();

    const getData = async (showMessage = true) => {
        const current_url = window.location.href;
        const parts = current_url.split("/");
        const desiredPart = parts[parts.length - 1];
        try {
            const response = await Apis.myGet(
                `social/public/online?url_name=${encodeURIComponent(
                    desiredPart
                )}`
            );
            setIsExist(true);
            setIsOnline(true);
            if (response.data?.[0]) {
                setShowData(response.data[0]);

                return (
                    showMessage &&
                    toast.success(
                        `${response.data[0].user}のオンライン プロフィールが見つかりました!`
                    )
                );
            }

            throw Error("Public Business Profile is not found!");
        } catch (error) {
            try {
                const response = await Apis.myGet(
                    `social/public/snstree?url_name=${encodeURIComponent(
                        desiredPart
                    )}`
                );
                setIsExist(true);
                setIsOnline(false);
                if (response.data[0]) {
                    setShowData(response.data[0]);
                }
                showMessage &&
                    toast.success(
                        `${response.data[0].user}のソーシャル プロファイルを見つけました!`
                    );
            } catch (error) {
                console.error(error.message);
                showMessage && toast.error("ページが存在しません");
            }
        }
    };

    useEffect(() => {
        // Runs once when the component mounts or when 'user' changes
        if (!hasMounted.current) {
            getData();
            hasMounted.current = true;
        }
    }, []);

    const handleSave = async () => {
        setIsShowModal(true);
    };

    useEffect(() => {
        if (
            user &&
            localStorage.getItem("group_name") &&
            localStorage.getItem("member") &&
            (showData.url_name || showData.user)
        ) {
            handleContact(localStorage.getItem("group_name"));
            localStorage.removeItem("group_name");
            localStorage.removeItem("member");
        }
    }, [user, showData]);

    useEffect(() => {
        window.addEventListener("cancelLoginModal", () => {
            localStorage.removeItem("group_name");
            localStorage.removeItem("member");
        });
        // Cleanup event listener khi component unmount
        return () => {
            window.removeEventListener("cancelLoginModal", () => {
                localStorage.removeItem("group_name");
                localStorage.removeItem("member");
            });
        };
    }, []);

    const handleContact = async (id) => {
        if (!user) {
            localStorage.setItem("group_name", id);
            localStorage.setItem(
                "member",
                isExist && isOnline ? showData.url_name : showData.user
            );
            setShowLoginModal(true);
            setIsShowModal(false);
            return;
        }
        try {
            const response = await Apis.myPost("social/private/contactdata", {
                group_Name: id,
                member: isExist && isOnline ? showData.url_name : showData.user,
                contact_type: isOnline ? CARD_TYPE.BUSINESS : CARD_TYPE.SNS,
            });
            if (response.success) {
                toast.success("名刺交換が完了しました");
                setIsShowModal(false);
            } else {
                toast.error("保存に失敗しました!");
            }
            setIsSavedContact(true);
        } catch (error) {
            console.error(error);
        } finally {
            // window.location.reload();
            getData(false);
        }
    };
    const username = isExist && isOnline ? showData.url_name : showData.user;

    const getTitle = (data) => {
        switch (data) {
            case "Business":
                return "ビジネス";
            case "Private":
                return "プライベート";
            default:
                return data;
        }
    };

    return (
        <div className="bg-white flex flex-col items-center">
            {isExist && isOnline ? (
                <PublicView publishData={showData} username={username} />
            ) : (
                <PublicViewSocial publishData={showData} />
            )}
            <div
                style={{ backgroundColor: showData.bgColor ?? "#F3F4F6 " }}
                className="h-16 md:h-24 flex justify-center items-center  w-full"
            >
                <Link to={`/d-dashboard`}>
                    <div className="">
                        <img
                            className="mx-auto w-[100px] h-auto"
                            alt="logo"
                            src="/image/logo.png"
                        />
                    </div>
                </Link>
            </div>
            {!isSavedContact &&
                showData &&
                showData.isEnableContact &&
                user?.username !== username && (
                    <>
                        <div className="h-[90px]"></div>
                        <div
                            className={`z-[16] fixed pc:bottom-0 right-0 left-0 backdrop-blur-sm bg-slate-400/30 flex w-full justify-center py-[25px] bottom-[70px]`}
                        >
                            <TERipple>
                                <button
                                    className=" w-[160px] text-white inline-block py-2 text-center rounded-lg bg-orange-400 cursor-pointer border-none outline-none"
                                    onClick={handleSave}
                                >
                                    名刺交換する
                                </button>
                            </TERipple>
                        </div>
                    </>
                )}
            <Modal
                open={isShowModal}
                style={{ zIndex: 1000 }}
                onClose={() => setIsShowModal(false)}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        padding: "24px",
                        borderRadius: "8px",
                    }}
                >
                    <div className="absolute top-2 right-2">
                        <button onClick={() => setIsShowModal(false)}>
                            <CancelRounded color="disabled" />
                        </button>
                    </div>
                    <div className="font-bold text-xl whitespace-nowrap pt-4">
                        どちらに保存しますか？
                    </div>
                    <div className="grid grid-cols-2 gap-4 pt-5">
                        {contactGroupData.map((data) => {
                            return (
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#FFA726",
                                        whiteSpace: "nowrap",
                                    }}
                                    key={data}
                                    onClick={() => handleContact(data)}
                                >
                                    {getTitle(data)}
                                </Button>
                            );
                        })}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
