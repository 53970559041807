import {
    Button,
    Checkbox,
    IconButton,
    Input,
    InputAdornment,
} from "@mui/material";
import { useState } from "react";
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
    TEModalHeader,
    TEModalFooter,
} from "tw-elements-react";

import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DigmeCard from "../assets/images/digme_card.png";
import MailImg from "../assets/images/mail.png";
import { ReactComponent as RedArrow } from "../assets/icons/redArrow.svg";

export default function PublishCompleteModal({
    type,
    showPublishModal,
    setShowPublishModal,
    link,
}) {
    const [showCopied, setShowCopied] = useState(false);

    const publicUrl = `${window.location.origin}/${link}`;
    const [isNotShowAgain, setIsNotShowAgain] = useState(
        JSON.parse(
            localStorage.getItem(`${type}_PublishCompleteModalNotShowAgain`)
        )
    );

    const isShow = showPublishModal && !isNotShowAgain;

    const handleNotShowAgain = (e) => {
        localStorage.setItem(
            `${type}_PublishCompleteModalNotShowAgain`,
            e.target.checked
        );
        setIsNotShowAgain(e.target.checked);
    };

    const handleCopy = async () => {
        setShowCopied(true);
        await navigator.clipboard.writeText(publicUrl);
        setTimeout(() => {
            setShowCopied(false);
        }, 1500);
    };

    return (
        <TEModal show={isShow} setShow={setShowPublishModal}>
            <TEModalDialog className="!absolute top-[50%] left-[50%] translate-x-[-50%] !translate-y-[-50%] !w-[90%] md:!w-auto !max-w-screen-lg md:!max-w-screen-md">
                <TEModalContent className="w-full md:w-[700px]">
                    <TEModalHeader className="md:px-12 border-none">
                        <div className="w-full flex justify-center">
                            <h1 className="text-center text-base md:text-2xl md:p-3 text-[#4C31F4] font-semibold">
                                公開されました！
                            </h1>
                        </div>
                        <button
                            type="button"
                            className="absolute top-3 right-2 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setShowPublishModal(false)}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="grey"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </TEModalHeader>
                    {/* <!--Modal body--> */}
                    <TEModalBody className="md:px-12 py-0 md:py-4">
                        <div className="flex flex-col md:flex-row md:justify-between items-center md:mx-12">
                            {/* <Input
                                id="public-url"
                                type="text"
                                readOnly
                                value={publicUrl}
                                disableUnderline
                                multiline
                                className="block order-2 md:order-1 border rounded-full border-sky-500 md:!px-2 !px-4 w-full md:w-[75%]"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="click to copy"
                                            onClick={handleCopy}
                                        >
                                            {showCopied ? (
                                                <CheckIcon />
                                            ) : (
                                                <ContentCopyIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            /> */}
                            <div className="flex items-center justify-between order-2 md:order-1 border rounded-3xl border-sky-500 md:!px-2 !px-4 w-full md:w-[75%]">
                                <div className="w-[calc(100%-50px)] break-words">{publicUrl}</div>
                                <IconButton
                                    aria-label="click to copy"
                                    onClick={handleCopy}
                                >
                                    {showCopied ? (
                                        <CheckIcon />
                                    ) : (
                                        <ContentCopyIcon />
                                    )}
                                </IconButton>
                            </div>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={publicUrl}
                                className="block order-1 md:order-2 md:inline text-center mb-2 md:mb-auto"
                            >
                                <Button
                                    variant="contained"
                                    className="!bg-sky-500"
                                >
                                    プレビュー
                                </Button>
                            </a>
                        </div>
                        <div className="flex flex-col items-center md:flex-row md:justify-between md:items-start md:gap-16 mt-4 md:mt-9">
                            <img className="max-w-[265px]" alt="Degimeカード" src={DigmeCard} />
                            <div className="mt-[-160px] md:mt-0 md:px-5 md:w-[50%]">
                                <div className="font-bold text-lg mb-3 text-center md:text-start">
                                    Degimeカードにシフトしませんか？
                                </div>
                                <div className="text-[#906579] font-bold text-center md:text-start">
                                    今までの名刺からデジタル名刺へ
                                    <br />
                                    相手のスマホに近づけるだけで、あなたのプロフィールページを瞬時に表示することができます
                                </div>
                                <div className="flex justify-center mt-4 md:mt-auto">
                                    {/* <Link to="/d-shop"> */}
                                    <Button
                                        href="/d-shop/list"
                                        variant="contained"
                                        className="!bg-gray-400 border !rounded-xl text-bold"
                                    >
                                        すぐ購入する
                                    </Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="relative hidden md:flex justify-between items-start gap-16 mt-[-30px]">
                            <div className="flex flex-col items-center justify-center gap-2 w-[50%]">
                                <img alt="Mail" src={MailImg} />
                                <span className="font-bold text-[14px]">
                                    デジメ：{publicUrl}
                                    <br />
                                    ※デジメ登録をお願いします。
                                </span>
                            </div>
                            <RedArrow className="absolute left-[50%] translate-x-[-50%] top-[15%]" />
                            <div className="px-5 w-[50%]">
                                <div className="font-bold text-lg mb-3">
                                    メールへ表示させましょう！
                                </div>
                                <div className="text-[#906579] font-bold">
                                    メールにDegimeで作成したプロフィールページURLを表示して送信しましょう
                                </div>
                                <br />
                                <div className="font-bold">
                                    今までの名刺からデジタル名刺［Degime］へ
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                    <TEModalFooter className="!justify-start border-none">
                        <Checkbox
                            checked={isNotShowAgain}
                            onChange={handleNotShowAgain}
                        />
                        <span className="text-[12px]">
                            今後この画面を出さない
                        </span>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    );
}
