import React, { useEffect, useState } from "react";
import { Apis, urlAPI } from "../../api";
import UserListTable from "../../components/userlistTable";

export default function Userlist() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(100);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");

    const handelGetUsers = async () => {
        const listUser = (
            await Apis.myGet(
                `${urlAPI.accountUsers}?page=${page}&per_page=${per_page}&keySearch=${search}`
            )
        )?.data;
        setUsers(
            listUser?.data.map((item, index) => ({
                ...item,
                userId: item.id,
                id: listUser?.totalItem - ((page - 1) * per_page + index),
                ...(item.url_name && {
                    url: `${window.origin}/${item.url_name}`,
                }),
                registerDate: new Date(item.date_joined).toISOString(),
            }))
        );
        setTotal(listUser?.totalItem);
    };

    const handelChangePaging = (per_pageValue, pageValue) => {
        setPage(pageValue);
        setPer_page(per_pageValue);
    };

    const handleStorageChange = (event) => {
        setSearch(localStorage.getItem("search"));
    };

    useEffect(() => {
        window.addEventListener("localStorageChange", handleStorageChange);
        // Cleanup event listener khi component unmount
        return () => {
            window.removeEventListener(
                "localStorageChange",
                handleStorageChange
            );
            const event = new Event("localStorageClear");
            window.dispatchEvent(event);
        };
    }, []);

    useEffect(() => {
        handelGetUsers();
    }, [page, per_page, search]);

    useEffect(() => {
        setPage(1);
        handelGetUsers();
    }, [search]);

    return (
        <div className="px-10 h-full">
            <UserListTable
                data={users}
                changePaging={handelChangePaging}
                total={total}
                search={search}
                onGetUsers={handelGetUsers}
            />
        </div>
    );
}
