import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ColorBgPicker from "./ColorBgPicker";

function TextEditor({
    isClear,
    onChangeData,
    enableChangeBgColor = false,
    data,
}) {
    const [editorContent, setEditorContent] = useState("");
    const [bgColor, setBgColor] = useState("transparent");
    const [openColorDialog, setOpenColorDialog] = useState(false);
    const quillRef = useRef(null);

    useEffect(() => {
        if (isClear) {
            setEditorContent("");
            setBgColor("transparent");
        }
    }, [isClear]);

    useEffect(() => {
        if (data) {
            setEditorContent(data?.text ?? data);
            setBgColor(data?.bgColor ?? "transparent");
        }

        return () => {
            setEditorContent("");
            setBgColor("transparent");
        };
    }, [data]);

    const handleEditorChange = (_content, _delta, _source, editor) => {
        if (editor) {
            setEditorContent(editor.getContents());

            onChangeData({
                data: { text: editor.getContents(), bgColor },
                type: "text",
            });
        }
    };

    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            ["link"],
            [{ align: [] }],
            ["clean"],
        ],
    };

    const handleColorChange = (color) => {
        setBgColor(color.hex);

        onChangeData({
            data: { text: editorContent, bgColor: color.hex },
            type: "text",
        });
    };

    return (
        <div className="h-[350px]">
            {enableChangeBgColor && (
                <ColorBgPicker
                    isOpen={openColorDialog}
                    onClose={() => setOpenColorDialog(false)}
                    onChangeColor={handleColorChange}
                    color={bgColor}
                >
                    <div className="mb-[10px] flex items-center">
                        <button
                            onClick={() => setOpenColorDialog(true)}
                            className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white mr-3 px-5 py-1 rounded-lg"
                        >
                            背景
                        </button>
                        <div className="flex items-center">
                            選んだ背景色:{" "}
                            <div
                                style={{ backgroundColor: bgColor }}
                                className="ml-2 border-2 rounded-md border-black w-[30px] h-[30px]"
                            >
                                {" "}
                            </div>
                        </div>
                    </div>
                </ColorBgPicker>
            )}
            <ReactQuill
                className="h-[calc(100%-138px)]"
                value={editorContent}
                onChange={handleEditorChange}
                modules={modules}
                ref={quillRef}
            />
            <DisplayComponent editedData={editorContent} />
        </div>
    );
}

function DisplayComponent({ editedData }) {
    return <div></div>;
}

export default TextEditor;
