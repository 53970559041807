import { useDrag, useDrop } from "react-dnd";
import { formatCurrency } from "../utilities/helper";

export default function FolderItem({
    item,
    currentActive,
    /*  currentPage,
    itemsPerPage, */
    index,
    onMove,
    onClickTab,
    hasNotice,
    onSwitchFolder,
}) {
    const [{ isOver }, drop] = useDrop({
        accept: ["CONTACT_ITEM", "FOLDER_ITEM"],
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
        }),
        canDrop: (draggedItem) => draggedItem.type === "CONTACT_ITEM" || (!["Business", "Private"].includes(item.title) && !["Business", "Private"].includes(draggedItem.title)),
        drop(draggedItem, monitor) {
            if (draggedItem.type === "CONTACT_ITEM") {
                // Not moving the contact item
                if (draggedItem.folder === item.title) {
                    return;
                }

                onMove(draggedItem.username, item.title, draggedItem.contact_type);
                draggedItem.folder = item.title;
            }

            if (draggedItem.type === "FOLDER_ITEM") {
                // Not moving the folder item
                if (draggedItem.title === item.title) {
                    return;
                }

                onSwitchFolder(draggedItem, item);
                draggedItem.title = item.title;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "FOLDER_ITEM",
        item: {
            type: "FOLDER_ITEM",
            id: item.id,
            title: item.title,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !["Business", "Private"].includes(item.title),
    });

    const opacity = isDragging ? 0 : 1;

    const getTitle = () => {
        switch (item.title) {
            case "Business":
                return "ビジネス";
            case "Private":
                return "プライベート";
            default:
                return item.title;
        }
    };

    return (
        <li
            key={index}
            style={{
                backgroundColor: isOver ? "#4078C0" : "transparent",
                opacity,
            }}
            className={`relative p-1 pr-4 text-lg mt-3 text-center font-bold cursor-pointer ${
                currentActive === index /*  + currentPage * itemsPerPage */
                    ? "text-blue-700 border-b-2 border-blue-700"
                    : "text-slate-600"
            } ${isOver ? "rounded-lg text-white" : ""}`}
            onClick={() => {
                onClickTab(index /* + currentPage * itemsPerPage */);
            }}
            ref={(node) => {
                drop(node);
                drag(node);
            }}
        >
            <div className="h-[30px] text-ellipsis overflow-hidden whitespace-nowrap">
                {getTitle()}
            </div>
            <p className="absolute right-0 top-10 text-zinc-800 text-sm w-full">{`(${formatCurrency(
                item.count
            )})`}</p>
            {hasNotice && (
                <div className="absolute top-1 right-0 h-3 w-3 bg-red-400 rounded-full" />
            )}
        </li>
    );
}
