import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TEChart } from "tw-elements-react";

import { a11yProps, CustomTabPanel } from "../../components/TabPanel";
import { Apis, urlAPI } from "../../api";
import dayjs from "dayjs";

function ChartLine({
    data,
    label,
    isOneLine = false,
    showLineOne = false,
    showLineTwo = false,
}) {
    const chartData = [];
    if (showLineOne) {
        chartData.push({
            label: "訪問者数",
            data: data[0],
        });
    }
    if (showLineTwo) {
        chartData.push({
            label: "交換者数",
            borderColor: "orange",
            data: data[1],
        });
    }
    return (
        <TEChart
            type="line"
            data={{
                labels: label,
                datasets: isOneLine
                    ? [
                          {
                              label: "訪問時間帯",
                              borderColor: "#10b981",
                              data: data[0],
                              showLine: true,
                          },
                      ]
                    : chartData,
            }}
            className="md:min-w-[600px] aspect-video"
        />
    );
}

const timeLabel = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
];

export default function Chartlist() {
    const [value, setValue] = useState(0);
    const [viewWeek, setViewWeek] = useState([]);
    const [viewWeekReverse, setViewWeekReverse] = useState([]);
    const [viewWeekLabel, setViewWeekLabel] = useState([]);
    const [viewWeekLabelReverse, setViewWeekLabelReverse] = useState([]);
    const [viewYear, setViewYear] = useState([]);
    const [viewYearReverse, setViewYearReverse] = useState([]);
    const [viewYearLabel, setViewYearLabel] = useState([]);
    const [viewYearLabelReverse, setViewYearLabelReverse] = useState([]);
    const [saveWeek, setSaveWeek] = useState([]);
    const [saveWeekReverse, setSaveWeekReverse] = useState([]);
    const [saveYear, setSaveYear] = useState([]);
    const [saveYearReverse, setSaveYearReverse] = useState([]);
    const [viewsHour, setViewsHour] = useState([]);
    const [isView, setIsView] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isViewHour, setIsViewHour] = useState(false);
    const [currentMonthInfo, setCurrentMonthInfo] = useState({
        view: 0,
        save: 0,
        viewToday: 0,
        saveToday: 0,
        viewYesterday: 0,
        saveYesterday: 0,
        mostViewHour: 0,
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const compare = (a, b) => {
        if (a.total < b.total) {
            return 1;
        }
        if (a.total > b.total) {
            return -1;
        }
        return 0;
    };

    const adjustForLocalTime = (utcArray) => {
        const offsetMinutes = new Date().getTimezoneOffset();
        const offset = offsetMinutes / 60;
        const length = utcArray.length;
        return utcArray.map((_, index) => {
            const adjustedIndex = (index + offset + length) % length;
            return utcArray[adjustedIndex];
        });
    };

    const fetchData = async () => {
        const listView = (await Apis.myGet(`${urlAPI.dataViewStatistic}`))
            ?.data;

        const reversedYear = [...listView.data.year].reverse();
        const reversedWeek = [...listView.data.week].reverse();

        setViewsHour(adjustForLocalTime(listView.data.time));
        setViewWeek(listView.data.week.map((item) => item.total));
        setViewWeekReverse(reversedWeek.map((item) => item.total));

        setViewWeekLabel(
            listView.data.week.map((item) =>
                dayjs(item.date).format("YYYY-MM-DD")
            )
        );
        setViewWeekLabelReverse(
            reversedWeek.map((item) => dayjs(item.date).format("YYYY-MM-DD"))
        );

        setViewYear(listView.data.year.map((item) => item.total));
        setViewYearReverse(reversedYear.map((item) => item.total));

        setViewYearLabel(
            listView.data.year.map((item) =>
                dayjs(item.month).format("YYYY-MM")
            )
        );
        setViewYearLabelReverse(
            reversedYear.map((item) => dayjs(item.month).format("YYYY-MM"))
        );

        const listSave = (await Apis.myGet(`${urlAPI.dataSaveStatistic}`))
            ?.data;

        setSaveWeek(listSave.data.week.map((item) => item.total));
        setSaveWeekReverse(reversedWeek.map((item) => item.total));

        setSaveYear(listSave.data.year.map((item) => item.total));
        setSaveYearReverse(reversedYear.map((item) => item.total));

        setCurrentMonthInfo({
            ...currentMonthInfo,
            view:
                listView.data.year.find(
                    (item) =>
                        dayjs(item.month).format("YYYY-MM-DD") ===
                        dayjs().format("YYYY-MM-DD")
                )?.total ?? 0,
            viewToday:
                listView.data.week.find(
                    (item) => item.date === dayjs().format("YYYY-MM-DD")
                )?.total ?? 0,
            viewYesterday:
                listView.data.week.find(
                    (item) =>
                        item.date ===
                        dayjs().subtract(1, "day").format("YYYY-MM-DD")
                )?.total ?? 0,
            save:
                listSave.data.year.find(
                    (item) =>
                        dayjs(item.month).format("YYYY-MM-DD") ===
                        dayjs().format("YYYY-MM-DD")
                )?.total ?? 0,
            saveToday:
                listSave.data.week.find(
                    (item) => item.date === dayjs().format("YYYY-MM-DD")
                )?.total ?? 0,
            saveYesterday:
                listSave.data.week.find(
                    (item) =>
                        item.date ===
                        dayjs().subtract(1, "day").format("YYYY-MM-DD")
                )?.total ?? 0,
            mostViewHour: adjustForLocalTime(listView.data.time)
                .map((item, index) => {
                    return {
                        hour: index,
                        total: item,
                    };
                })
                .sort(compare)[0]?.hour,
        });
    };

    const toggleHourChart = () => {
        if (!isViewHour) setValue(0);
        setIsViewHour(!isViewHour);
        setIsView(false);
        setIsSave(false);
    };

    const toggleViewChart = () => {
        setIsViewHour(false);
        if (!isView) {
            setIsSave(false);
        }
        setIsView(!isView);
    };

    const toggleSaveChart = () => {
        setIsViewHour(false);
        if (!isSave) {
            setIsView(false);
        }
        setIsSave(!isSave);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="mx-2 md:mx-10 border shadow-md">
            <div className="flex flex-row gap-1 md:gap-0 md:justify-around p-2 mt-5 mb-3">
                <div
                    className={`border cursor-pointer bg-white shadow-lg rounded-md w-full py-5 ${
                        isView ? "bg-yellow-100" : ""
                    }`}
                    onClick={toggleViewChart}
                >
                    <h1 className="text-center md:text-2xl">月間訪問者数</h1>
                    <h4 className="text-base md:text-xl text-center my-2">
                        <span className="md:text-2xl text-blue-600">
                            {currentMonthInfo.view}
                        </span>
                        人
                    </h4>
                    <p className="text-end mr-2 md:mr-10">
                        前日比：
                        {currentMonthInfo.viewToday >=
                        currentMonthInfo.viewYesterday
                            ? "＋ " +
                              (currentMonthInfo.viewToday -
                                  currentMonthInfo.viewYesterday)
                            : "– " +
                              (currentMonthInfo.viewYesterday -
                                  currentMonthInfo.viewToday)}
                    </p>
                </div>
                <div
                    className={`border cursor-pointer bg-white shadow-lg rounded-md w-full py-5 ${
                        isViewHour ? "bg-yellow-100" : ""
                    }`}
                    onClick={toggleHourChart}
                >
                    <h1 className="text-center md:text-2xl">訪問時間帯</h1>
                    <h4 className="text-base md:text-xl text-center my-2">
                        <span className="md:text-2xl text-green-500">
                            {currentMonthInfo.mostViewHour}
                        </span>
                        時
                    </h4>
                    <p className="text-end mr-2 md:mr-10"></p>
                </div>
                <div
                    className={`border cursor-pointer bg-white shadow-lg rounded-md w-full py-5 ${
                        isSave ? "bg-yellow-100" : ""
                    }`}
                    onClick={toggleSaveChart}
                >
                    <h1 className="text-center md:text-2xl">月間交換者数</h1>
                    <h4 className="text-base md:text-xl text-center my-2">
                        <span className="md:text-2xl text-orange-400">
                            {currentMonthInfo.save}
                        </span>
                        人
                    </h4>
                    <p className="text-end mr-2 md:mr-10">
                        前日比：
                        {currentMonthInfo.saveToday >=
                        currentMonthInfo.saveYesterday
                            ? "＋" +
                              (currentMonthInfo.saveToday -
                                  currentMonthInfo.saveYesterday)
                            : "ー" +
                              (currentMonthInfo.saveYesterday -
                                  currentMonthInfo.saveToday)}
                    </p>
                </div>
            </div>
            <Box sx={{ width: "100%" }} className="px-2 md:px-10">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    {isViewHour ? (
                        <Tabs
                            value={0}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label="時別"
                                {...a11yProps(0)}
                                style={{
                                    color: "white",
                                    backgroundColor: "#10b981",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            />
                        </Tabs>
                    ) : (
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label="日別"
                                {...a11yProps(0)}
                                style={{
                                    color: "white",
                                    backgroundColor: "#38bdf8",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            />
                            <Tab
                                label="月別"
                                {...a11yProps(1)}
                                style={{
                                    color: "white",
                                    backgroundColor: "#f87171",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            />
                        </Tabs>
                    )}
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <div className="overflow-auto">
                        {isViewHour ? (
                            <ChartLine
                                data={[viewsHour]}
                                label={timeLabel}
                                isOneLine={true}
                            />
                        ) : (
                            <ChartLine
                                data={[viewWeek, saveWeek]}
                                label={viewWeekLabel}
                                showLineOne={
                                    isSave === false || isView === true
                                }
                                showLineTwo={
                                    isSave === true || isView === false
                                }
                            />
                        )}
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className="overflow-auto">
                        <ChartLine
                            data={[viewYear, saveYear]}
                            label={viewYearLabel}
                            showLineOne={isSave === false || isView === true}
                            showLineTwo={isSave === true || isView === false}
                        />
                    </div>
                </CustomTabPanel>
            </Box>
            <div>
                <div className="flex flex-col overflow-x-auto px-2 pt-4 md:px-10 md:mt-10">
                    <div className="sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-x-auto">
                                <table className="min-w-full text-left text-sm font-light">
                                    <thead className="border-b font-medium dark:border-neutral-500">
                                        {isViewHour ? (
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    時
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    訪問者数
                                                </th>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    日付
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    訪問者数
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    交換者数
                                                </th>
                                            </tr>
                                        )}
                                    </thead>
                                    {isViewHour ? (
                                        <tbody>
                                            {timeLabel.map((item, index) => (
                                                <tr className="border-b dark:border-neutral-500">
                                                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                        {item}
                                                    </td>
                                                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                        {viewsHour[index]}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {value === 0
                                                ? viewWeekLabelReverse.map(
                                                      (item, i) => (
                                                          <tr
                                                              className="border-b dark:border-neutral-500"
                                                              key={i}
                                                          >
                                                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                                  {item}
                                                              </td>
                                                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                                  {
                                                                      viewWeekReverse[
                                                                          i
                                                                      ]
                                                                  }
                                                              </td>
                                                              <td className="whitespace-nowrap px-6 py-4">
                                                                  {
                                                                      saveWeekReverse[
                                                                          i
                                                                      ]
                                                                  }
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                                : viewYearLabelReverse.map(
                                                      (item, i) => (
                                                          <tr
                                                              className="border-b dark:border-neutral-500"
                                                              key={i}
                                                          >
                                                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                                  {item}
                                                              </td>
                                                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                                  {
                                                                      viewYearReverse[
                                                                          i
                                                                      ]
                                                                  }
                                                              </td>
                                                              <td className="whitespace-nowrap px-6 py-4">
                                                                  {
                                                                      saveYearReverse[
                                                                          i
                                                                      ]
                                                                  }
                                                              </td>
                                                          </tr>
                                                      )
                                                  )}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
