import { Apis } from "../api";
import { emailRegex, phoneRegex, postCodeRegex } from "../const/common";
import axios from "axios";
import fileDownload from "js-file-download";

export const generateEmbedUrl = (address) => {
    // Extract place
    const placeMatch = address.split("/");
    const placeIndex = placeMatch.indexOf("place");

    const itemAfterPlace = placeMatch[placeIndex + 1];
    const place = placeMatch ? itemAfterPlace : null;

    // Extract latitude and longitude
    const coordRegex = /@(.*?),(.*?),/;
    const coordMatch = address.match(coordRegex);
    const lat = coordMatch ? coordMatch[1] : null;
    const long = coordMatch ? coordMatch[2] : null;

    // Generate Google Maps embed code
    return `https://www.google.com/maps/embed/v1/place?q=${decodeURI(
        place
    )}&center=${lat},${long}&key=AIzaSyBeqpOnrhkYIRpyxwh2e7nY6NztnElJCIk&zoom=16`;
};

export const caseShortUrl = async (url) => {
    const response = await getFullUrl(url);
    if (response.startsWith("https://www.google.com/maps/place/")) {
        const placeMatch = response.split("/");
        const placeIndex = placeMatch.indexOf("place");

        const itemAfterPlace = placeMatch[placeIndex + 1];
        const place = placeMatch ? itemAfterPlace : null;

        // Extract latitude and longitude
        const coordRegex = /@(.*?),(.*?),/;
        const coordMatch = response.match(coordRegex);
        const lat = coordMatch ? coordMatch[1] : null;
        const long = coordMatch ? coordMatch[2] : null;

        // Generate Google Maps embed code
        return `https://www.google.com/maps/embed/v1/place?q=${decodeURI(
            place
        )}&center=${lat},${long}&key=AIzaSyBeqpOnrhkYIRpyxwh2e7nY6NztnElJCIk&zoom=11`;
    } else if (response.startsWith("https://www.google.com/maps/search")) {
        const coordinates = extractCoordinates(response);
        if (coordinates) {
            const latitude = coordinates.latitude;
            const longitude = coordinates.longitude;
            return (
                "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10000!2d" +
                longitude +
                "!3d" +
                latitude +
                "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098&key=AIzaSyBeqpOnrhkYIRpyxwh2e7nY6NztnElJCIk"
            );
        } else {
            return "";
        }
    } else {
        return response;
    }
};

const getFullUrl = async (short_url) => {
    const response = await Apis.myPost(`social/un-short-url`, { short_url });
    return response.data;
};

export const getEmbedUrl = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/embed/v1/place?key=AIzaSyBeqpOnrhkYIRpyxwh2e7nY6NztnElJCIk&q=${encodedAddress}`;
};
function extractCoordinates(url) {
    // Regular expression to match the latitude and longitude in the URL
    const regex = /\/maps\/search\/(-?\d+\.\d+),\+(-?\d+\.\d+)/;
    const match = url.match(regex);

    if (match) {
        const latitude = parseFloat(match[1]);
        const longitude = parseFloat(match[2]);
        return { latitude, longitude };
    } else {
        return null;
    }
}

export const isValidEmail = (email) => {
    if (!email) return false;
    return emailRegex.test(email);
};

export const isValidPhoneNumber = (phone) => {
    if (!phone) return false;
    return phoneRegex.test(phone);
};

export const isValidPostCode = (postCode) => {
    if (!postCode) return false;
    return postCodeRegex.test(postCode);
};

export const formatHtmlAspectRatio = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const imgs = doc.querySelectorAll("img");
    imgs.forEach((img) => {
        const style = img.getAttribute("style");
        if (!style) {
            const width = img.getAttribute("width");
            const height = img.getAttribute("height");
            const maxWidth = 600;
            if (width && height) {
                const aspectRatio = width / height;
                const newWidth = width > maxWidth ? maxWidth : width;
                const newHeight = newWidth / aspectRatio;
                const newStyle = `width: ${newWidth}px; height: ${newHeight}px;`;
                img.setAttribute("style", newStyle);
                return;
            }
            return;
        }
        const widthMatch = style.match(/width:\s*([0-9.]+)px/);
        const aspectRatioMatch = style.match(
            /aspect-ratio:\s*([0-9.]+)\/([0-9.]+)/
        );

        if (widthMatch && aspectRatioMatch) {
            const width = parseFloat(widthMatch[1]);
            const aspectRatioWidth = parseFloat(aspectRatioMatch[1]);
            const aspectRatioHeight = parseFloat(aspectRatioMatch[2]);

            const height = width / (aspectRatioWidth / aspectRatioHeight);

            const newStyle =
                style + `height: ${height}px;` + `max-width: 100% !important;`;
            img.setAttribute("style", newStyle);
        }
    });

    return doc.body.innerHTML;
};

export const formatCurrency = (price) => {
    if (!price) return 0;
    return price.toLocaleString();
};

export const getLocaltionByPostCode = async (postCode) => {
    try {
        if (!postCode) return null;
        const response = await fetch(
            "https://zipcloud.ibsnet.co.jp/api/search?zipcode=" + postCode
        );
        if (response) {
            const data = await response.json();
            if (data.status === 200) {
                return {
                    province: data.results[0].address1,
                    city: data.results[0].address2,
                    town: data.results[0].address3,
                };
            }
        }
        throw new Error("Invalid post code");
    } catch (error) {
        console.error(error);
    }
    return null;
};

export const concatenateParams = ({ url, query }) => {
    const queryString = new URLSearchParams(query).toString();
    return `${url}?${queryString}`;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const handleDownload = (url, filename) => {
    axios
        .get(url, {
            responseType: "blob",
        })
        .then((res) => {
            fileDownload(res.data, filename);
        });
};

export const handleTextLink = (text) => {
    if (!text) return "";
    return text?.startsWith("http") || text?.startsWith("https")
        ? text
        : `https://${text}`;
};
