import React from "react";
import { Link } from "react-router-dom";
import { TERipple } from "tw-elements-react";

import { useLocation } from "react-router-dom";
import { formatCurrency } from "../../utilities/helper";
import Progressbar from "./Progressbar";

export default function ContractFour() {
    const location = useLocation();
    const locationState = location.state;
    if (!locationState) {
        window.location.href = "/d-shop/list";
    }
    const totalPrice = locationState.totalPrice;
    const totalPriceWithTax = locationState.totalPriceWithTax;

    return (
        <div>
            <Link to="/d-dashboard">
                <img
                    alt="logo"
                    src="/image/logo.png"
                    width="150"
                    className="pt-3 pl-5"
                />
            </Link>
            <Progressbar progressState={2} />
            <div className="px-10">
                <div className="text-2xl font-bold mx-auto text-center mb-10">
                    ご注文ありがとうございました
                </div>

                <div className="flex flex-col p-6  w-full justify-end items-end bg-zinc-300">
                    <div className="pc:mr-32 mb-5 text-sm flex items-center mobile:w-full mobile:justify-between">
                        <div className="mobile:text-end mobile:w-[150px]">
                            商品合計
                        </div>

                        <span className="pc:ml-24 text-2xl text-blue-800 mobile:whitespace-nowrap">
                            {formatCurrency(totalPriceWithTax)}円
                        </span>
                    </div>

                    <div className="pc:mr-32 mb-5 text-sm flex items-center mobile:w-full mobile:justify-between">
                        <div className="mobile:text-end mobile:w-[150px]">
                            送料
                        </div>

                        <span className="pc:ml-[135px] text-lg text-blue-800 mobile:whitespace-nowrap">
                            0円
                        </span>
                    </div>

                    <div className="pc:mr-32 mb-5 text-sm flex items-center mobile:w-full mobile:justify-between">
                        <div className="mobile:text-end mobile:w-[150px]">
                            合計
                        </div>
                        <span className="pc:ml-24 text-2xl text-red-500 mobile:whitespace-nowrap">
                            {formatCurrency(totalPriceWithTax)}円
                        </span>
                    </div>
                    <div className="pc:mr-32 text-green-500 mobile:whitespace-nowrap">
                        (うち消費税
                        {formatCurrency(totalPriceWithTax - totalPrice)}
                        円)
                    </div>
                </div>

                <TERipple rippleColor="white" style={{ display: "block" }}>
                    <Link
                        className="w-[70%] relative mx-auto justify-center bg-zinc-800 pc:px-10 pc:pr-10 py-5 text-white mt-10 cursor-pointer flex items-center"
                        to="/d-shop/list"
                    >
                        ショッピングページへ
                        <svg
                            className="absolute right-5 mobile:hidden"
                            width="13"
                            height="25"
                            viewBox="0 0 13 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.547878 28.2862C1.27838 29.2379 2.46276 29.2379 3.19326 28.2862L12.4521 16.2233C13.1826 15.2715 13.1826 13.7285 12.4521 12.7767L3.19327 0.713804C2.46276 -0.237936 1.27838 -0.237936 0.547878 0.713804C-0.182625 1.66554 -0.182626 3.20861 0.547878 4.16035L8.48404 14.5L0.547878 24.8396C-0.182625 25.7914 -0.182625 27.3345 0.547878 28.2862Z"
                                fill="white"
                            />
                        </svg>
                    </Link>
                </TERipple>
            </div>
        </div>
    );
}
