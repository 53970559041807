import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Modal,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as React from "react";
import { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import { Apis } from "../../api";
import { FileUpload } from "../../utilities/upload";

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "0px 16px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function AdminSetting() {
    const [imageTopPC, setImageTopPC] = useState([]);
    const [imageTopApp, setImageTopApp] = useState([]);
    const [imageTopShop, setImageTopShop] = useState([]);
    const [topPCImage, setTopPCImage] = useState(null);
    const [topPCImageStart, setTopPCImageStart] = useState("");
    const [topPCImageEnd, setTopPCImageEnd] = useState("");
    const [topPCLink, setTopPCLink] = useState("");
    const [isSubmitTopImage, setIsSubmitTopImage] = useState(false);
    const [page, setPage] = useState(1);
    const [pageApp, setPageApp] = useState(1);
    const [pageShop, setPageShop] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [perPageApp, setPerPageApp] = useState(5);
    const [perPageShop, setPerPageShop] = useState(5);
    const [total, setTotal] = useState(0);
    const [totalApp, setTotalApp] = useState(0);
    const [totalShop, setTotalShop] = useState(0);

    const [openModalPC, setOpenModalPC] = useState(false);
    const [openModalApp, setOpenModalApp] = useState(false);
    const [openModalShop, setOpenModalShop] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    /* -------------------------------------------------------------------------- */
    /*                               Top PC Setting                               */
    /* -------------------------------------------------------------------------- */
    //トップPC画像アップロード
    const handleTopPCImageUpload = (event) => {
        if (event.target.files.length) {
            const fr = new FileReader();
            fr.onload = function () {
                setTopPCImage(fr.result);
            };
            fr.readAsDataURL(event.target.files[0]);
        }
    };

    //トップPC画像設定
    const handleTopImage = async (imageUsage) => {
        setIsLoading(true);
        if (currentImage?.id) {
            await handleUpdateImagePC(
                topPCImage,
                topPCImageStart,
                topPCImageEnd,
                topPCLink,
                imageUsage
            );
        } else {
            await putImage(
                topPCImage,
                topPCImageStart,
                topPCImageEnd,
                topPCLink,
                imageUsage
            );
        }
        setIsLoading(false);
        setCurrentImage(null);
        setTopPCImage(null);
        setTopPCImageStart("");
        setTopPCImageEnd("");
        setTopPCLink("");
        setOpenModalPC(false);
        setOpenModalApp(false);
        setOpenModalShop(false);
        await getTopImage(imageUsage);
    };

    //APIに設定をget
    const getTopImage = async (imageUsage) => {
        const res = await Apis.myGet(
            `settingImage/admin?imageUsage=${imageUsage}&page=${
                imageUsage === 1 ? page : imageUsage === 2 ? pageApp : pageShop
            }&per_page=${
                imageUsage === 1
                    ? perPage
                    : imageUsage === 2
                    ? perPageApp
                    : perPageShop
            }`
        );
        if (res && res.success && res.data.items.length !== 0) {
            switch (imageUsage) {
                case 1:
                    setImageTopPC(
                        res.data.items.map((item, index) => {
                            return {
                                ...item,
                                no: index + 1,
                                start_date: dayjs(item.start_date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                end_date: dayjs(item.end_date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                isImage: isImageFile(item.image_url),
                            };
                        })
                    );
                    setTotal(res?.data.total);
                    break;
                case 2:
                    setImageTopApp(
                        res.data.items.map((item, index) => {
                            return {
                                ...item,
                                no: index + 1,
                                start_date: dayjs(item.start_date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                end_date: dayjs(item.end_date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                isImage: isImageFile(item.image_url),
                            };
                        })
                    );
                    setTotalApp(res?.data.total);
                    break;
                case 3:
                    setImageTopShop(
                        res.data.items.map((item, index) => {
                            return {
                                ...item,
                                no: index + 1,
                                start_date: dayjs(item.start_date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                end_date: dayjs(item.end_date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                isImage: isImageFile(item.image_url),
                            };
                        })
                    );
                    setTotalShop(res?.data.total);
                    break;

                default:
                    break;
            }
        }
    };

    //APIに設定をput
    const putImage = async (file, startDate, endDate, link, imageUsage) => {
        const upload_url = await FileUpload(file);
        const payload = {
            image_url: upload_url,
            start_date: dayjs(new Date(startDate))
                .utc()
                .format("YYYY-MM-DDTHH:mm"),
            end_date: dayjs(new Date(endDate)).utc().format("YYYY-MM-DDTHH:mm"),
            image_usage: imageUsage,
            link: link,
        };

        const res = await Apis.myPost("settingImage/settingImage", payload);
        if (res && res.success) {
            toast.success(`画像の登録が完了しました！`);
            return;
        }
        if (res && res.data.error) {
            toast.error(res.data.error);
        } else {
            toast.error(`失敗しました! もう一度お試しください!`);
        }
    };

    function isImageFile(filePath) {
        const imageExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "svg",
            "webp",
            "tiff",
        ];

        const extension = filePath.split(".").pop().toLowerCase();

        return imageExtensions.includes(extension);
    }

    //APIに設定をdelete
    const handleDeleteSettingImage = async () => {
        const res = await Apis.myDelete(
            `settingImage/settingImage/${openConfirmDelete}`
        );
        setOpenConfirmDelete(null);
        if (res && res.success) {
            if (res && res.success) {
                toast.success(`画像は正常に削除されました!`);
                await getTopImage(1);
                return;
            }
        }
    };

    const handleUpdateImagePC = async (
        file,
        startDate,
        endDate,
        link,
        imageUsage
    ) => {
        const payload = {
            id: currentImage.id,
            start_date: dayjs(new Date(startDate))
                .utc()
                .format("YYYY-MM-DDTHH:mm"),
            end_date: dayjs(new Date(endDate)).utc().format("YYYY-MM-DDTHH:mm"),
            image_usage: imageUsage,
            link: link,
        };
        if (file && file.startsWith("data:")) {
            const upload_url = await FileUpload(file);
            payload.image_url = upload_url;
        } else {
            payload.image_url = currentImage.image_url;
        }

        const res = await Apis.myPut(
            `settingImage/settingImage/${currentImage.id}`,
            payload
        );
        if (res && res.success) {
            toast.success(`画像の登録が完了しました！`);
            return;
        }
        if (res && res.data.error) {
            toast.error(res.data.error);
        } else {
            toast.error(`失敗しました! もう一度お試しください!`);
        }
    };

    useEffect(() => {
        if (topPCImageStart && topPCImageEnd) {
            setIsSubmitTopImage(false);
        } else {
            setIsSubmitTopImage(true);
        }
    }, [topPCImageStart, topPCImageEnd]);

    useEffect(() => {
        getTopImage(1);
    }, [page, perPage]);

    useEffect(() => {
        getTopImage(2);
    }, [pageApp, perPageApp]);

    useEffect(() => {
        getTopImage(3);
    }, [pageShop, perPageShop]);

    const disableDateStart = (current) => {
        if (!topPCImageEnd || topPCImageEnd === "") return true;
        return current.isBefore(topPCImageEnd, "day");
    };
    const disableDateEnd = (current) => {
        if (!topPCImageStart || topPCImageStart === "") return true;
        return current.isAfter(topPCImageStart, "day");
    };

    return (
        <div className="p-10">
            <div className="mb-4">
                <div className="py-3 px-4 border-l-4 border-l-indigo-600">
                    トップ画像（PC）
                </div>
                <button
                    className="rounded-lg cursor-pointer bg-blue-400 hover:bg-blue-500 active:bg-blue-600 text-white my-5 p-4 w-40"
                    onClick={() => {
                        setTopPCImageStart("");
                        setTopPCImageEnd("");
                        setOpenModalPC(true);
                    }}
                >
                    登録
                </button>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">
                                    No
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    画像
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    開始日
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    終了日
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    リンク
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageTopPC.length === 0 && (
                                <StyledTableRow>
                                    <StyledTableCell
                                        colSpan={15}
                                        align="center"
                                    >
                                        <div className="p-4">
                                            該当する注文は 0 件 です
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {imageTopPC.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        sx={{ width: "80px" }}
                                    >
                                        {row.no}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.isImage && (
                                            <img
                                                alt=""
                                                src={row.image_url}
                                                width={100}
                                                className="m-auto max-h-[300px]"
                                            ></img>
                                        )}
                                        {!row.isImage && (
                                            <video
                                                autoPlay
                                                loop
                                                muted
                                                width={100}
                                                className="m-auto max-h-[300px]"
                                            >
                                                <source
                                                    src={row.image_url}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.start_date}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.end_date}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.link}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "150px" }}
                                    >
                                        <div className="px-2 py-2 flex flex-col">
                                            <button
                                                className="rounded-lg cursor-pointer bg-red-400 hover:bg-red-500 active:bg-red-600 text-white max-[680px]:text-sm max-[680px]:p-1 py-1"
                                                onClick={() =>
                                                    setOpenConfirmDelete(row.id)
                                                }
                                            >
                                                削除
                                            </button>
                                            <button
                                                className="mt-3 rounded-lg cursor-pointer bg-lime-400 hover:bg-lime-500 active:bg-lime-600 text-white max-[680px]:text-sm max-[680px]:p-1 py-1"
                                                onClick={() => {
                                                    setCurrentImage(row);
                                                    setTopPCImage(
                                                        row.image_url
                                                    );
                                                    setTopPCImageStart(
                                                        new Date(row.start_date)
                                                    );
                                                    setTopPCImageEnd(
                                                        new Date(row.end_date)
                                                    );
                                                    setTopPCLink(row.link);
                                                    setOpenModalPC(true);
                                                }}
                                            >
                                                編集
                                            </button>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Render the pagination controls here */}
                <div className="w-full  min-w-[900px] mt-1 p-3 flex justify-around items-center !text-slate-400">
                    <div className=" w-24">{`${
                        page * perPage - (perPage - 1)
                    }-${
                        page * perPage > total
                            ? page * perPage - perPage + imageTopPC?.length
                            : page * perPage
                    } of ${total}`}</div>
                    <div className="w-full justify-center flex gap-2">
                        <div>
                            <span className=" text-slate-400 hidden md:inline-block">
                                ページあたりの行数：
                            </span>
                            <select
                                className="border-none focus-visible:border-none bg-gray-100"
                                value={perPage}
                                onChange={(e) => setPerPage(e.target.value)}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="flex items-center">
                            <button
                                onClick={() => {
                                    setPage(page - 1);
                                }}
                                disabled={page === 1}
                                className="mr-2 max-[400px]:ml-2 ml-10"
                            >
                                <svg
                                    width="14"
                                    height="23"
                                    viewBox="0 0 14 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13 22L1 11.5L13 1"
                                        stroke="#868FA0"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                                {page} /{" "}
                                {Math.ceil(
                                    (total || imageTopPC?.length) / perPage
                                )}
                            </span>
                            <button
                                onClick={() => setPage(page + 1)}
                                disabled={
                                    page ===
                                        Math.ceil(
                                            (total || imageTopPC?.length) /
                                                perPage
                                        ) ||
                                    !imageTopPC ||
                                    imageTopPC?.length === 0
                                }
                                className="mx-2"
                            >
                                <svg
                                    width="14"
                                    height="23"
                                    viewBox="0 0 14 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L13 11.5L1 22"
                                        stroke="#868FA0"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {openModalPC && (
                    <Modal
                        open={openModalPC}
                        onClose={() => {
                            setCurrentImage(null);
                            setTopPCImage(null);
                            setTopPCImageStart("");
                            setTopPCImageEnd("");
                            setTopPCLink("");
                            setOpenModalPC(false);
                        }}
                        keepMounted
                        className="w-full flex justify-center items-center"
                    >
                        <div className="relative w-[40%] tablet:w-[50%] mobile:w-[320px] rounded-lg p-[20px] bg-white items-center">
                            {isLoading && (
                                <div className="absolute inset-0 flex justify-center items-center z-[10] bg-zinc-500/30">
                                    <CircularProgress />
                                </div>
                            )}
                            <div className="h-[calc(100%-40px)]">
                                {topPCImage?.startsWith("data:video") ||
                                (!currentImage?.isImage &&
                                    topPCImage &&
                                    !topPCImage.startsWith("data:image")) ? (
                                    <div className="relative w-full aspect-[16/9] border-black border-2 mx-auto">
                                        <video autoPlay loop>
                                            <source
                                                src={topPCImage}
                                                type="video/mp4"
                                            />
                                        </video>
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,video/mp4"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            name={`image-upload-${
                                                currentImage?.id ?? 0
                                            }`}
                                            onChange={(event) => {
                                                handleTopPCImageUpload(event);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="relative w-full aspect-[16/9] border-black border-2 mx-auto"
                                        style={{
                                            backgroundImage: `url(${
                                                topPCImage ??
                                                currentImage?.image_url ??
                                                "/image/imagelink.png"
                                            })`,
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,video/mp4"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            name={`image-upload-${
                                                currentImage?.id ?? 0
                                            }`}
                                            onChange={(event) => {
                                                handleTopPCImageUpload(event);
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="flex justify-between items-center gap-2 mt-2">
                                    <Datetime
                                        value={topPCImageStart ?? ""}
                                        inputProps={{
                                            placeholder:
                                                " 月 / 日 / 年       --:--",
                                            onKeyDown: (e) => {
                                                if (e.keyCode !== 8) {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onChange={(value) => {
                                            setTopPCImageStart(value);
                                        }}
                                        isValidDate={disableDateStart}
                                        timeFormat="HH:mm"
                                        className="relative cursor-pointer border border-black rounded-md datetime-custom w-64"
                                    />

                                    <span> ~ </span>
                                    <Datetime
                                        value={topPCImageEnd}
                                        inputProps={{
                                            placeholder:
                                                " 月 / 日 / 年       --:--",
                                            onKeyDown: (e) => {
                                                if (e.keyCode !== 8) {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onChange={(value) => {
                                            setTopPCImageEnd(value);
                                        }}
                                        isValidDate={disableDateEnd}
                                        timeFormat="HH:mm"
                                        className="relative cursor-pointer border border-black rounded-md datetime-custom w-64"
                                    />
                                </div>
                                <div className="flex items-center">
                                    <div className="min-w-20">リンク:</div>
                                    <input
                                        className="w-full text-sm border border-black rounded-md p-2 px-3 my-1 mb-2"
                                        name={`link-3-${currentImage?.id ?? 0}`}
                                        value={topPCLink}
                                        placeholder="URL"
                                        onChange={(e) =>
                                            setTopPCLink(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="flex justify-end gap-6">
                                    <Button
                                        variant="outlined"
                                        disabled={isSubmitTopImage}
                                        className="mt-5 py-2 px-4 mr-10 inline-block text-white rounded-lg cursor-pointer"
                                        onClick={() => {
                                            setCurrentImage(null);
                                            setTopPCImage(null);
                                            setTopPCImageStart("");
                                            setTopPCImageEnd("");
                                            setTopPCLink("");
                                            setOpenModalPC(false);
                                        }}
                                    >
                                        キャンセル
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitTopImage}
                                        className="mt-5 py-2 px-4 mr-10 inline-block text-white rounded-lg cursor-pointer"
                                        onClick={() => handleTopImage(1)}
                                    >
                                        設定する
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>

            <div className="mb-4">
                <div className="py-3 px-4 border-l-4 border-l-indigo-600">
                    トップ画像（アプリ）
                </div>
                <button
                    className="rounded-lg cursor-pointer bg-blue-400 hover:bg-blue-500 active:bg-blue-600 text-white my-5 p-4 w-40"
                    onClick={() => {
                        setOpenModalApp(true);
                    }}
                >
                    登録
                </button>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">
                                    No
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    画像
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    開始日
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    終了日
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    リンク
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageTopApp.length === 0 && (
                                <StyledTableRow>
                                    <StyledTableCell
                                        colSpan={15}
                                        align="center"
                                    >
                                        <div className="p-4">
                                            該当する注文は 0 件 です
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {imageTopApp.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        sx={{ width: "80px" }}
                                    >
                                        {row.no}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.isImage && (
                                            <img
                                                alt=""
                                                src={row.image_url}
                                                width={100}
                                                className="m-auto max-h-[300px]"
                                            ></img>
                                        )}
                                        {!row.isImage && (
                                            <video
                                                autoPlay
                                                loop
                                                muted
                                                width={100}
                                                className="m-auto max-h-[300px]"
                                            >
                                                <source
                                                    src={row.image_url}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.start_date}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.end_date}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.link}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "150px" }}
                                    >
                                        <div className="px-2 py-2 flex flex-col">
                                            <button
                                                className="rounded-lg cursor-pointer bg-red-400 hover:bg-red-500 active:bg-red-600 text-white max-[680px]:text-sm max-[680px]:p-1 py-1"
                                                onClick={() =>
                                                    setOpenConfirmDelete(row.id)
                                                }
                                            >
                                                削除
                                            </button>
                                            <button
                                                className="mt-3 rounded-lg cursor-pointer bg-lime-400 hover:bg-lime-500 active:bg-lime-600 text-white max-[680px]:text-sm max-[680px]:p-1 py-1"
                                                onClick={() => {
                                                    setCurrentImage(row);
                                                    setTopPCImage(
                                                        row.image_url
                                                    );
                                                    setTopPCImageStart(
                                                        new Date(row.start_date)
                                                    );
                                                    setTopPCImageEnd(
                                                        new Date(row.end_date)
                                                    );
                                                    setTopPCLink(row.link);
                                                    setOpenModalApp(true);
                                                }}
                                            >
                                                編集
                                            </button>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Render the pagination controls here */}
                {!!totalApp && (
                    <div className="w-full  min-w-[900px] mt-1 p-3 flex justify-around items-center !text-slate-400">
                        <div className=" w-24">{`${
                            pageApp * perPageApp - (perPageApp - 1)
                        }-${
                            pageApp * perPageApp > totalApp
                                ? pageApp * perPageApp -
                                  perPageApp +
                                  imageTopApp?.length
                                : pageApp * perPageApp
                        } of ${totalApp}`}</div>
                        <div className="w-full justify-center flex gap-2">
                            <div>
                                <span className=" text-slate-400 hidden md:inline-block">
                                    ページあたりの行数：
                                </span>
                                <select
                                    className="border-none bg-gray-100"
                                    value={perPageApp}
                                    onChange={(e) =>
                                        setPerPageApp(e.target.value)
                                    }
                                >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="flex items-center">
                                <button
                                    onClick={() => {
                                        setPageApp(pageApp - 1);
                                    }}
                                    disabled={pageApp === 1}
                                    className="mr-2 max-[400px]:ml-2 ml-10"
                                >
                                    <svg
                                        width="14"
                                        height="23"
                                        viewBox="0 0 14 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13 22L1 11.5L13 1"
                                            stroke="#868FA0"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                                <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                                    {pageApp} /{" "}
                                    {Math.ceil(
                                        (totalApp || imageTopApp?.length) /
                                            perPageApp
                                    )}
                                </span>
                                <button
                                    onClick={() => setPageApp(pageApp + 1)}
                                    disabled={
                                        pageApp ===
                                            Math.ceil(
                                                (totalApp ||
                                                    imageTopApp?.length) /
                                                    perPageApp
                                            ) ||
                                        !imageTopApp ||
                                        imageTopApp?.length === 0
                                    }
                                    className="mx-2"
                                >
                                    <svg
                                        width="14"
                                        height="23"
                                        viewBox="0 0 14 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 1L13 11.5L1 22"
                                            stroke="#868FA0"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {openModalApp && (
                    <Modal
                        open={openModalApp}
                        onClose={() => {
                            setCurrentImage(null);
                            setTopPCImage(null);
                            setTopPCImageStart("");
                            setTopPCImageEnd("");
                            setTopPCLink("");
                            setOpenModalApp(false);
                        }}
                        keepMounted
                        className="w-full flex justify-center items-center"
                    >
                        <div className="relative w-[40%] tablet:w-[50%] mobile:w-[320px] rounded-lg p-[20px] bg-white items-center">
                            {isLoading && (
                                <div className="absolute inset-0 flex justify-center items-center z-[10] bg-zinc-500/30">
                                    <CircularProgress />
                                </div>
                            )}
                            <div className="h-[calc(100%-40px)]">
                                {topPCImage?.startsWith("data:video") ||
                                (!currentImage?.isImage &&
                                    topPCImage &&
                                    !topPCImage.startsWith("data:image")) ? (
                                    <div className="relative w-full aspect-[16/9] border-black border-2 mx-auto">
                                        <video autoPlay loop>
                                            <source
                                                src={topPCImage}
                                                type="video/mp4"
                                            />
                                        </video>
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,video/mp4"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            name={`image-upload-${
                                                currentImage?.id ?? 0
                                            }`}
                                            onChange={(event) => {
                                                handleTopPCImageUpload(event);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="relative w-full aspect-[16/9] border-black border-2 mx-auto"
                                        style={{
                                            backgroundImage: `url(${
                                                topPCImage ??
                                                currentImage?.image_url ??
                                                "/image/imagelink.png"
                                            })`,
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,video/mp4"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            name={`image-upload-${
                                                currentImage?.id ?? 0
                                            }`}
                                            onChange={(event) => {
                                                handleTopPCImageUpload(event);
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="flex justify-between items-center gap-2 mt-2">
                                    <Datetime
                                        value={topPCImageStart ?? ""}
                                        inputProps={{
                                            placeholder:
                                                " 月 / 日 / 年       --:--",
                                            onKeyDown: (e) => {
                                                if (e.keyCode !== 8) {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onChange={(value) => {
                                            setTopPCImageStart(value);
                                        }}
                                        isValidDate={disableDateStart}
                                        timeFormat="HH:mm"
                                        className="relative cursor-pointer border border-black rounded-md datetime-custom w-64"
                                    />
                                    <span> ~ </span>
                                    <Datetime
                                        value={topPCImageEnd}
                                        inputProps={{
                                            placeholder:
                                                " 月 / 日 / 年       --:--",
                                            onKeyDown: (e) => {
                                                if (e.keyCode !== 8) {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onChange={(value) => {
                                            setTopPCImageEnd(value);
                                        }}
                                        isValidDate={disableDateEnd}
                                        timeFormat="HH:mm"
                                        className="relative cursor-pointer border border-black rounded-md datetime-custom w-64"
                                    />
                                </div>
                                <div className="flex items-center">
                                    <div className="min-w-20">リンク:</div>
                                    <input
                                        className="w-full text-sm border border-black rounded-md p-2 px-3 my-1 mb-2"
                                        name={`link-3-${currentImage?.id ?? 0}`}
                                        value={topPCLink}
                                        placeholder="URL"
                                        onChange={(e) =>
                                            setTopPCLink(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="flex justify-end gap-6">
                                    <Button
                                        variant="outlined"
                                        disabled={isSubmitTopImage}
                                        className="mt-5 py-2 px-4 mr-10 inline-block text-white rounded-lg cursor-pointer"
                                        onClick={() => {
                                            setCurrentImage(null);
                                            setTopPCImage(null);
                                            setTopPCImageStart("");
                                            setTopPCImageEnd("");
                                            setTopPCLink("");
                                            setOpenModalApp(false);
                                        }}
                                    >
                                        キャンセル
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitTopImage}
                                        className="mt-5 py-2 px-4 mr-10 inline-block text-white rounded-lg cursor-pointer"
                                        onClick={() => handleTopImage(2)}
                                    >
                                        設定する
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>

            <div className="mb-4">
                <div className="py-3 px-4 border-l-4 border-l-indigo-600">
                    ショップページトップ画像
                </div>
                <button
                    className="rounded-lg cursor-pointer bg-blue-400 hover:bg-blue-500 active:bg-blue-600 text-white my-5 p-4 w-40"
                    onClick={() => {
                        setOpenModalShop(true);
                    }}
                >
                    登録
                </button>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">
                                    No
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    画像
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    開始日
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    終了日
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    リンク
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageTopShop.length === 0 && (
                                <StyledTableRow>
                                    <StyledTableCell
                                        colSpan={15}
                                        align="center"
                                    >
                                        <div className="p-4">
                                            該当する注文は 0 件 です
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {imageTopShop.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        sx={{ width: "80px" }}
                                    >
                                        {row.no}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.isImage && (
                                            <img
                                                alt=""
                                                src={row.image_url}
                                                width={100}
                                                className="m-auto max-h-[300px]"
                                            ></img>
                                        )}
                                        {!row.isImage && (
                                            <video
                                                autoPlay
                                                loop
                                                muted
                                                width={100}
                                                className="m-auto max-h-[300px]"
                                            >
                                                <source
                                                    src={row.image_url}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.start_date}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "160px" }}
                                    >
                                        {row.end_date}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.link}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ width: "150px" }}
                                    >
                                        <div className="px-2 py-2 flex flex-col">
                                            <button
                                                className="rounded-lg cursor-pointer bg-red-400 hover:bg-red-500 active:bg-red-600 text-white max-[680px]:text-sm max-[680px]:p-1 py-1"
                                                onClick={() =>
                                                    setOpenConfirmDelete(row.id)
                                                }
                                            >
                                                削除
                                            </button>
                                            <button
                                                className="mt-3 rounded-lg cursor-pointer bg-lime-400 hover:bg-lime-500 active:bg-lime-600 text-white max-[680px]:text-sm max-[680px]:p-1 py-1"
                                                onClick={() => {
                                                    setCurrentImage(row);
                                                    setTopPCImage(
                                                        row.image_url
                                                    );
                                                    setTopPCImageStart(
                                                        new Date(row.start_date)
                                                    );
                                                    setTopPCImageEnd(
                                                        new Date(row.end_date)
                                                    );
                                                    setTopPCLink(row.link);
                                                    setOpenModalShop(true);
                                                }}
                                            >
                                                編集
                                            </button>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Render the pagination controls here */}
                {!!totalShop && (
                    <div className="w-full  min-w-[900px] mt-1 p-3 flex justify-around items-center !text-slate-400">
                        <div className=" w-24">{`${
                            pageShop * perPageShop - (perPageShop - 1)
                        }-${
                            pageShop * perPageShop > totalShop
                                ? pageShop * perPageShop -
                                  perPageShop +
                                  imageTopShop?.length
                                : pageShop * perPageShop
                        } of ${totalShop}`}</div>
                        <div className="w-full justify-center flex gap-2">
                            <div>
                                <span className=" text-slate-400 hidden md:inline-block">
                                    ページあたりの行数：
                                </span>
                                <select
                                    className="border-none bg-gray-100"
                                    value={perPageShop}
                                    onChange={(e) =>
                                        setPerPageShop(e.target.value)
                                    }
                                >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="flex items-center">
                                <button
                                    onClick={() => {
                                        setPageShop(pageShop - 1);
                                    }}
                                    disabled={pageShop === 1}
                                    className="mr-2 max-[400px]:ml-2 ml-10"
                                >
                                    <svg
                                        width="14"
                                        height="23"
                                        viewBox="0 0 14 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13 22L1 11.5L13 1"
                                            stroke="#868FA0"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                                <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                                    {pageShop} /{" "}
                                    {Math.ceil(
                                        (totalShop || imageTopShop?.length) /
                                            perPageShop
                                    )}
                                </span>
                                <button
                                    onClick={() => setPageShop(pageShop + 1)}
                                    disabled={
                                        pageShop ===
                                            Math.ceil(
                                                (totalShop ||
                                                    imageTopShop?.length) /
                                                    perPageShop
                                            ) ||
                                        !imageTopShop ||
                                        imageTopShop?.length === 0
                                    }
                                    className="mx-2"
                                >
                                    <svg
                                        width="14"
                                        height="23"
                                        viewBox="0 0 14 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 1L13 11.5L1 22"
                                            stroke="#868FA0"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {openModalShop && (
                    <Modal
                        open={openModalShop}
                        onClose={() => {
                            setCurrentImage(null);
                            setTopPCImage(null);
                            setTopPCImageStart("");
                            setTopPCImageEnd("");
                            setTopPCLink("");
                            setOpenModalShop(false);
                        }}
                        keepMounted
                        className="w-full flex justify-center items-center"
                    >
                        <div className="relative w-[40%] tablet:w-[50%] mobile:w-[320px] rounded-lg p-[20px] bg-white items-center">
                            {isLoading && (
                                <div className="absolute inset-0 flex justify-center items-center z-[10] bg-zinc-500/30">
                                    <CircularProgress />
                                </div>
                            )}
                            <div className="h-[calc(100%-40px)]">
                                {topPCImage?.startsWith("data:video") ||
                                (!currentImage?.isImage &&
                                    topPCImage &&
                                    !topPCImage.startsWith("data:image")) ? (
                                    <div className="relative w-full aspect-[16/9] border-black border-2 mx-auto">
                                        <video autoPlay loop>
                                            <source
                                                src={topPCImage}
                                                type="video/mp4"
                                            />
                                        </video>
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,video/mp4"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            name={`image-upload-${
                                                currentImage?.id ?? 0
                                            }`}
                                            onChange={(event) => {
                                                handleTopPCImageUpload(event);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="relative w-full aspect-[16/9] border-black border-2 mx-auto"
                                        style={{
                                            backgroundImage: `url(${
                                                topPCImage ??
                                                currentImage?.image_url ??
                                                "/image/imagelink.png"
                                            })`,
                                            backgroundSize: "contain",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    >
                                        <input
                                            type="file"
                                            accept="image/jpeg,image/png,video/mp4"
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            name={`image-upload-${
                                                currentImage?.id ?? 0
                                            }`}
                                            onChange={(event) => {
                                                handleTopPCImageUpload(event);
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="flex justify-between items-center gap-2 mt-2">
                                    <Datetime
                                        value={topPCImageStart ?? ""}
                                        inputProps={{
                                            placeholder:
                                                " 月 / 日 / 年       --:--",
                                            onKeyDown: (e) => {
                                                if (e.keyCode !== 8) {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onChange={(value) => {
                                            setTopPCImageStart(value);
                                        }}
                                        isValidDate={disableDateStart}
                                        timeFormat="HH:mm"
                                        className="relative cursor-pointer border border-black rounded-md datetime-custom w-64"
                                    />
                                    <span> ~ </span>
                                    <Datetime
                                        value={topPCImageEnd}
                                        inputProps={{
                                            placeholder:
                                                " 月 / 日 / 年       --:--",
                                            onKeyDown: (e) => {
                                                if (e.keyCode !== 8) {
                                                    e.preventDefault();
                                                }
                                            },
                                        }}
                                        onChange={(value) => {
                                            setTopPCImageEnd(value);
                                        }}
                                        isValidDate={disableDateEnd}
                                        timeFormat="HH:mm"
                                        className="relative cursor-pointer border border-black rounded-md datetime-custom w-64"
                                    />
                                </div>
                                <div className="flex items-center">
                                    <div className="min-w-20">リンク:</div>
                                    <input
                                        className="w-full text-sm border border-black rounded-md p-2 px-3 my-1 mb-2"
                                        name={`link-3-${currentImage?.id ?? 0}`}
                                        value={topPCLink}
                                        placeholder="URL"
                                        onChange={(e) =>
                                            setTopPCLink(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="flex justify-end gap-6">
                                    <Button
                                        variant="outlined"
                                        disabled={isSubmitTopImage}
                                        className="mt-5 py-2 px-4 mr-10 inline-block text-white rounded-lg cursor-pointer"
                                        onClick={() => {
                                            setCurrentImage(null);
                                            setTopPCImage(null);
                                            setTopPCImageStart("");
                                            setTopPCImageEnd("");
                                            setTopPCLink("");
                                            setOpenModalShop(false);
                                        }}
                                    >
                                        キャンセル
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitTopImage}
                                        className="mt-5 py-2 px-4 mr-10 inline-block text-white rounded-lg cursor-pointer"
                                        onClick={() => handleTopImage(3)}
                                    >
                                        設定する
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>

            {/* Dialog Delete */}
            <Dialog
                open={!!openConfirmDelete}
                onClose={() => setOpenConfirmDelete(null)}
            >
                <DialogTitle>これを削除してもよろしいですか?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDelete(null)}>
                        キャンセル
                    </Button>
                    <Button
                        onClick={() =>
                            handleDeleteSettingImage(openConfirmDelete)
                        }
                        autoFocus
                    >
                        削除
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
