import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Apis } from "../../../api";
import CustomEditor from "../../../components/Editor/CustomEditor";
import { formatHtmlAspectRatio } from "../../../utilities/helper";
import { MenuItem, OutlinedInput, Select } from "@mui/material";

export default function NewEmail() {
    const [emails, setEmails] = useState([]);
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [subject, setSubject] = useState("");
    const [header, setHeader] = useState("");
    const [content, setContent] = useState("");
    const [footer, setFooter] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [emailId, setID] = useState("");
    const [toPreview, setToPreview] = useState("");
    const [subjectPreview, setSubjectPreview] = useState("");
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        const id = searchParams.get("id");
        if (id) {
            setID(searchParams.get("id"));
            getEmail(id);
        }
        getEmails();
    }, []);

    const getEmail = async (id) => {
        const res = await Apis.myGet(`settingEmail/settingEmail?id=${id}`);
        if (res && res.success) {
            setEmail(res.data.items[0]);
        }
    };

    const getEmails = async () => {
        const res = await Apis.myGet(
            `settingEmail/settingEmail?mailType=other`
        );
        if (res && res.success) {
            setEmails(res.data.items);
        }
    };

    const handleSetDefaultEmail = async (id) => {
        try {
            const res = await Apis.myPut(`settingEmail/settingEmail`, {
                id: id,
                is_default: true,
            });
            if (res && res.success) {
                setEmail(res.data.data);
                toast.success("デフォルトのメールとして設定されました");
                return;
            }
            toast.error("デフォルトのメールとして設定できませんでした");
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setTitle(email.title);
        setSubject(email.subject);
        setHeader(email.header);
        setContent(email.content);
        setFooter(email.footer);
    }, [email]);

    const navigation = useNavigate();

    const handleBackClick = () => {
        navigation(-1);
    };

    const sendTestEmail = async () => {
        const payload = {
            email: toPreview,
            subject: subjectPreview,
            header: formatHtmlAspectRatio(header),
            content: formatHtmlAspectRatio(content),
            footer: formatHtmlAspectRatio(footer),
        };

        const res = await Apis.myPost("settingEmail/settingEmail", payload);

        if (res && res.success) {
            await toast.success(`メールの登録が完了しました。`);
            setIsModalOpen(false);
            return;
        }
        if (res && res.data.error) {
            toast.error(res.data.error);
        } else {
            toast.error(`失敗しました! もう一度お試しください!`);
        }
    };

    const saveEmail = async () => {
        if (!title || !subject || !header || !content || !footer) {
            toast.error(`失敗しました! 必須フィールドに入力してください!`);
            return;
        }

        const payload = {
            title: title,
            subject: subject,
            header: formatHtmlAspectRatio(header),
            content: formatHtmlAspectRatio(content),
            footer: formatHtmlAspectRatio(footer),
        };

        if (emailId !== "") {
            payload.id = emailId;
        }

        const res = await Apis.myPut("settingEmail/settingEmail", payload);

        if (res && res.success) {
            await toast.success(`メールの登録が完了しました。`);
            navigation(-1);
            return;
        }
        if (res && res.data.error) {
            toast.error(res.data.error);
        } else {
            toast.error(`失敗しました! もう一度お試しください!`);
        }
    };

    const handleSetTemplate = async (e) => {
        setTemplate(e);
        const template = emails.find((item) => item.id === e);
        if (template) {
            setTitle(template.title);
            setSubject(template.subject);
            setHeader(template.header);
            setContent(template.content);
            setFooter(template.footer);
        } else {
            setTitle("");
            setSubject("");
            setHeader(null);
            setContent(null);
            setFooter(null);
        }
    };

    return (
        <div className="py-2 px-10">
            <div className="py-1 px-4 border-l-4 border-l-indigo-600 text-2xl">
                新規メール
            </div>

            <div className="pt-8">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-code" className="text-md pt-2">
                            メールテンプレート
                        </label>
                    </div>
                    <Select
                        labelId="demo-select-small-label"
                        className="!bg-white col-span-6 p-2 no-padding"
                        value={template}
                        input={<OutlinedInput />}
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(e) => handleSetTemplate(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {emails.map((row) => (
                            <MenuItem value={row.id}>{row.title}</MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div className="border-[1px] w-1/2 mx-auto my-4" />
            <div className="pb-8">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-code" className="text-md pt-2">
                            テンプレートタイトル
                            <span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            type="text"
                            className="w-full p-1 text-lg border rounded-md"
                            name="product-code"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-name" className="text-md pt-2">
                            件名<span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            type="text"
                            className="w-full p-1 text-lg border rounded-md"
                            name="product-name"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                        />
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-name" className="text-md pt-2">
                            ヘッダー<span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6 h-[360px]">
                        <CustomEditor
                            value={header}
                            onChange={(e) => {
                                setHeader(e);
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-name" className="text-md pt-2">
                            本文<span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6 h-[360px]">
                        <CustomEditor
                            value={content}
                            onChange={(e) => {
                                setContent(e);
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-name" className="text-md pt-2">
                            フッター<span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6 h-[360px]">
                        <CustomEditor
                            value={footer}
                            onChange={(e) => {
                                setFooter(e);
                            }}
                        />
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-4 mt-4 mb-8">
                    <div className="col-span-3"></div>
                    <div className="col-span-6">
                        <div className="flex items-center gap-4">
                            <button
                                className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md bg-slate-300 hover:bg-slate-400 active:bg-slate-500 cursor-pointer"
                                onClick={() => handleSetDefaultEmail(emailId)}
                            >
                                初期値に戻す
                            </button>
                            <button
                                className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md bg-slate-300 hover:bg-slate-400 active:bg-slate-500 cursor-pointer"
                                onClick={() => setIsModalOpen(true)}
                            >
                                プレビュー
                            </button>
                            <button
                                className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md bg-blue-300 hover:bg-blue-400 active:bg-blue-500 cursor-pointer"
                                onClick={saveEmail}
                            >
                                保存
                            </button>
                            <button
                                className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md bg-slate-300 hover:bg-slate-400 active:bg-slate-500 cursor-pointer"
                                onClick={handleBackClick}
                            >
                                キャンセル
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <>
                    <div
                        className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50  max-[530px]:w-[500px] w-[500px] h-[660px] p-6 bg-white rounded-lg"
                        style={{ border: "solid 1px black" }}
                    >
                        <h2 className="text-zinc-600 font-bold text-[18px] mt-2">
                            メールプレビュー
                        </h2>

                        <div className="border-[1px] w-full mx-auto my-4" />

                        <span className="mr-4">宛先:</span>
                        <input
                            className="w-2/3 text-lg border rounded-md px-2"
                            type="text"
                            value={toPreview}
                            onChange={(e) => {
                                setToPreview(e.target.value);
                            }}
                        />

                        <label className="mt-2" htmlFor="content-test-mail">
                            件名:
                        </label>
                        <textarea
                            className="h-[400px] w-full p-2 text-lg border rounded-md"
                            value={subjectPreview}
                            onChange={(e) => {
                                setSubjectPreview(e.target.value);
                            }}
                        />

                        <div className="border-[1px] w-fulll mx-auto my-4" />

                        <div className="flex justify-between mt-4 font-light px-6">
                            <button
                                className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                onClick={sendTestEmail}
                            >
                                テストメール送信
                            </button>
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                    <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30"></div>
                </>
            )}
        </div>
    );
}
