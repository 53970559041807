import React, { useCallback } from "react";
import Imagelinkitem from "./Imagelinkitem";

export default function Oneimagelink({
    FileUpload,
    isClear,
    onChangeData,
    data,
    setIsLoading,
}) {
    const handleState = useCallback(
        (newState) => {
            onChangeData({ data: newState, type: "oneImage" });
        },
        [onChangeData]
    );

    return (
        <Imagelinkitem
            FileUpload={FileUpload}
            data={data?.[0] ?? data}
            isClear={isClear}
            onChangeState={handleState}
            type={"one-link"}
            setIsLoading={setIsLoading}
        />
    );
}
