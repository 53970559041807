import * as React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { Close } from "@mui/icons-material";
import { Apis, urlAPI } from "../../../api";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FileUpload } from "../../../utilities/upload";
import CustomEditor from "../../../components/Editor/CustomEditor";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useAuthContext } from "../../../auth/context";
import { concatenateParams } from "../../../utilities/helper";

// id,
// code,
// title,
// imageUrl,
// priceWithoutFee,
// price,
// isNew,
// isRecommended,
// isPublic,
// stock,
// createdAt,
// updatedAt
export default function UpdateProduct() {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const { setLoading } = useAuthContext();
    const [logoColor, setLogoColor] = useState("white");
    const [isPublic, setIsPublic] = useState(true);
    const [id, setId] = useState(null);
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [imageUrls, setImageUrls] = useState([]);
    const [backImageUrls, setBackImageUrls] = useState([]);
    const [is_new, setIsNew] = useState(true);
    const [isCartAdded, setIsCartAdded] = useState(false);
    const [is_recommended, setIsRecommended] = useState(true);
    const [description, setDescription] = useState("");
    const [stock, setStock] = useState(0);
    const [priceWithoutFee, setPriceWithoutFee] = useState(0);
    const [fee, setFee] = useState(0);
    const [isPassUploadImgs, setIsPassUploadImgs] = useState(true);
    const [isPassUploadBgs, setIsPassUploadBgs] = useState(true);
    const [openModalConfirmHideProduct, setOpenModalConfirmHideProduct] =
        useState(false);

    const imgRef = useRef(null);
    const bgRef = useRef(null);
    const ruleUpload = ["image/jpeg", "image/png"];
    const messageErrorUpload = "JPGもしくはPNG形式のみサポートされています";

    useEffect(() => {
        // TODO load product from product id
        const product_id = params.get("id");
        if (product_id) {
            loadProduct(product_id).then();
        } else {
            toast.error("無効な商品 URL");
            navigate("/admin/products");
        }
    }, []);

    const loadProduct = async (id) => {
        setLoading(true);

        const query = concatenateParams({
            url: urlAPI.updateProduct,
            query: {
                code: id,
            },
        });

        const res = await Apis.myGet(query);
        if (res && res.success) {
            const product = res.data;
            setLogoColor(product.logo_color);
            setIsPublic(product.is_public);
            setCode(product.code);
            setId(product.id);
            setName(product.title);
            setImageUrls(product.image_urls);
            setBackImageUrls(product.back_image_urls);
            setIsNew(product.is_new);
            setIsRecommended(product.is_recommended);
            setDescription(product.description);
            setStock(product.stock);
            setPriceWithoutFee(product.price_without_fee);
            setFee(product.price - product.price_without_fee);
            setIsCartAdded(product.is_cart_added);
        } else {
            toast.error("無効な商品");
            navigate("/admin/products");
        }
        setLoading(false);
    };

    const handleChangeStatus = (value) => {
        if (isCartAdded && value !== "public") {
            setOpenModalConfirmHideProduct(isCartAdded);
            return;
        } else {
            setIsPublic(value === "public");
        }
    };

    const handleUpdateProduct = async () => {
        if (!name) {
            return toast.error(`製品名を入力してください!`);
        }

        if (!code) {
            return toast.error(`製品コードを入力してください!`);
        }

        if (!imageUrls.length) {
            return toast.error(`製品画像を入力してください!`);
        }

        if (!priceWithoutFee) {
            return toast.error(`Pリースフィル製品価格!`);
        }

        if (!isPassUploadImgs || !isPassUploadBgs) {
            return toast.error(messageErrorUpload);
        }

        const payload = {
            id,
            code,
            title: name,
            image_urls: imageUrls,
            back_image_urls: backImageUrls,
            price_without_fee: priceWithoutFee,
            price: priceWithoutFee + fee,
            stock,
            is_new,
            is_recommended,
            description,
            is_public: isPublic,
            logo_color: logoColor,
        };

        try {
            setLoading(true);

            const res = await Apis.myPut(urlAPI.updateProduct, payload);
            if (res && res.success) {
                toast.success(`製品が正常に更新されました!`);
                navigate("/admin/products");
                return;
            }

            if (res && res.data.error) {
                toast.error(res.data.error);
            }
        } catch (error) {
            toast.error(`失敗しました。もう一度お試しください!`);
        } finally {
            setLoading(false);
        }
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (ruleUpload.includes(file.type)) {
            const upload_url = await FileUpload(file);
            setImageUrls([...imageUrls, upload_url]);
        } else {
            imgRef.current && (imgRef.current.value = "");
            toast.error(messageErrorUpload);
        }
        setIsPassUploadImgs(ruleUpload.includes(file.type));
    };

    const handleBackImageUpload = async (event) => {
        const file = event.target.files[0];
        if (ruleUpload.includes(file.type)) {
            const upload_url = await FileUpload(file);
            setBackImageUrls([...backImageUrls, upload_url]);
        } else {
            bgRef.current && (bgRef.current.value = "");
            toast.error(messageErrorUpload);
        }
        setIsPassUploadBgs(ruleUpload.includes(file.type));
    };

    const handleRemoveImage = (index) => {
        imgRef.current && (imgRef.current.value = "");
        setIsPassUploadImgs(true);
        setImageUrls(
            imageUrls.slice(0, index).concat(imageUrls.slice(index + 1))
        );
    };

    const handleRemoveBg = (index) => {
        bgRef.current && (bgRef.current.value = "");
        setIsPassUploadBgs(true);
        setBackImageUrls(
            backImageUrls.slice(0, index).concat(backImageUrls.slice(index + 1))
        );
    };

    return (
        <div className="py-2 px-10">
            <div className="py-1 px-4 border-l-4 border-l-indigo-600 text-2xl">
                商品変更
            </div>

            <div className="py-8">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-code" className="text-md pt-2">
                            商品コード
                            <span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            type="text"
                            readOnly
                            className="p-1 text-lg border rounded-md"
                            name="product-code"
                            value={code}
                            onChange={(event) => setCode(event.target.value)}
                        />
                        <button className="bg-gray-200 border-[1px] border-gray-300 rounded-md text-sm px-2 py-1 ml-4">
                            自動採番
                        </button>
                        <small className="ml-4">※登録後の変更不可</small>
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-name" className="text-md pt-2">
                            商品名<span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            type="text"
                            className="w-full p-1 text-lg border rounded-md"
                            name="product-name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-image" className="text-md pt-2">
                            オリジナル画像(表)
                            <span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            ref={imgRef}
                            type="file"
                            accept="image/jpeg,image/png"
                            className="w-full p-1 text-lg border rounded-md"
                            name="product-image"
                            onChange={handleImageUpload}
                        />
                        {imageUrls.map((img, index) => (
                            <div key={index} className="relative w-[px] mt-2">
                                <img src={img} alt="" />
                                <Close
                                    className="absolute -right-8 top-0 cursor-pointer"
                                    onClick={() => handleRemoveImage(index)}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-3 text-right">
                        <label
                            htmlFor="product-image-back"
                            className="text-md pt-2"
                        >
                            オリジナル画像(裏)
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            ref={bgRef}
                            type="file"
                            accept="image/jpeg,image/png"
                            className="w-full p-1 text-lg border rounded-md"
                            name="product-image-back"
                            onChange={handleBackImageUpload}
                        />
                        {backImageUrls.map((img, index) => (
                            <div key={index} className="relative w-[px] mt-2">
                                <img src={img} alt="" />
                                <Close
                                    className="absolute -right-8 top-0 cursor-pointer"
                                    onClick={() => handleRemoveBg(index)}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-3 text-right">
                        <label
                            htmlFor="product-is-public"
                            className="text-md pt-2"
                        >
                            公開
                        </label>
                    </div>
                    <div className="col-span-6">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="is-public"
                            value={isPublic ? "public" : "non-public"}
                            onChange={(event, value) =>
                                handleChangeStatus(value)
                            }
                        >
                            <FormControlLabel
                                value="public"
                                control={<Radio />}
                                label="公開"
                            />
                            <FormControlLabel
                                value="non-public"
                                control={<Radio />}
                                label="非公開"
                            />
                        </RadioGroup>
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-2 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="product-price" className="text-md pt-2">
                            販売価格<span className="text-red-500 px-1">*</span>
                        </label>
                    </div>
                    <div className="col-span-9 flex items-center">
                        <small>本体価格：</small>
                        <input
                            type="number"
                            className="w-[120px] p-1 text-lg border rounded-md"
                            name="product-price"
                            value={priceWithoutFee}
                            onChange={(e) =>
                                setPriceWithoutFee(Number(e.target.value))
                            }
                        />
                        <small className="pl-2">＋ 消費税額：</small>
                        <input
                            type="number"
                            className="w-[120px] p-1 text-lg border rounded-md"
                            name="product-price"
                            value={fee}
                            onChange={(e) => setFee(Number(e.target.value))}
                        />
                        <small className="pl-2">＝ 税込価格：</small>
                        <input
                            type="number"
                            className="w-[120px] p-1 text-lg border rounded-md"
                            name="product-price"
                            value={priceWithoutFee + fee}
                        />
                        <small className="pl-1">円</small>
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-3 text-right">
                        <label
                            htmlFor="product-description"
                            className="text-md pt-2"
                        >
                            商品説明文
                        </label>
                    </div>
                    <div className="col-span-6">
                        <CustomEditor
                            value={description}
                            onChange={(text) => setDescription(text)}
                        />
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-3 text-right">
                        <label htmlFor="stock" className="text-md pt-2">
                            在庫
                        </label>
                    </div>
                    <div className="col-span-6">
                        <input
                            type="number"
                            className="p-1 text-lg border rounded-md"
                            name="stock"
                            value={stock}
                            onChange={(event) =>
                                setStock(Number(event.target.value))
                            }
                        />
                    </div>
                </div>

                <div className="border-[1px] w-1/2 mx-auto my-4" />

                <div className="grid grid-cols-12 gap-4 mt-4 mb-8">
                    <div className="col-span-6"></div>
                    <div className="col-span-3 text-right">
                        <button
                            className="ml-auto block w-[150px] text-center p-2 mt-3 rounded-md bg-slate-300 hover:bg-slate-400 active:bg-slate-500 cursor-pointer"
                            onClick={handleUpdateProduct}
                        >
                            変更する
                        </button>
                    </div>
                </div>
            </div>

            <Dialog
                open={!!openModalConfirmHideProduct}
                onClose={() => setOpenModalConfirmHideProduct(false)}
            >
                <DialogTitle>
                    <>
                        この商品はユーザーのカートに
                        <br />
                        追加されているため非公開に編集できません
                    </>
                </DialogTitle>

                <DialogActions>
                    <Button
                        onClick={() => setOpenModalConfirmHideProduct(false)}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
