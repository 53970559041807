import { createContext, useContext, useEffect, useState } from "react";
import {
    getAccessToken,
    removeAccessTokenFromStorage,
    setAccessTokenToStorage,
} from "./index";
import { Apis, urlAPI } from "../api";
import { concatenateParams } from "../utilities/helper";

const defaultContext = {
    loading: true,
    user: null,
    isAdmin: false,
    isAgency: false,
    countShipping: 0,
    socket: null,
    liff: null,
    showLoginModal: false,
    setUserInfo: () => null,
    resetUserInfo: () => null,
    getNoticeShipping: () => null,
    reAuth: () => null,
    setLoading: () => null,
    setShowLoginModal: () => null,
    setSocket: () => null,
    setLiff: () => null,
};

const AuthContext = createContext(defaultContext);

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAgency, setIsAgency] = useState(false);
    const [isAgencyLv2, setIsAgencyLv2] = useState(false);
    const [reload, setReload] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showLoginEmailModal, setShowLoginEmailModal] = useState(false);
    const [countShipping, setCountShipping] = useState(0);
    const [socket, setSocket] = useState(null);
    const [liff, setLiff] = useState(null);

    function reAuth() {
        setReload(!reload);
    }

    function setUserInfo(user) {
        // set axios header
        if (user.token) {
            Apis.setAuthToken(user.token);
            setAccessTokenToStorage(user.token);
        }

        // set user on context
        setUser(user);
        setIsAdmin(!!user.is_superuser);
        setIsAgency(!!user.is_agency);
        setIsAgencyLv2(!!user.is_agency_tier_2);
    }

    function resetUserInfo() {
        removeAccessTokenFromStorage();
        Apis.setAuthToken("");

        setUser(null);
        setIsAdmin(false);
        setIsAgency(false);
        setIsAgencyLv2(false);
    }

    async function getNoticeShipping(page, perPage) {
        const pageQuery = page ?? 1;
        const perPageQuery = perPage ?? 100;

        const query = concatenateParams({
            url: urlAPI.orderView,
            query: {
                shippingState: 1,
                page: pageQuery,
                per_page: perPageQuery,
            },
        });

        const res = await Apis.myGet(query);
        if (res && res.success) {
            setCountShipping(res.data.total);
            return res.data;
        }
        return [];
    }

    useEffect(() => {
        const accessToken = getAccessToken();
        if (accessToken) {
            // fetch me
            Apis.me()
                .then((res) => {
                    if (res.success) {
                        // Success
                        setUserInfo(res.data);
                        setLoading(false);
                    } else {
                        // Failed
                        setUser(null);
                        setLoading(false);
                        removeAccessTokenFromStorage();
                    }
                })
                .catch(() => {
                    // Call failed
                    setUser(null);
                    setLoading(false);
                    removeAccessTokenFromStorage();
                });
        } else {
            setLoading(false);
        }
    }, [reload]);

    const values = {
        loading,
        user,
        isAdmin,
        isAgency,
        isAgencyLv2,
        socket,
        liff,
        setUserInfo,
        resetUserInfo,
        reAuth,
        showLoginModal,
        setShowLoginModal,
        showLoginEmailModal,
        setShowLoginEmailModal,
        setLoading,
        countShipping,
        getNoticeShipping,
        setSocket,
        setLiff,
    };
    return (
        <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
    );
};
export const useAuthContext = () => useContext(AuthContext);
