import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import AlarmPart from "../components/AlarmPart";
import { Apis } from "../api";
import { useAuthContext } from "../auth/context";
import { AppContext } from "../App";

export default function Layout() {
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuthContext();
    const [notifications, setNotifications] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false); // Hide the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const getNotification = async () => {
        try {
            const res = await Apis.myGet(`notification/online`);
            if (res?.data) {
                setNotifications(res?.data);
                return;
            }
            setNotifications([]);
        } catch (error) {
            console.error(error);
        }
    };
    const handleReadNotification = async (id) => {
        try {
            await Apis.myPut(`notification/online`, {
                notification_id: id,
                isRead: true,
            });
            await getNotification();
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (user) {
            getNotification();
        }
    }, [user]);

    const { previousPage } = useContext(AppContext);
    return (
        <div className="Dashboard relative">
            {/* <div>
            </div> */}
            <div
                /* className="selectiveBackground" */ className="flex justify-between"
            >
                <div>
                    {previousPage /* window.history.length > 2 */ && (
                        <Link to={-1}>
                            <img
                                src="/image/turn-left.png"
                                alt="return"
                                className="w-[40px] h-[60px] cursor-pointer ml-5 pt-5"
                            ></img>
                        </Link>
                    )}
                </div>
                <div className="top-0 right-0 flex p-2 md:m-4 items-center">
                    <AlarmPart
                        data={notifications}
                        onChange={handleReadNotification}
                    />
                    <div className="downBar my-auto" ref={dropdownRef}>
                        <img
                            alt="contactLists"
                            src="/image/contactLists.png"
                            id="contactLists"
                            onClick={toggleDropdown}
                        ></img>
                        {isOpen && (
                            <div className="downList mt-[10px]">
                                <div className="unannouncedList list">
                                    <Link
                                        className="listLink"
                                        to="/d-layout/list/nocontactlist"
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                    >
                                        非通知一覧
                                    </Link>
                                </div>
                                <div className="blockList list">
                                    <Link
                                        className="listLink"
                                        to="/d-layout/list/blocklist"
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                    >
                                        ブロック一覧
                                    </Link>
                                </div>
                                <div className="deleteList list">
                                    <Link
                                        className="listLink"
                                        to="/d-layout/list/deletedlist"
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                    >
                                        削除一覧
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col mx-auto pt-4justify-center items-center relative">
                <div className="userImage">
                    <img
                        className="aspect-square rounded-full w-full h-full bg-white"
                        alt="userAvatar"
                        src={
                            user?.avatar
                                ? user.avatar
                                : "/image/user_default.png"
                        }
                        style={{
                            backgroundSize: "contain",
                            backgroundPosition: "top center",
                        }}
                    ></img>
                </div>
                <div className="userInformation text-center text-black">
                    <div className="userName m-3">
                        {user ? user.username : ""}
                    </div>
                    <div className="userEmail">{user ? user.email : ""}</div>
                </div>
            </div>

            <div className="px-4 md:px-[70px] max-[400px]:mt-3">
                <Outlet />
            </div>
        </div>
    );
}
