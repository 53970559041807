export const linkTypes = [
    "Youtube",
    "Instagram",
    "TikTok",
    "Pinterest",
    "Linkedin",
    "Facebook",
    "GooglePlus",
    "Line",
    "Mail",
    "twitter",
];

export const selfProfile = [
    { type: "資格", color: "#F87171" }, // red-400
    { type: "特技", color: "#F87171" }, // red-400
    { type: "経歴", color: "#F87171" }, // red-400
    { type: "事業の強み", color: "#F87171" }, // red-400
    { type: "提供できるリソース", color: "#F87171" }, // red-400
    { type: "イノベーション実績", color: "#F87171" }, // red-400
    { type: "お知らせ", color: "#FB923C" }, // orange-400
    { type: "メッセージ", color: "#FB923C" }, // orange-400
    { type: "アイデア募集中", color: "#A855F7" }, // purple-500
    { type: "こんな企業と出会いたい", color: "#A855F7" }, // purple-500
    { type: "抱えている問題", color: "#525252" }, // neutral-600
    { type: "実現したいことや目標", color: "#A855F7" }, // purple-500
    { type: "苦手なこと", color: "#525252" }, // neutral-600
];

// 電話番号の正規表現パターン
export const phoneRegex = /^0\d{1,4}-\d{1,4}-\d{4}$/;

// メールアドレスの正規表現パターン
export const emailRegex =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{2,}(?:\.[A-Za-z0-9]{2,})?$/;

export const postCodeRegex = /^(\d{3}-\d{4}|\d{7})$/;
