import React, { useState, useEffect, useRef } from "react";

export default function Spaceitem({ isClear, onChangeData, data }) {
    const [value, setValue] = useState(0);
    const [error, setError] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        setValue(data?.space ?? data ?? 0);
    }, [data]);

    useEffect(() => {
        if (value > 20) {
            setValue(20);
            setError("");
            return onChangeData({ data: { space: 20 }, type: "space" });
        }

        if (value < 5) {
            return setError("数字は5から20までの範囲でなければなりません。");
        }

        if (value <= 20 && value >= 5) {
            setError("");
            onChangeData({ data: { space: value }, type: "space" });
        }
    }, [value]);

    useEffect(() => {
        if (isClear) {
            setValue(0);
        }
    }, [isClear]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.width = `${value * 10}px`;
        }
    }, [value]);

    return (
        <div>
            <h2>余白サイズを指定してください</h2>
            <div
                className={`relative w-full flex items-center border mt-3 rounded-lg`}
                style={{
                    height:
                        value < 5
                            ? "50px"
                            : value > 20
                            ? "200px"
                            : `${value * 10}px`,
                }}
            >
                <input
                    style={{ outline: "none" }}
                    ref={inputRef}
                    type="number"
                    className="!w-[60%] p-3 my-2 focus-visible:border-none rounded-lg"
                    value={value}
                    onChange={(e) => setValue(Number(e.target.value))}
                />
                <label className="absolute top-1/2 translate-y-[-50%] left-10">
                    mm幅
                </label>
                <div className="absolute top-1/2 translate-y-[-50%] cursor-pointer right-3">
                    <div
                        onClick={() => {
                            const newValue =
                                value < 5
                                    ? 5
                                    : value < 20
                                    ? parseInt(value) + 1
                                    : value;
                            onChangeData({ data: newValue, type: "space" });
                            setValue((pre) =>
                                pre < 5 ? 5 : pre < 20 ? parseInt(pre) + 1 : pre
                            );
                        }}
                    >
                        <img
                            className="w-[15px] h-[15px]"
                            alt="up"
                            src="/image/up-arrow.png"
                        />
                    </div>
                    <div
                        onClick={() => {
                            const newValue = value > 5 ? value - 1 : value;
                            onChangeData({ data: newValue, type: "space" });
                            setValue((pre) => (pre > 5 ? pre - 1 : pre));
                        }}
                    >
                        <img
                            className="w-[15px] h-[15px]"
                            alt="down"
                            src="/image/down-arrow.png"
                        />
                    </div>
                </div>
            </div>
            {error && <span className="text-red-500">{error}</span>}
        </div>
    );
}
