import React, { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { TERipple } from "tw-elements-react";

import { toast } from "react-toastify";
import { Apis } from "../../api";
import PuschaseModal from "../../components/Modal/Checkout/PurchaseModal";
import { formatCurrency } from "../../utilities/helper";
import Progressbar from "./Progressbar";

// const PuschaseModal = lazy(() =>
//     import("../../components/Modal/Checkout/PurchaseModal")
// );

const MinimumPaymentStripe = 77;

export default function ContractThree() {
    const [show, setShow] = useState(false);
    const [price, setPrice] = useState(0);
    const [cartData, setCartData] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
    const isFirstRender = useRef(true);
    const [searchParams] = useSearchParams();
    const rdCodeParam = searchParams.get("rd_code");
    const [rdCode, setRdCode] = useState("");

    const handlePuchase = () => {
        if (totalPriceWithTax < MinimumPaymentStripe) {
            toast.error("注文は最低金額が77円である必要があります");
            return;
        }
        setPrice(totalPriceWithTax);
        setShow(true);
    };
    const handleClosePuschase = () => {
        setPrice(0);
        setShow(false);
    };
    const getCartData = async () => {
        try {
            const response = await Apis.myGet(`account/cart`);
            if (response && response.success) {
                setCartData(response.data.data);
                const total = response.data.data.reduce((acc, item) => {
                    return acc + item.product.price_without_fee * item.quantity;
                }, 0);
                const totalWithTax = response.data.data.reduce((acc, item) => {
                    return acc + item.product.price * item.quantity;
                }, 0);
                setTotalPrice(total);
                setTotalPriceWithTax(totalWithTax);
                return;
            }
            throw new Error("カートデータを取得できませんでした");
        } catch (error) {
            toast.error("カートデータを取得できませんでした");
            console.error(error);
        }
    };
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            getCartData();
            if (rdCodeParam) {
                setRdCode(rdCodeParam);
            }
        }
    }, []);

    const styleHeaderTable = () => {
        return `py-4 mobile:whitespace-nowrap mobile:px-2`;
    };
    return (
        <div>
            <Link to="/d-dashboard">
                <img
                    alt="logo"
                    src="/image/logo.png"
                    width="150"
                    className="pt-3 pl-5"
                />
            </Link>
            <Progressbar progressState={1} />
            <div className="px-10">
                <div className="text-2xl font-bold mx-auto text-center mb-10">
                    最終確認
                </div>
                <div className="overflow-scroll">
                    {" "}
                    <table className="w-full text-center">
                        <tbody>
                            <tr>
                                <th className={styleHeaderTable()}>No</th>
                                <th className={styleHeaderTable()}>
                                    商品コード／商品名
                                </th>
                                <th className={styleHeaderTable()}>価格</th>
                                <th className={styleHeaderTable()}>数量</th>
                                <th className={styleHeaderTable()}>
                                    小計(税込)
                                </th>
                            </tr>
                            {cartData.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={`even:bg-white odd:bg-slate-50`}
                                >
                                    <td className="px-4">{index + 1}</td>
                                    <td className="px-4 flex justify-center py-2">
                                        <img
                                            src={item.product.image_urls[0]}
                                            alt="card_1"
                                            className="aspect-[2022/1275] object-contain"
                                            width="72"
                                        />
                                        <div className="px-4">
                                            <p>{item.product.code}</p>
                                            <p className="text-red-500">
                                                <Link
                                                    to={`/d-shop/detail/${item.product.code}`}
                                                >
                                                    {item.product.title}
                                                </Link>
                                            </p>
                                        </div>
                                    </td>
                                    <td className="px-4 mobile:whitespace-nowrap">
                                        {formatCurrency(item.product.price)}円
                                    </td>
                                    <td className="px-4 text-red-500">
                                        {item.quantity}
                                    </td>
                                    <td className="px-4">
                                        {formatCurrency(
                                            item.product.price * item.quantity
                                        )}
                                        円
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex flex-col p-6  w-full justify-end items-end bg-zinc-300">
                    <div className="grid grid-cols-2 text-right">
                        <div className="text-sm flex items-center">
                            <p className="w-full">商品合計</p>
                        </div>
                        <span className="pc:ml-24 text-2xl text-blue-800 mobile:whitespace-nowrap">
                            {formatCurrency(totalPriceWithTax)}円
                        </span>
                        <div className="text-sm flex items-center">
                            <p className="w-full">送料</p>
                        </div>
                        <span className="pc:ml-24 text-lg text-blue-800 mobile:whitespace-nowrap">
                            0円
                        </span>
                        <div className="text-sm flex items-center">
                            <p className="w-full">合計</p>
                        </div>
                        <span className="pc:ml-24 text-2xl text-red-500 mobile:whitespace-nowrap">
                            {formatCurrency(totalPriceWithTax)}円
                        </span>
                        <div></div>

                        <div className="text-green-500">
                            (うち消費税
                            {formatCurrency(totalPriceWithTax - totalPrice)}
                            円)
                        </div>
                    </div>
                </div>
                {/* <div className="flex p-6  w-full justify-end items-center ">
                    <div className="px-2 rounded-lg bg-red-600  text-sm text-white inline-block">
                        必須
                    </div>
                    <span className="ml-4">は必須項目です</span>
                </div>
                <div className="w-full px-8 py-4 bg-slate-300 text-lg text-white">
                    お支払方法
                </div>
                <div className="flex justify-between border-b-2 my-3 py-2">
                    <div className="flex items-center">
                        お支払方法
                        <div className="ml-3 px-2 rounded-lg bg-red-600  text-sm text-white inline-block">
                            必須
                        </div>
                    </div>
                    <div className="w-[70%]">
                        お支払い方法が設定されていないため、購入ができません。お手数ですが、店舗までお問い合わせください。
                    </div>
                </div> */}
                <TERipple rippleColor="white" style={{ display: "block" }}>
                    <Link
                        className="w-[70%] relative mx-auto justify-center bg-zinc-800 pc:px-10 px-6 py-5 text-white mt-10 cursor-pointer flex items-center"
                        // to="/d-shop/contract/4"
                        onClick={handlePuchase}
                    >
                        カード情報を入力する
                        <svg
                            className="absolute right-5 mobile:hidden"
                            width="13"
                            height="25"
                            viewBox="0 0 13 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.547878 28.2862C1.27838 29.2379 2.46276 29.2379 3.19326 28.2862L12.4521 16.2233C13.1826 15.2715 13.1826 13.7285 12.4521 12.7767L3.19327 0.713804C2.46276 -0.237936 1.27838 -0.237936 0.547878 0.713804C-0.182625 1.66554 -0.182626 3.20861 0.547878 4.16035L8.48404 14.5L0.547878 24.8396C-0.182625 25.7914 -0.182625 27.3345 0.547878 28.2862Z"
                                fill="white"
                            />
                        </svg>
                    </Link>
                </TERipple>
                <TERipple
                    rippleColor="white"
                    style={{ display: "block", paddingBottom: "100px" }}
                >
                    <Link
                        className="w-[50%] mx-auto justify-center bg-neutral-600 pc:px-10 px-4 py-5 text-white mt-10 cursor-pointer flex items-center"
                        to="/d-shop/contract/1"
                    >
                        前の画面へ戻る
                    </Link>
                </TERipple>
            </div>
            <PuschaseModal
                show={show}
                onClose={handleClosePuschase}
                price={price}
                totalPriceWithTax={totalPriceWithTax}
                totalPrice={totalPrice}
                rdCode={rdCode}
            />
        </div>
    );
}
