import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Apis, urlAPI } from "../../../api";
import { useEffect, useState } from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import Pagination from "../../../components/Pagination";
import { useAuthContext } from "../../../auth/context";
import { concatenateParams } from "../../../utilities/helper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ShippedList() {
    const { setLoading } = useAuthContext();
    const [shipped, setShipped] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [shippingStartDate, setShippingStartDate] = useState("");
    const [shippingEndDate, setShippingEndDate] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [total, setTotal] = useState(0);

    const loadOrders = async () => {
        try {
            setLoading(true);

            const query = concatenateParams({
                url: urlAPI.orderView,
                query: {
                    shippingState: 2,
                    page,
                    per_page: perPage,
                    shippingStartDate: shippingStartDate
                        ? encodeURIComponent(shippingStartDate)
                        : undefined,
                    shippingEndDate: shippingEndDate
                        ? encodeURIComponent(shippingEndDate)
                        : undefined,
                },
            });

            const res = await Apis.myGet(query);
            if (res && res.success) {
                setShipped(res.data.items);
                setPage(res.data.page);
                setPerPage(res.data.per_page);
                setTotal(res.data.total);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStartDate = (date) => {
        setPage(1);
        setShippingStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setPage(1);
        setShippingEndDate(date);
    };

    useEffect(() => {
        loadOrders().then();
    }, [page, perPage, shippingStartDate, shippingEndDate]);

    const csvData = [
        [
            "注文日",
            "注文番号",
            "会員ID",
            "発送日",
            "アカウント名",
            "氏名",
            "郵便番号",
            "住所",
            "電話番号",
            "注文品",
            "注文数",
            "注文金額",
            "決済手数料",
            "合計",
        ],
    ];
    for (const item of displayList) {
        csvData.push([
            moment(item.order_id.date).format("YYYY/MM/DD"),
            item.order_id.id,
            item.order_id.customer_id.id,
            moment(item.order_id.shipping_date).format("YYYY/MM/DD"),
            item.order_id.customer_id.username,
            item.order_id.customer_id.full_name,
            item.order_id.post_code,
            item.order_id.address,
            item.order_id.phone_number,
            item.product_id.title,
            item.number,
            item.total,
            item.closing_fee,
            (item.total || 0) + (item.closing_fee || 0),
        ]);
    }

    useEffect(() => {
        // 同じ注文の集計
        const results = shipped.reduce((acc, cur, index) => {
            const { order_id, product_id, number } = cur;
            const existingOrderId = acc.find(
                (p) => p.order_id.id === order_id.id
            );

            if (existingOrderId) {
                existingOrderId.product_id.title += `,${product_id.title}`;
                existingOrderId.total += product_id.price * number;
                existingOrderId.closing_fee += order_id.closing_fee;
                existingOrderId.number += number;
            } else {
                acc.push({
                    order_id,
                    product_id: { title: product_id.title },
                    number,
                    total: product_id.price * number,
                    closing_fee: order_id.closing_fee,
                });
            }
            return acc;
        }, []);
        setDisplayList(results);
    }, [shipped]);

    return (
        <div className="px-10 mb-3">
            <div className="flex justify-between items-center mb-2 gap-4">
                <button className="bg-indigo-700 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center">
                    発送済み
                </button>
                <CSVLink data={csvData} filename={`発送済み一覧.csv`}>
                    <button className="bg-indigo-700 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center">
                        CSVダウンロード
                    </button>
                </CSVLink>
                <div>
                    <input
                        type="date"
                        value={shippingStartDate}
                        onChange={(e) => handleChangeStartDate(e.target.value)}
                    ></input>
                    ~
                    <input
                        type="date"
                        value={shippingEndDate}
                        onChange={(e) => handleChangeEndDate(e.target.value)}
                    ></input>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                注文日
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文番号
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                会員ID
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                発送日
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                アカウント名
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                氏名
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                郵便番号
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                住所
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                電話番号
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文品
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文数
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                注文金額
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                決済手数料
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                合計
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shipped.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={15} align="center">
                                    該当する注文は 0 件 です
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        {displayList.map((p, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {p.order_id.date
                                        ? moment(p.order_id.date).format(
                                              "YYYY/MM/DD"
                                          )
                                        : null}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.customer_id.id}
                                </StyledTableCell>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {p.order_id.shipping_date
                                        ? moment(
                                              p.order_id.shipping_date
                                          ).format("YYYY/MM/DD")
                                        : null}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="max-w-[200px] min-w-[150px]"
                                >
                                    <p className="break-all line-clamp-1">
                                        {p.order_id.customer_id.username}
                                    </p>
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="max-w-[200px] min-w-[150px]"
                                >
                                    <p className="break-all line-clamp-1">
                                        {p.order_id.recipient_name}
                                    </p>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.post_code}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.address}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.phone_number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.product_id.title}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.total_price}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_id.closing_fee || 0}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {(p.order_id.total_price || 0) +
                                        (p.order_id.closing_fee || 0)}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                page={page}
                perPage={perPage}
                total={total}
                onChangePage={(p) => setPage(p)}
                onChangePerPage={(l) => setPerPage(l)}
            />
        </div>
    );
}
