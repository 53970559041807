import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function PreviewModal({ open, onClose, imageUrl, imageLink }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                border: "none",
                "& .MuiModal-backdrop": {
                    backgroundColor: "rgba(32,32,32,0.95);",
                },
            }}
        >
            <>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "fit-content",
                        border: "none",
                        bgcolor: "transparent",
                    }}
                >
                    <div className="h-[90vh] w-[90vw]">
                        <img
                            className="object-contain h-full w-full rounded-md cursor-pointer"
                            src={imageUrl}
                            onClick={() => {
                                if (!imageLink) return;
                                const a = document.createElement("a");
                                a.href = imageLink;
                                a.target = "_blank";
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                            }}
                            alt="..."
                        />
                    </div>
                </Box>
                <Close
                    sx={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        color: "white",
                        cursor: "pointer",
                    }}
                    onClick={onClose}
                />
            </>
        </Modal>
    );
}
