import { Modal, Box } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function ModalConfirm({ isOpen, onClose, title = "", onOk }) {
    return (
        <Modal open={isOpen} className="mobile:w-full" onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minWidth: 300,
                    minHeight: 200,
                    maxWidth: 500,
                    bgcolor: "background.paper",
                    borderRadius: "4px",
                    boxShadow: 24,
                    padding: "20px",
                    display: "grid",
                    gridTemplateRows: "auto 1fr auto",
                }}
            >
                <div className="absolute top-0 right-0 cursor-pointer ">
                    <Close onClick={onClose} className="text-zinc-500" />
                </div>

                <div
                    className="text-lg md:text-3xl font-semibold text-center break-all row pb-4"
                    style={{
                        gridRow: "1",
                    }}
                >
                    {title}
                </div>
                <div
                    className="flex justify-between font-light md:px-6"
                    style={{
                        gridRow: "3",
                    }}
                >
                    <button
                        className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                        onClick={onOk}
                    >
                        はい
                    </button>
                    <button
                        className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                        onClick={onClose}
                    >
                        いいえ
                    </button>
                </div>
            </Box>
        </Modal>
    );
}
