import { Avatar, Badge, Box, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TERipple } from "tw-elements-react";
import { Apis } from "../../../api/index";
import { toast } from "react-toastify";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const ChatHeader = ({ members }) => {
    const navigate = useNavigate();

    const updateHaveReadProfile = async (username) => {
        try {
            const BusinessResponse = await Apis.myGet(
                `social/public/online?username=${encodeURIComponent(username)}`
            );
            if (BusinessResponse.data && BusinessResponse.data.length) {
                setTimeout(() => {
                    window.open(`${window.origin}/${username}`, "_blank");
                }, 0);
                return;
            }
            throw new Error("ページが存在しません!");
        } catch (error) {
            toast.error("ページが存在しません!");
            console.error(error.message);
        }
    };

    return (
        <Box
            p={2}
            width={"100%"}
            sx={{
                backgroundColor: "transparent",
            }}
        >
            <Stack
                alignItems={"center"}
                direction={"row"}
                sx={{ height: "100%" }}
                justifyContent="start"
            >
                <Stack spacing={2} direction="row">
                    <Box
                        sx={{
                            marginLeft: "auto !important",
                            padding: "3px",
                            alignItems: "center",
                        }}
                    >
                        <TERipple>
                            <Link to="/d-chat/list">
                                <img
                                    src="/image/turn-left.png"
                                    alt="edit"
                                    className="w-[40px] h-[40px] cursor-pointer"
                                ></img>
                            </Link>
                        </TERipple>
                    </Box>
                    <Box>
                        {members.member ? (
                            members.member.map((item, index) => (
                                <StyledBadge
                                    key={index}
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    color={item.is_online ? "success" : "error"}
                                    variant="standard"
                                >
                                    <Avatar
                                        alt={`${item.username}_${index}`}
                                        src={item.avatar || ""}
                                        onClick={() => {
                                            updateHaveReadProfile(
                                                item.username
                                            );
                                        }}
                                        className="cursor-pointer"
                                    >
                                        {item.username[0]}
                                    </Avatar>
                                </StyledBadge>
                            ))
                        ) : (
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                variant="dot"
                            >
                                <Avatar
                                    alt={members.chat_group || "Room"}
                                    src="/image/user_default.png"
                                />
                            </StyledBadge>
                        )}
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ChatHeader;
