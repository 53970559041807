import { CircularProgress } from "@mui/material";

const Loading = () => {
    return (
        <div className="fixed h-screen w-screen top-0 left-0 z-[9999] bg-zinc-500/30 inset-0 flex items-center justify-center">
            <CircularProgress />
        </div>
    );
};

export default Loading;
