import { Close } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const AlarmPart = ({ data, onChange }) => {
    const [open, setIsOpen] = useState(false);
    // const ref = useRef(null);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             setIsOpen(false);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         // Clean up the event listener
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    const handleClickItem = (item) => {
        onChange(item.id);
        setIsOpen(false);
    };

    return (
        <div className="downBar relative h-5">
            <button
                className="relative "
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <img
                    alt="alarm"
                    src="/image/alarm.png"
                    id="alarm"
                    className="cursor-pointer"
                ></img>
                {data.length !== 0 && <div className="alarmBadge"></div>}
            </button>
            <Modal open={open} onClose={() => setIsOpen(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 2,
                        maxHeight: "75%",
                    }}
                >
                    <div className="flex justify-end cursor-pointer ">
                        <Close
                            onClick={() => setIsOpen(false)}
                            className="text-zinc-500"
                        />
                    </div>
                    {open &&
                        (data.length === 0 ? (
                            // <div className="downList !w-fit whitespace-nowrap mt-[10px]">
                            <div className="list px-4 pb-4">
                                <div className="listLink">通知はありません</div>
                            </div>
                        ) : (
                            // </div>
                            // <div className="z-20 downList pc:!w-fit pc:whitespace-nowrap mt-[10px] pc:max-h-[500px] overflow-y-auto hidden-scroll mobile:w-[300px] mobile:max-h-[400px] mobile:h-auto">
                            <div className="overflow-y-auto px-4 pb-4 max-h-[400px] hidden-scroll w-full md:w-auto">
                                {data.map((item, index) => (
                                    <div key={index} className="list text-left">
                                        {item.redirect_url === "" ? (
                                            <div
                                                className="listLink cursor-pointer"
                                                onClick={() =>
                                                    handleClickItem(item)
                                                }
                                            >
                                                {item.content
                                                    .split("\n")
                                                    .map((line, i) => (
                                                        <div key={i}>
                                                            {line}
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : (
                                            <Link
                                                className="listLink"
                                                target="_blank"
                                                to={item.redirect_url}
                                                onClick={() =>
                                                    handleClickItem(item)
                                                }
                                            >
                                                {item.content
                                                    .split("\n")
                                                    .map((line, i) => (
                                                        <div key={i}>
                                                            {line}
                                                        </div>
                                                    ))}
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </div>
                            // </div>
                        ))}
                </Box>
            </Modal>
        </div>
    );
};

export default AlarmPart;
