import React, { useCallback, useState, useEffect } from "react";
import Imagelinkitem from "./Imagelinkitem";

export default function Threeimagelink({
    FileUpload,
    isClear,
    onChangeData,
    data,
    setIsLoading,
}) {
    const defaultChildData = [
        {
            imageLink: "",
            title: "",
            text: "",
            url: "",
            id: 0,
        },
        {
            imageLink: "",
            title: "",
            text: "",
            url: "",
            id: 1,
        },
        {
            imageLink: "",
            title: "",
            text: "",
            url: "",
            id: 2,
        },
    ];
    const [childDataArray, setChildDataArray] = useState(defaultChildData);

    useEffect(() => {
        if (data) {
            if (data.length) {
                setChildDataArray(data);
            } else {
                setChildDataArray([
                    {
                        imageLink: data.url1,
                        title: data.title1,
                        text: data.text1,
                        url: data.link1,
                        id: 0,
                    },
                    {
                        imageLink: data.url2,
                        title: data.title2,
                        text: data.text2,
                        url: data.link2,
                        id: 1,
                    },
                    {
                        imageLink: data.url3,
                        title: data.title3,
                        text: data.text3,
                        url: data.link3,
                        id: 2,
                    },
                ]);
            }
        }
    }, [data]);

    const handleChildData = useCallback(
        (childData) => {
            childData = { ...childData };
            setChildDataArray((prevChildDataArray) => {
                const updatedChildDataArray = [...prevChildDataArray];
                const index = updatedChildDataArray.findIndex(
                    (data) => data.id === childData.id
                );
                if (index !== -1) {
                    updatedChildDataArray[index] = childData;
                } else {
                    updatedChildDataArray.push(childData);
                }

                onChangeData({
                    data: updatedChildDataArray,
                    type: "threeImage",
                });
                return updatedChildDataArray;
            });
        },
        [onChangeData]
    );

    useEffect(() => {
        isClear && setChildDataArray(defaultChildData);
    }, [isClear]);

    return (
        <div className="flex gap-2 flex-col md:flex-row md:justify-between">
            <div>
                <div className="aspect-w-1 aspect-h-1">
                    <Imagelinkitem
                        FileUpload={FileUpload}
                        data={childDataArray?.[0]}
                        isClear={isClear}
                        id={0}
                        onChangeState={handleChildData}
                        setIsLoading={setIsLoading}
                    />
                </div>
            </div>
            <div>
                <div className="aspect-w-1 aspect-h-1">
                    <Imagelinkitem
                        FileUpload={FileUpload}
                        data={childDataArray?.[1]}
                        isClear={isClear}
                        id={1}
                        onChangeState={handleChildData}
                        setIsLoading={setIsLoading}
                    />
                </div>
            </div>
            <div>
                <div className="aspect-w-1 aspect-h-1">
                    <Imagelinkitem
                        FileUpload={FileUpload}
                        data={childDataArray?.[2]}
                        isClear={isClear}
                        id={2}
                        onChangeState={handleChildData}
                        setIsLoading={setIsLoading}
                    />
                </div>
            </div>
        </div>
    );
}
