import React, { useState } from "react";
import { Apis } from "../api";
import { Link } from "react-router-dom";
import PersonDescription from "./PersonDescription";

export default function ChatAdditem({
    url,
    username,
    content,
    isAdd,
    date,
    is_online,
    full_name = username,
}) {
    const [isActive, setIsActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handlePending = async () => {
        const postUrl = "social/private/contactdata?is_pending=True";
        const body = { member: username };
        await Apis.myPut(postUrl, body);
        setIsActive(true);
        setIsModalOpen(false);
    };

    return (
        <>
            <li className="flex py-5 first:pt-0 last:pb-0 items-center justify-between cursor-pointer active:opacity-90">
                <div className="flex items-center">
                    <Link target="_blank" to={`/${username}`}>
                        <div className="relative">
                            <img
                                className="rounded-full w-[60px] h-[60px] object-contain"
                                width="60"
                                height="60"
                                src={url ? url : "/image/user_default.png"}
                                alt={`${username}-avatar`}
                            />
                            <div
                                className={`absolute bottom-1 right-1 w-[15px] h-[15px] rounded-full ${
                                    is_online === 2
                                        ? "bg-green-500"
                                        : is_online === 1
                                        ? "bg-amber-300"
                                        : "bg-red-500"
                                } `}
                            ></div>
                        </div>
                    </Link>
                    <div className="mobile:ml-3 pc:ml-10 overflow-hidden break-all">
                        <p className="text-xl font-medium text-slate-900">
                            {full_name}
                            {content?.contact_type === 'SNS' && <span className="p-1 leading-8 border rounded-md border-[#2ACEFF] bg-[#2ACEFF] text-white ml-2">サブページ</span>}
                        </p>
                        <PersonDescription person={content} />
                        <p className="text-sm text-slate-900 truncate ml-2">
                            {date}
                        </p>
                    </div>
                </div>

                {isActive || isAdd ? (
                    <div className="mobile:px-1 pc:px-5 py-2 text-white rounded-full bg-indigo-600 mobile:text-sm mobile:flex-shrink-0">
                        応答待機中
                    </div>
                ) : (
                    <div
                        className="mobile:px-1 pc:px-5 py-2 text-white rounded-full bg-orange-400 mobile:flex-shrink-0"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        チャットを申請
                    </div>
                )}
            </li>
            {isModalOpen && (
                <>
                    <div
                        className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 pc:w-[500px] w-[90%] h-[250px] p-6 bg-white rounded-lg "
                        style={{ border: "solid 3px black" }}
                    >
                        <h2 className="text-zinc-600 text-center font-bold text-[24px] mt-2">
                            本当にこのユーザーをチャットに
                            <br />
                            承認しますか？
                        </h2>
                        <div className="flex justify-between mobile:mt-[20px] mt-[70px] font-light px-6">
                            <button
                                className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                onClick={handlePending}
                            >
                                はい
                            </button>
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                            >
                                いいえ
                            </button>
                        </div>
                    </div>
                    <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30"></div>
                </>
            )}
        </>
    );
}
