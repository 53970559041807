import React from "react";
import { Link, useSearchParams } from "react-router-dom";

const Table = ({
    data,
    total,
    currentPage,
    selectedValue,
    setCurrentPage,
    setSelectedValue,
}) => {
    const handleSelectChange = (event) => {
        setCurrentPage(1);
        setSelectedValue(parseInt(event.target.value));
    };
    const [searchParams] = useSearchParams();
    const rdCode = searchParams.get("rd_code");

    const styleHeaderTable = () => {
        return `py-3 mobile:whitespace-nowrap mobile:px-2`;
    };
    return (
        <>
            <table className="w-full text-center min-w-[630px]">
                <tbody>
                    <tr>
                        <th className={styleHeaderTable()}>No</th>
                        <th className={styleHeaderTable()}>商品名</th>
                        <th className={styleHeaderTable()}>製品イメージ</th>
                        <th className={styleHeaderTable()}>価格（税込）</th>
                        <th className={styleHeaderTable()}>在庫量</th>
                        <th className={styleHeaderTable()}>詳細を見る/購入</th>
                    </tr>
                    {data.map((item, index) => (
                        <tr
                            key={index}
                            className={`even:bg-white odd:bg-slate-50`}
                        >
                            <td className="px-2">
                                {(currentPage - 1) * selectedValue + index + 1}
                            </td>
                            <td className="px-2">{item.cardName}</td>
                            <td className="px-2 justify-center items-center max-w-px">
                                <Link
                                    to={`/d-shop/detail/${item.code}${
                                        rdCode ? `?rd_code=${rdCode}` : ""
                                    }`}
                                >
                                    <img
                                        src={item.image || ""}
                                        alt={item.cardName}
                                    />
                                </Link>
                            </td>
                            <td className="px-2 break-words max-[440px]:w-[50px]">
                                {item.price}
                            </td>
                            {/* <td className="px-2">{item.variant}</td> */}
                            <td className="px-2">{item.stock}</td>
                            <td className="px-2">
                                <Link
                                    to={`/d-shop/detail/${item.code}${
                                        rdCode ? `?rd_code=${rdCode}` : ""
                                    }`}
                                    className="p-3 rounded-lg cursor-pointer bg-lime-400 hover:bg-lime-500 active:bg-lime-600 text-white max-[680px]:text-sm max-[680px]:p-1"
                                >
                                    詳細
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Render the pagination controls here */}
            <div className="w-full min-w-[630px] p-3 flex text-xl justify-around items-center">
                <div className="">{`${
                    currentPage * selectedValue - (selectedValue - 1)
                }-${
                    currentPage * selectedValue > total
                        ? total
                        : currentPage * selectedValue
                } of ${total}`}</div>
                <div>
                    <div>
                        <span className="text-black/70">
                            ページあたりの行数：
                        </span>
                        <select
                            className="border-2 border-black/40 rounded-md"
                            value={selectedValue}
                            onChange={handleSelectChange}
                        >
                            <option value={1}>1</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                </div>
                <div className="flex items-center">
                    <button
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        className="mr-2 max-[400px]:ml-2 ml-10"
                    >
                        <svg
                            width="14"
                            height="23"
                            viewBox="0 0 14 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13 22L1 11.5L13 1"
                                stroke="#868FA0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <span className="max-[500px]:text-sm max-w-[70px]">
                        {currentPage} / {Math.ceil(total / selectedValue)}
                    </span>
                    <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={
                            currentPage === Math.ceil(total / selectedValue)
                        }
                        className="mx-2"
                    >
                        <svg
                            width="14"
                            height="23"
                            viewBox="0 0 14 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 1L13 11.5L1 22"
                                stroke="#868FA0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
};

export default Table;
