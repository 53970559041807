import moment from "moment";
import React, { useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "moment/locale/ja";

moment.locale("ja");

const Datetimepicker = ({ value, disableValue, onChange, size = "s" }) => {
    const [selectedDate, setSelectedDate] = useState(value);

    const handleDateChange = (date) => {
        onChange(date);
        setSelectedDate(date);
    };

    const disableDate = (current) => {
        if (!disableValue) return true;
        return current.isAfter(disableValue, "day");
    };

    return (
        <Datetime
            value={selectedDate}
            inputProps={{
                placeholder: " 月 / 日 / 年       --:--",
                onKeyDown: (e) => {
                    if (e.keyCode !== 8) {
                        e.preventDefault();
                    }
                },
            }}
            onChange={handleDateChange}
            isValidDate={disableDate}
            timeFormat="HH:mm"
            className={`relative cursor-pointer border border-black rounded-md datetime-custom ${
                size === "l" ? "w-64" : ""
            }`}
        />
    );
};

export default Datetimepicker;
