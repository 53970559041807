import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Button, CircularProgress } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";

function VideoLinkItem({ FileUpload, isClear, onChangeData, data }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [video, setVideo] = useState("");
    const [videoUpload, setVideoUpload] = useState("");
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const [thumbnailUrl, setThumbnailUrl] = useState("");

    useEffect(() => {
        if (data) {
            const youtubeDomains = [
                'https://www.youtube.com',
                'https://youtu.be'
            ];

            const url = data.url ?? data ?? "";
            
            // Check if the URL starts with any of the YouTube domains
            (youtubeDomains.some(domain => url.includes(domain))) ?
                setYoutubeUrl(url) : setVideoUpload(url)

            setVideo(url)
        }
    }, [data])

    const handleVideoUpload = async (event) => {
        event.preventDefault();
        const uploadedVideo = event.target.files[0];
        if (uploadedVideo) {
            try {
                setIsUploading(true);
                const lastVideoUrl = URL.createObjectURL(uploadedVideo);
                const upload_url = await FileUpload(uploadedVideo);
                setVideoUpload(upload_url);
                setVideo(upload_url);
                onChangeData({ data: upload_url, type: "videoLink" });
                event.target.value = null;
                return () => URL.revokeObjectURL(lastVideoUrl);
            } catch (error) {
                console.error("Error creating object URL:", error);
            } finally {
                setIsUploading(false);
            }
        } else {
            console.error("No file selected");
        }
    };

    const handlePlaying = () => {
        setIsLoading(true);
        setIsPlaying((pre) => !pre);
    };

    const handleYoutubeUrlChange = (event) => {
        const url = event.target.value;
        setYoutubeUrl(url);
        const videoId = getYoutubeVideoId(url);
        if (!videoUpload) {
            setVideo(url);
            onChangeData({ data: url, type: "videoLink" });
            if (videoId) {
                setThumbnailUrl(`https://img.youtube.com/vi/${videoId}/0.jpg`);
            } else {
                setThumbnailUrl("");
            }
        }
    };

    const getYoutubeVideoId = (url) => {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    useEffect(() => {
        if (isClear) {
            setVideo();
            setYoutubeUrl("");
            setThumbnailUrl("");
            setVideoUpload("");
            setIsLoading(false);
        }
    }, [isClear]);

    return (
        <div className="mb-2">
            <div className="relative w-full h-fit aspect-video rounded-lg border-black border-2 overflow-hidden mx-auto">
                {video ? (
                    <ReactPlayer
                        url={video}
                        playing={isPlaying}
                        light={thumbnailUrl}
                        playsinline
                        controls={true}
                        width="100%"
                        height="100%"
                        onReady={() => {
                            setIsLoading(false);
                        }}
                        onClick={() => {
                            handlePlaying();
                        }}
                    />
                ) : (
                    !(isLoading || isUploading) && (
                        <div className="absolute inset-0 w-full h-full flex justify-center items-center">
                            利用できるビデオはありません
                        </div>
                    )
                )}

                {(isLoading || isUploading) && (
                    <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center z-1000">
                        <CircularProgress />
                    </div>
                )}
            </div>
            <div className="flex flex-row items-center py-1">
                <div className="shrink-0 relative flex items-center pr-1 lg:pr-4">
                    <Button variant="text" component="label">
                        <FileUploadOutlined className="lg:size-8" />
                        <input
                            type="file"
                            accept="video/*"
                            className="absolute hidden inset-0 w-full h-full opacity-0"
                            name="video-upload"
                            onChange={handleVideoUpload}
                        />
                        <span className="lg:font-semibold">アップロード</span>
                    </Button>
                </div>
                <div className="w-auto">
                    {videoUpload && (
                        <a
                            href={videoUpload}
                            target="_blank"
                            rel="noreferrer"
                            className="line-clamp-1 w-full break-all text-primary underline"
                        >
                            {videoUpload}
                        </a>
                    )}
                </div>
            </div>
            <span>または:</span>
            <div>
                <input
                    type="text"
                    value={youtubeUrl}
                    onChange={handleYoutubeUrlChange}
                    placeholder="YouTubeのURLを入力してください"
                    className="border border-gray-300 w-full rounded px-2 py-1"
                />
            </div>
        </div>
    );
}

export default VideoLinkItem;
